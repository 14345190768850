import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const nushRulesRouting: Routes = [
  {
    path: '',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:rule', 'read:rule', 'update:rule', 'delete:rule']
    },
    loadComponent: () => import('./pages/nush-rules/nush-rules.component').then(m => m.NushRulesComponent)
  },
  {
    path: 'create-nush',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Створення налаштувань НУШ',
      permissions: ['create:rule', 'read:rule', 'update:rule', 'delete:rule']
    },
    loadComponent: () => import('./pages/create-nush-rule/create-nush-rule.component').then(m => m.CreateNushRuleComponent)
  },
  {
    path: 'edit-nush/:id',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Редагування налаштувань НУШ',
      permissions: ['create:rule', 'read:rule', 'update:rule', 'delete:rule']
    },
    loadComponent: () => import('./pages/create-nush-rule/create-nush-rule.component').then(m => m.CreateNushRuleComponent)
  }
];
