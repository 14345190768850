import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard {
  constructor(private readonly router: Router, private readonly _oauth: OAuthService) { }

  canActivate(): UrlTree | boolean {
    if (this._oauth.hasValidAccessToken()) {
      return this.router.createUrlTree(['/dashboard']);
    } else {
      return true;
    }
  }
}
