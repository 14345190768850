<nit-wrapper [heading]="chooseRole ? 'Реєстрація (для батьків/вчителів)' : 'Зареєструватися як:'">
  <form *ngIf="chooseRole" [formGroup]="signupForm">
    <nit-field-wrapper icon="mail" [required]="true" [label]="'Email'" [control]="signupForm.controls['userName']">
      <input nitTrim type="email" formControlName="userName" placeholder="youremail@gmail.com" />
    </nit-field-wrapper>

    <nit-field-wrapper icon="password" [isHighError]="true" [required]="true" [label]="'Пароль'" [control]="signupForm.controls['password']"
      [showHidePassword]="true">
      <input formControlName="password" type="password" placeholder="••••••••" />
    </nit-field-wrapper>

    <nit-field-wrapper icon="password" [required]="true" [label]="'Підтвердження паролю'" [showHidePassword]="true"
      [control]="signupForm.controls['confirmPassword']">
      <input formControlName="confirmPassword" type="password" placeholder="••••••••" />
    </nit-field-wrapper>

    <div class="row">
      <div class="col-12 mt-3">
        <nit-field-wrapper [required]="true" [control]="signupForm.controls['aggrement']">
          <label class="checkbox mb-2"><input type="checkbox" formControlName="aggrement">
            <div>
              Я ознайомився (лася) та погоджуюся з
              <a class="link" routerLink="/privacy-policy">Політикою конфіденційності</a><span> та </span><a class="link" routerLink="/terms-of-use">Умовами використання</a>
            </div>
          </label>
        </nit-field-wrapper>
      </div>
    </div>

    <nit-button (clicked)="signup()">Зареєструватися</nit-button>

    <nit-link-item text="Ви вже зареєстровані?" link="/login" linkText="Увійти" />
  </form>
  <div *ngIf="!chooseRole" class="main-block">
    <button (click)="informModal.open()">Учень</button>
    <button (click)="chooseRole = true">Батьки/Вчителі </button>
    <nit-link-item text="Ви вже зареєстровані?" link="/login" linkText="Увійти" />
  </div>
</nit-wrapper>

<nit-inform-modal #informModal />
