import {Component, ViewChild} from '@angular/core';
import {ModalComponent} from '@nit-components';

@Component({
  selector: 'nit-inform-modal',
  templateUrl: './inform-modal.component.html',
  styleUrl: './inform-modal.component.scss',
  standalone: true,
  imports: [ModalComponent]
})
export class InformModalComponent {
  @ViewChild('informModal') informModal: ModalComponent;

  open(): void {
    this.informModal.open();
  }
}
