<nit-modal #modal
           id="delete-wish-modal"
           [titleSize]="'small'"
           [isPopup]="true"
           [displaySubmitBtn]="false"
           [width]="422">
  <div class="title">
    Ви дійсно бажаєте видалити {{isParent ? 'побажання' : 'рекомендацію'}}?
  </div>
  <div class="action">
    <button class="cancel" (click)="modal.close()">Скасувати</button>
    <button class="remove" (click)="submit()">Видалити</button>
</div>
</nit-modal>
