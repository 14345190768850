import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';
import {UnsavedUpdatesGuard} from '@nit-core/guards/unsaved-updates.guard';
import {profileRouting} from '../profile/profile-routing';

export const usersRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/users/all-users/all-users.component').then(m => m.AllUsersComponent),
  },
  {
    path: 'administrator',
    redirectTo: 'administrator/create'
  },
  {
    path: 'administrator/create',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:school-user'],
      breadcrumb: 'Створити адміністратора'
    },
    loadComponent: () => import('./pages/administrator-form/administrator-form.component').then(m => m.AdministratorFormComponent)
  },
  {
    path: 'administrator/:id',
    loadComponent: () => import('./pages/administrator-form/administrator-form.component').then(m => m.AdministratorFormComponent)
  },
  {
    path: 'tutor',
    redirectTo: 'tutor/create'
  },
  {
    path: 'tutor/create',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:school-user'],
      breadcrumb: 'Створити вчителя'
    },
    loadComponent: () => import('./pages/tutor-form/tutor-form.component').then(m => m.TutorFormComponent)
  },
  {
    path: 'tutor/:id',
    loadComponent: () => import('./pages/tutor-form/tutor-form.component').then(m => m.TutorFormComponent)
  },
  {
    path: 'student',
    redirectTo: 'student/create'
  },
  {
    path: 'student/create',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:school-user'],
      breadcrumb: 'Створити учня'
    },
    loadComponent: () => import('./pages/student-form/student-form.component').then(m => m.StudentFormComponent)
  },
  {
    path: 'student/:id',
    loadComponent: () => import('./pages/student-form/student-form.component').then(m => m.StudentFormComponent)
  },
  {
    path: 'profile/:id',
    children: profileRouting,
    data: {
      breadcrumb: 'Профіль'
    }
  },
  {
    path: 'edit-profile/:id',
    loadComponent: () => import('../profile/pages/profile-edit/profile-edit.component').then(m => m.ProfileEditComponent),
    data: {
      breadcrumb: 'Редагування профілю'
    }
  },
  {
    path: 'sync',
    canDeactivate: [UnsavedUpdatesGuard],
    loadComponent: () => import('./pages/user-synchronisation/users-synchronisation.component').then(m => m.UsersSynchronisationComponent),
    data: {
      breadcrumb: 'Синхронізація користувачів'
    }
  }
];
