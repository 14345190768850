import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {HttpPendingService} from '@nit-services';

@Injectable()
export class PendingInterceptor implements HttpInterceptor {

  constructor(private readonly _pending: HttpPendingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'POST' || req.method === 'PUT') {
      return this._nextHandleProgress(req, next);
    } else if (req.method === 'GET') {
      return this._httpGetHandleProgress(req, next);
    } else {
      return next.handle(req);
    }
  }

  private _nextHandleProgress(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._pending.startProgress();

    return next.handle(req).pipe(
      finalize(() => this._pending.stopProgress())
    );
  }

  private _httpGetHandleProgress(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._pending.startGetProgress(req.headers.get('Alias'));

    return next.handle(req).pipe(
      finalize(() => this._pending.stopGetProgress(req.headers.get('Alias')))
    );
  }
}
