<div id="lbs-form">
  <div class="lbs-main-block">
    <div class="nt-british-header_logos">
      <a href="https://www.lvivbritish.school/" target="_blank"><img src="https://lending.nit.lionwood.software/static/british/british_school_logo.svg" alt="Lionwood Software"></a>
      <img src="https://lending.nit.lionwood.software/static/british/british_kit.svg" alt="british_kit" class="main-logo">
      <a href="https://nit.school/" target="_blank"><img src="https://lending.nit.lionwood.software/static/british/logo.svg" alt="Lionwood Software"></a>
    </div>
    <div class="form">
      <span class="title">Всеукраїнський конкурс на навчання у Lviv British School</span>
      <span class="description">Вкажи, будь ласка, свою країну, область і місто, де ти зараз знаходишся. Після чого не забудь ввести прізвище та імʼя та контактні дані
        <br>одного з батьків. Обовʼязково прочитай правила участі у конкурсі, після чого постав ☑️ навпроти тексту “Я приймаю та погоджуюсь з
        <br>правилами участі у “Всеукраїнському конкурсі на навчання у Lviv British School”</span>
      <div class="inputs" [formGroup]="form">
        <div class="row">
          <div class="col-md-4">
            <nit-field-wrapper [control]="form.get('country')"  [label]="'Країна перебування'" [required]="true">
              <input placeholder="Введіть назву" nitTrim  formControlName="country"/>
            </nit-field-wrapper>
          </div>
          <div class="col-md-4">
            <nit-field-wrapper [control]="form.get('region')" [label]="'Область'" [required]="true">
              <input placeholder="Введіть назву" nitTrim formControlName="region" />
            </nit-field-wrapper>
          </div>
          <div class="col-md-4">
            <nit-field-wrapper [control]="form.get('city')" [label]="'Місто'" [required]="true">
              <input placeholder="Введіть назву" nitTrim formControlName="city" />
            </nit-field-wrapper>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <nit-field-wrapper  [control]="form.get('lastName')" [label]="'Прізвище'" [required]="true">
              <input placeholder="Введіть прізвище" nitTrim formControlName="lastName" />
            </nit-field-wrapper>
          </div>
          <div class="col-md-4">
            <nit-field-wrapper  [control]="form.get('firstName')" [label]="'Ім\'я'" [required]="true">
              <input placeholder="Введіть імʼя" nitTrim  formControlName="firstName" />
            </nit-field-wrapper>
          </div>
          <div class="col-md-4">
            <nit-field-wrapper [disabled]="true" [label]="'Вибраний клас'">
              <input disabled [placeholder]="classNumber + '-й клас'" />
            </nit-field-wrapper>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <nit-field-wrapper [control]="form.get('parentLastName')" [label]="'Прізвище батька/матері'" [required]="true">
              <input placeholder="Введіть прізвище" nitTrim formControlName="parentLastName" />
            </nit-field-wrapper>
          </div>
          <div class="col-md-4">
            <nit-field-wrapper [control]="form.get('parentFirstName')" [label]="'Ім\'я батька/матері'" [required]="true">
              <input placeholder="Введіть імʼя" nitTrim  formControlName="parentFirstName" />
            </nit-field-wrapper>
          </div>
          <div class="col-md-4">
            <nit-field-wrapper [control]="form.get('parentMiddleName')" [label]="'По-батькові батька/матері'">
              <input placeholder="Введіть по-батькові" nitTrim  formControlName="parentMiddleName" />
            </nit-field-wrapper>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <nit-field-wrapper [control]="form.get('parentPhone')" [label]="'Контактний номер батька/матері'" [required]="true">
              <nit-phone-input nitTrim  formControlName="parentPhone" />
            </nit-field-wrapper>
          </div>
          <div class="col-md-6">
            <nit-field-wrapper [control]="form.get('parentEmail')" [label]="'Email батька/матері'" [required]="true">
              <input placeholder="Введіть email" nitTrim formControlName="parentEmail" />
            </nit-field-wrapper>
          </div>
        </div>
        <div class="row checkbox">
          <nit-field-wrapper [control]="form.get('agreeTerms')" [required]="true">
            <div class="rules">
              <nit-custom-checkbox
                formControlName="agreeTerms"
                [isLbs]="true" />
              <a target="_blank" href="https://nit.school/lviv-british-school/">Я приймаю та погоджуюсь з правилами участі у “Всеукраїнському конкурсі на навчання у Lviv British School</a>
            </div>
          </nit-field-wrapper>
          <button [disabled]="form.invalid || !form.get('agreeTerms').value" (click)="saveDate()">
            Розпочати тестування
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
