import {Routes} from '@angular/router';
import {UnsavedUpdatesGuard} from '@nit-core/guards/unsaved-updates.guard';
import {replacementTableRouting} from '../../../../components/replacement-table/replacement-table-routing';

export const teacherReplacementRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/replacement-menu/replacement-menu.component').then(m => m.ReplacementMenuComponent)
  },
  {
    path: 'creating-teacher-replacement',
    canDeactivate: [UnsavedUpdatesGuard],
    loadComponent: () => import('./pages/creating-teacher-replacement/create-teacher-replacement.component').then(m => m.CreateTeacherReplacementComponent),
    data: {
      breadcrumb: 'Створення заміни'
    },
  },
  {
    path: 'replacement-history',
    children: replacementTableRouting,
    data: {
      breadcrumb: 'Історія замін',
      isReplacementHistory: true
    },
  },
  {
    path: 'accounting-journal',
    children: replacementTableRouting,
    data: {
      breadcrumb: 'Журнал обліку пропущених і замінених уроків',
    },
  },
];
