import {Routes} from '@angular/router';
import {NotFoundComponent} from '../core/components/not-found/not-found.component';
import {PermissionGuard} from '../core/permission/permission.guard';
import {ParentGuards} from '@nit-core/guards/parent.guards';
import {schoolRouting} from './pages/school/school-routing';
import {coursesRouting} from './pages/courses/courses-routing';
import {comparisonReportRouting} from './pages/comparison-report/comparison-report-routing';
import {questionnaireSettingsRouting} from './pages/questionnaire-settings/questionnaire-settings-routing';
import {documentsRouting} from './pages/documents/documents-routing';
import {schoolListRouting} from './pages/school-list/school-list-routing';
import {myClassRouting} from './pages/my-class/my-class-routing';
import {profileRouting} from './pages/profile/profile-routing';
import {usersRouting} from './pages/users/users-routing';
import {childrenRouting} from './pages/children/children-routing';
import {basicRootRouting} from './pages/basic-data/basic-root-routing';

export const privateRoutes: Routes = [
  {
    path: 'dashboard',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:dashboard'],
    },
    loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent)
  },
  // TODO: fix routes (use loadComponent etc.)
  {
    path: 'school',
    canActivate: [ParentGuards || PermissionGuard],
    data: {
      permissions: ['get:school'],
      breadcrumb: 'Профіль школи'
    },
    children: schoolRouting
  },
  {
    path: 'basic-data',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:class'],
      breadcrumb: 'Основні дані'
    },
    children: basicRootRouting
  },
  {
    path: 'my-class',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:class:my'],
      breadcrumb: 'Мій клас',
      hasAccess: true,
    },
    children: myClassRouting
  },
  {
    path: 'profile',
    data: {
      isMyProfile: true,
      breadcrumb: 'Профіль'
    },
    children: profileRouting
  },
  {
    path: 'profile-not-joined',
    data: {breadcrumb: ''},
    loadComponent: () => import('./pages/profile/not-joined-profile/not-joined-profile.component').then(m => m.NotJoinedProfileComponent)
  },
  {
    path: 'children',
    canActivate: [PermissionGuard],
    data: {
      isChildrenList: true,
      permissions: ['read:relation'],
      breadcrumb: 'Діти'
    },
    children: childrenRouting
  },
  {
    path: 'requests',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:request', 'read:school-request'],
      breadcrumb: 'Заявки'
    },
    loadComponent: () => import('./pages/requests/requests.component').then(m => m.RequestsComponent)
  },
  {
    path: 'diary',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:dashboard'],
      breadcrumb: 'Щоденник'
    },
    loadComponent: () => import('./pages/diary/pages/diary/diary.component').then(m => m.DiaryComponent)
  },
  {
    path: 'users',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:school-user'],
      breadcrumb: 'Список користувачів'
    },
    children: usersRouting
  },
  {
    path: 'student-journal',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:mark:my'],
      breadcrumb: 'Журнал оцінок'
    },
    loadComponent: () => import('./pages/student-journal/student-journal.component').then(m => m.StudentJournalComponent)
  },
  {
    path: 'support',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:user'],
      breadcrumb: 'Техпідтримка'
    },
    loadComponent: () => import('./pages/support/pages/support/support.component').then(m => m.SupportComponent)
  },
  {
    path: 'school-list',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:school'],
      breadcrumb: 'Список шкіл'
    },
    children: schoolListRouting
  },
  {
    path: 'settings',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['update:warning'],
      breadcrumb: 'Налаштування платформи'
    },
    loadComponent: () => import('./pages/settings/pages/settings/settings.component').then(m => m.SettingsComponent)
  },
  {
    path: 'archive',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:archive'],
      breadcrumb: 'Архів'
    },
    loadComponent: () => import('./pages/basic-data/pages/class-teacher-office/pages/years-archives/years-archives.component').then(m => m.YearsArchivesComponent)
  },
  {
    path: 'documents',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:report'],
      breadcrumb: 'Завантаження'
    },
    children: documentsRouting
  },
  {
    path: 'comparison-documents',
    canActivate: [PermissionGuard],
    data: {
      isComparison: true,
      permissions: ['get:governance-school'],
      breadcrumb: 'Завантаження для порівняльних звітів'
    },
    children: documentsRouting
  },
  {
    path: 'school-reports',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:governance-school'],
      breadcrumb: 'Звітність по школах'
    },
    loadComponent: () => import('./pages/school-reports/components/school-reports-table/school-reports-table.component').then(m => m.SchoolReportsTableComponent)
  },
  {
    path: 'school-reports-welcome',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:governance-school'],
    },
    loadComponent: () => import('./pages/school-reports-welcome/school-reports-welcome.component').then(m => m.SchoolReportsWelcomeComponent)
  },
  {
    path: 'chat',
    canActivate: [PermissionGuard],
    loadComponent: () => import('./pages/chat/chat.component').then(m => m.ChatComponent),
    data: {
      permissions: ['read:chat']
    },
  },
  {
    path: 'courses',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:course'],
      breadcrumb: 'Курси'
    },
    children: coursesRouting
  },
  {
    path: 'questionnaire',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:questionnaire'],
      breadcrumb: 'Опитування'
    },
    children: questionnaireSettingsRouting},
  {
    path: 'comparison-report',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:governance-school'],
      breadcrumb: 'Порівняльний звіт',
    },
    children: comparisonReportRouting},
  {
    path: 'settings-mobile',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['update:mobile-version'],
      breadcrumb: 'Налаштування платформи моб.'
    },
    loadComponent: () => import('./pages/settings/pages/settings-mobile/settings-mobile.component').then(m => m.SettingsMobileComponent)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
