import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {UserService} from '@nit-services';

@Injectable({
  providedIn: 'root'
})

export class ParentGuards {
  isParent: boolean;
  isChild: boolean;

  constructor(private readonly _router: Router, private readonly _userService: UserService) {
    this._userService.currentUser$.subscribe(res => {
      if (res) {
        this.isParent = !res.isChild;
        this.isChild = res.isChild;
      }
    });
  }


  canActivate(): boolean | UrlTree {
    if (this.isParent || this.isChild) {
      return true;
    } else {
      this._router.createUrlTree(['/404']);
    }
  }
}
