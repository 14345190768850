import {Routes} from '@angular/router';
import {LoggedInGuard} from '@nit-core/auth/logged-in.guard';
import {
  ConfirmationComponent,
  CongratulationsComponent,
  ForgotPasswordComponent,
  LoginComponent,
  SignupComponent,
  CongratulationsPasswordComponent,
  PrivacyPolicyComponent,
  TermsOfUseComponent
} from './pages';
import {CongratulationConfirmationComponent} from './pages/congratulation-confirmation/congratulation-confirmation.component';
import {LbsFormComponent} from './pages/lbs-form/lbs-form.component';
import {KitRegistrationComponent} from './pages/kit-registration/kit-registration.component';

export const publicRoutes: Routes = [
  // {
  //   path: '',
  //   component: PublicComponent,
  //   children: [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'signup-kit',
    component: KitRegistrationComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'congratulations',
    component: CongratulationsComponent
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'congratulations-password',
    component: CongratulationsPasswordComponent
  },
  {
    path: 'congratulations-confirmation',
    component: CongratulationConfirmationComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'lbs/:id',
    component: LbsFormComponent,
  }
  // ]
  // }
];
