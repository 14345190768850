import {Routes} from '@angular/router';
import {CertificateAchievementComponent} from './certificate-achievement.component';

export const certificateAchievementsRouting: Routes = [
  {
    path: '',
    component: CertificateAchievementComponent
  },
  {
    path: ':classId/certificate-acquaintance',
    loadComponent: () => import('../certificate-acquaintance/certificate-acquaintance.component').then(m => m.CertificateAcquaintanceComponent),
    data: {
      breadcrumb: 'Ознайомлення з свідоцтвом досягнень'
    }
  }
];
