import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';
import {studentSocialGroupResolver} from './social-groups-root-resolver';

export const socialGroupsRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/social-groups/social-groups.component').then(m => m.SocialGroupsComponent),
    canActivate: [PermissionGuard]
  },
  {
    path: ':id',
    loadComponent: () => import('./pages/edit-student-social-groups/edit-student-social-groups.component').then(m => m.EditStudentSocialGroupsComponent),
    canActivate: [PermissionGuard],
    resolve: {student: studentSocialGroupResolver},
    data: {
      permissions: ['update:social-group']
    }
  }
];
