import {Component, Input} from '@angular/core';
import {ScoreCardService} from '@nit-services';
import {PageTitleComponent} from '../../../../../../../../components/page-title/page-title.component';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'nit-title-section',
  templateUrl: './title-section.component.html',
  styleUrl: './title-section.component.scss',
  standalone: true,
  imports: [PageTitleComponent, CommonModule]
})
export class TitleSectionComponent {
  @Input() title: string = '';
  @Input() isParent: boolean = false;
  @Input() childId: string;
  @Input() schoolId: number;
  @Input() isFifthOrHigherGrade: boolean = false;
  @Input() didParentView: boolean = false;

  constructor(private readonly _scoreCardsService: ScoreCardService) { }

  parentApproveView(): void {
    this._scoreCardsService.updateScoreAccept({childId: this.childId}, this.childId, this.schoolId).subscribe(() => {
      this.didParentView = true;
    });
  }
}
