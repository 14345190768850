import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalComponent} from '@nit-core/components';
import {AchievementWishes} from '@nit-core/models/achievement-wishes';
import {AchievementWishService} from '@nit-services';
import {NitToastr} from '@nit-core/services/global/nit-toastr.service';

@Component({
  selector: 'nit-delete-wish-modal',
  templateUrl: './delete-wish-modal.component.html',
  styleUrl: './delete-wish-modal.component.scss',
  standalone: true,
  imports: [ModalComponent]
})
export class DeleteWishModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  @Input() isParent: boolean = true;
  @Input() schoolId: number;
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();

  wish: AchievementWishes;

  constructor(private readonly _achievementWishesService: AchievementWishService,
              private readonly _toastr: NitToastr) { }

  open(wish: AchievementWishes): void {
    this.wish = wish;
    this.modal.open();
  }

  submit(): void {
    this._achievementWishesService.delete(this.wish.id, {
      asUserId: this.isParent ? this.wish.userId : null,
      asUserSchoolId: this.schoolId
    }).subscribe(() => {
      this._toastr.success(`${this.isParent ? 'Побажання' : 'Рекомендацію'} успішно видалено`);
      this.deleted.emit();
      this.modal.close();
    });
  }
}
