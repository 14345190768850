import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const accountingAcademicAchievementRouting: Routes = [
  {path: '',
    loadComponent: () =>
      import('./accounting-academic-achievement.component').then(m => m.AccountingAcademicAchievementComponent),
  },
  {
    path: ':id/homework-list/:classId',
    loadComponent: () =>
      import('../../../../../../pages/basic-data/pages/class-journal/pages/class-journal/check-homework-list/check-homework-list.component')
        .then(m => m.CheckHomeworkListComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Домашні завдання',
      permissions: ['read:homework-implementation']
    }
  }
];
