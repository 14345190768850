import {NitForm, NValidate} from '@nit-forms';
import {AuthService} from '@nit-auth';
import {Component} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router, RouterLink} from '@angular/router';
import {WrapperComponent} from '../../components/wrapper/wrapper.component';
import {NitFieldWrapperComponent} from '../../../core/forms/components/field-wrapper/field-wrapper.component';
import {ButtonComponent} from '../../../core/components/buttons/button/button.component';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
  standalone: true,
  imports: [WrapperComponent, FormsModule, ReactiveFormsModule, NitFieldWrapperComponent, ButtonComponent, RouterLink]
})
export class ForgotPasswordComponent {
  form: NitForm = new NitForm({
    email: new FormControl(null, [NValidate.required, NValidate.email()])
  });

  constructor(private readonly authService: AuthService, private readonly router: Router) { }

  submit(): void {
    if (this.form.validate()) {
      this.authService.changePassword(this.form.value)
        .subscribe(
          () => this.router.navigate(['/congratulations-password']),
          e => this.form.externalValidationHandler(e)
        );
    }
  }
}
