import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const classJournalRoot: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/class-journal/class-journal.component').then(m => m.ClassJournalComponent),
  },
  {
    path: ':id',
    children: [
      {
        path: 'homework-list/:classId',
        loadComponent: () => import('./pages/class-journal/check-homework-list/check-homework-list.component').then(m => m.CheckHomeworkListComponent),
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Домашні завдання',
          permissions: ['read:homework-implementation']
        }
      },
      {
        path: 'history-of-changes',
        data: {
          breadcrumb: 'Історія змін журналу',
        },
        loadComponent: () => import('./pages/history-of-changes/history-of-changes.component').then(m => m.HistoryOfChangesComponent)
      },
      {
        path: 'create-thematic',
        data: {
          breadcrumb: 'Створити стовпець “Тематична оцінка”',
        },
        loadComponent: () => import('./pages/create-thematic-column/create-thematic-column.component').then(m => m.CreateThematicColumnComponent)
      },
      {
        path: 'create-summary',
        data: {
          breadcrumb: 'Створити стовпець “Підсумкова оцінка”',
        },
        loadComponent: () => import('./pages/create-thematic-column/create-thematic-column.component').then(m => m.CreateThematicColumnComponent)
      },
      {
        path: 'average',
        data: {
          breadcrumb: 'Калькулятор середнього балу',
        },
        loadComponent: () => import('./pages/create-thematic-column/create-thematic-column.component').then(m => m.CreateThematicColumnComponent)
      },
      {
        path: 'edit',
        data: {
          breadcrumb: 'Редагування стовпця',
        },
        loadComponent: () => import('./pages/create-thematic-column/create-thematic-column.component').then(m => m.CreateThematicColumnComponent)
      }
    ]
  }
];
