import {UnsavedUpdatesGuard} from '@nit-core/guards/unsaved-updates.guard';
import {Routes} from '@angular/router';
import {ClassTeacherPermissionGuard, PermissionGuard} from '@nit-core/permission/permission.guard';
import {NushViewModes} from '@nit-core/global/domain/enums';
import {classesRootRouting} from './pages/classes/classes-root-routing';
import {classJournalRoot} from './pages/class-journal/class-journal-root-routing';
import {nushRootRouting} from './pages/nush/nush-root-routing';
import {newTeacherScheduleRouting} from './pages/new-teacher-schedule/new-teacher-schedule-root-routing';
import {classTeacherOfficeRouting} from './pages/class-teacher-office/class-teacher-office-root-routing';
import {replacementTableRouting} from './components/replacement-table/replacement-table-routing';

export const basicRootRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/basic-data/basic-data.component').then(m => m.BasicDataComponent)
  },
  {
    path: 'classes',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:class', 'read:class:all'],
      breadcrumb: 'Класи'
    },
    children: classesRootRouting
  },
  {
    path: 'teacher-schedule',
    canActivate: [PermissionGuard],
    canDeactivate: [UnsavedUpdatesGuard],
    data: {
      permissions: ['get:academic-year'],
      breadcrumb: 'Розклад вчителів'
    },
    children: newTeacherScheduleRouting
  },
  {
    path: 'student-schedule',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:academic-year'],
      breadcrumb: 'Розклад учнів'
    },
    loadComponent: () => import('./pages/student-schedule/pages/student-schedule/student-schedule.component').then(m => m.StudentScheduleComponent)
  },
  {
    path: 'class-journal',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['get:journal', 'read:journal'],
      breadcrumb: 'Журнали класів'
    },
    children: classJournalRoot
  },
  {
    path: 'class-teacher-office',
    canActivate: [ClassTeacherPermissionGuard],
    data: {
      isTeacher: true,
      viewMode: NushViewModes.ClassTeacher,
      breadcrumb: 'Кабінет класного керівника',
      permissions: ['read:briefing-journal', 'read:visiting', 'create:report:any', 'get:movement-table']
    },
    children: classTeacherOfficeRouting
  },
  {
    path: 'class-admin-office',
    canActivate: [PermissionGuard],
    data: {
      isTeacher: false,
      viewMode: NushViewModes.Admin,
      permissions: ['update:school-user', 'update:school', 'update:teacher-replace'],
      breadcrumb: 'Кабінет адміністратора'
    },
    children: classTeacherOfficeRouting
  },
  {
    path: 'excursions-practices',
    canActivate: [PermissionGuard],
    data: {
      isTeacher: false,
      readonly: false,
      permissions: ['read:practice-journal', 'read:practice-journal:all'],
      breadcrumb: 'Екскурсії та практики'
    },
    loadComponent: () => import('./pages/excursions-practices/pages/excursions-practices/excursions-practices.component').then(m => m.ExcursionsPracticesComponent)
  },
  {
    path: 'class-journal-remark',
    canActivate: [PermissionGuard],
    data: {
      readonly: true,
      permissions: ['read:journal-remarks'],
      breadcrumb: 'Зауваження до ведення класного журналу'
    },
    loadComponent: () => import('./pages/remark-on-keeping-class-journal/pages/class-journal-remark/class-journal-remark.component').then(m => m.ClassJournalRemarkComponent)
  },
  {
    path: 'homework-table',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['read:homework-implementation'],
      breadcrumb: 'Домашні завдання'
    },
    loadComponent: () => import('./pages/homework/pages/homework/homework.component').then(m => m.HomeworkComponent)
  },
  {
    path: 'nush',
    data: {
      viewMode: NushViewModes.Teacher,
      breadcrumb: 'НУШ'
    },
    children: nushRootRouting
  },
  {
    path: 'individual-forms-of-study',
    data: {
      permissions: ['get:education-form', 'get:education-form:all'],
      breadcrumb: 'Індивідуальні форми здобуття освіти',
    },
    loadComponent: () => import('./pages/individual-forms-of-study/pages/individual-forms-of-study/individual-forms-of-study.component').then(m => m.IndividualFormsOfStudyComponent)
  },
  {
    path: 'my-replacements',
    data: {
      isMyReplacement: true,
      breadcrumb: 'Мої заміни'
    },
    children: replacementTableRouting,
  }
];
