<div id="kit-registration-form">
  <img class="kit-img" src="./assets/images/kit/top-registration-kit.svg" alt="registration-kit-logo">
  <img class="kit-img-mobile" src="./assets/images/kit/top-registration-kit-mobile.svg" alt="registration-kit-logo">
  <div class="main-block">
    <div class="form">
      <span class="title">Всеукраїнська онлайн-олімпіада "Квести І Тести"</span>
      <span class="description">Разом із батьками заповніть свої персональні дані й обовʼязково ознайомтесь з політикою конфіденційності й умовами користування.
        <br>Після чого постав ☑️ навпроти тексту "Я ознайомився (-лася) та погоджуюся з Політикою конфіденційності та Умовами використання”</span>
      <div class="inputs" [formGroup]="form">
        <nit-form-group name="Дані дитини">
          <div class="row">
            <div class="col-md-4">
              <nit-field-wrapper [control]="form.get('firstName')"  [label]="'Ім\ʼя дитини'" [required]="true">
                <input placeholder="Введіть імʼя дитини" nitTrim  formControlName="firstName"/>
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper [control]="form.get('lastName')" [label]="'Прізвище дитини'" [required]="true">
                <input placeholder="Введіть прізвище дитини" nitTrim formControlName="lastName" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper [disabled]="true" [control]="form.get('class')" [label]="'Клас дитини'" [required]="true">
                <input tabindex="-1"  placeholder="Введіть клас дитини" nitTrim formControlName="class" />
              </nit-field-wrapper>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('region')" [label]="'Область'" [required]="true">
                <input placeholder="Введіть область" nitTrim formControlName="region" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('city')" [label]="'Населений пункт'" [required]="true">
                <input placeholder="Введіть населений пункт" nitTrim  formControlName="city" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper [control]="form.get('school')"  [label]="'Назва школи'" [required]="true">
                <input placeholder="Введіть назву школи" nitTrim  formControlName="school" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper [disabled]="true" [control]="form.get('email')" [label]="'Email дитини'" [required]="true">
                <input tabindex="-1"  placeholder="Введіть email дитини" nitTrim formControlName="email" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('password')" [label]="'Пароль для акаунту КІТ'" [required]="true">
                <input placeholder="Встановість пароль" nitTrim  formControlName="password" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('confirmPassword')" [label]="'Підтвердження паролю'" [required]="true">
                <input placeholder="Введіть пароль повторно" nitTrim  formControlName="confirmPassword" />
              </nit-field-wrapper>
            </div>
          </div>
        </nit-form-group >
        <nit-form-group name="Дані одного з батьків">
          <div class="row">
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('parentLastName')" [label]="'Прізвище батька/матері'" [required]="true">
                <input placeholder="Введіть прізвище" nitTrim formControlName="parentLastName" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('parentFirstName')" [label]="'Ім\'я батька/матері'" [required]="true">
                <input placeholder="Введіть імʼя" nitTrim  formControlName="parentFirstName" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper [control]="form.get('parentMiddleName')" [label]="'По-батькові батька/матері'" [required]="true">
                <input placeholder="Введіть по-батькові" nitTrim  formControlName="parentMiddleName" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('parentPhone')" [label]="'Контактний номер батька/матері'" [required]="true">
                <nit-phone-input nitTrim  formControlName="parentPhone" />
              </nit-field-wrapper>
            </div>
            <div class="col-md-4">
              <nit-field-wrapper  [control]="form.get('parentEmail')" [label]="'Email батька/матері'" [required]="true">
                <input placeholder="Введіть email" nitTrim  formControlName="parentEmail" />
              </nit-field-wrapper>
            </div>
          </div>
        </nit-form-group>
        <div class="row checkbox">
          <nit-field-wrapper [control]="form.get('agreeTerms')" [required]="true">
            <div class="rules">
              <nit-custom-checkbox
                formControlName="agreeTerms"
                [isLbs]="true" />
              <span>
                Я ознайомився (лася) та погоджуюся з <a target="_blank" href="https://app.nit.school/privacy-policy"> Політикою конфіденційності </a> та <a target="_blank" href="https://app.nit.school/terms-of-use"> Умовами використання</a>
              </span>
            </div>
          </nit-field-wrapper>
          <button [disabled]="form.invalid || !form.get('agreeTerms').value" (click)="singUpKit()">Надіслати</button>
        </div>
      </div>
    </div>
  </div>
</div>
