import {Component, output, OutputEmitterRef, signal, Signal, viewChild, WritableSignal} from '@angular/core';
import {ModalComponent} from '@nit-components';
import {TeacherReplacementService} from '@nit-services';
import {ReplacementData} from '@nit-core/models/teacher-replacement';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ButtonComponent} from '@nit-core/components/buttons/button/button.component';
import {NitToastr} from '@nit-core/services/global/nit-toastr.service';

@UntilDestroy()
@Component({
  selector: 'nit-deleting-replacement-modal',
  templateUrl: './deleting-replacement-modal.component.html',
  styleUrl: './deleting-replacement-modal.component.scss',
  standalone: true,
  imports: [
    ModalComponent,
    ButtonComponent
  ],
})
export class DeletingReplacementModalComponent {
  confirm: Signal<ModalComponent> = viewChild('modal');

  closed: OutputEmitterRef<void> = output();
  updateTableData: OutputEmitterRef<void> = output();

  title: WritableSignal<string> = signal('');
  isCompleted: WritableSignal<boolean> = signal(false);
  replacementId: string;

  constructor(private readonly _teacherReplacementService: TeacherReplacementService,
              private readonly _toastr: NitToastr) {}

  open(data: ReplacementData): void {
    this.title.set(`Ви дійсно бажаєте видалити заміну для “${data?.originalTeacher?.fullName}”?`);
    this.replacementId = data.id;
    this.isCompleted.set(data.completed);
    this.confirm().open();
  }

  delete(): void {
    this._teacherReplacementService.delete(this.replacementId).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.confirm().close();
        this.updateTableData.emit();
        this._toastr.success('Заміну успішно видалено');
      });
  }
}
