import {Component} from '@angular/core';
import {NitForm, NValidate} from '@nit-forms';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from '@nit-auth';
import {KitForm} from '@nit-core/models/kit-form';
import {ActivatedRoute} from '@angular/router';
import {FormGroupComponent} from '../../../core/forms/components/form-group/form-group.component';
import {NitFieldWrapperComponent} from '../../../core/forms/components/field-wrapper/field-wrapper.component';
import {TrimInputDirective} from '../../../core/directives/trim-input.directive';
import {PhoneInputComponent} from '../../../core/components/phone-input/phone-input.component';
import {CustomCheckboxComponent} from '../../../private/components/custom-checkbox/custom-checkbox.component';

@Component({
  selector: 'nit-kit-registration',
  templateUrl: './kit-registration.component.html',
  styleUrl: './kit-registration.component.scss',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormGroupComponent, NitFieldWrapperComponent, TrimInputDirective, PhoneInputComponent, CustomCheckboxComponent]
})
export class KitRegistrationComponent {
  form: NitForm = new NitForm({
    firstName: new FormControl('',[NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    lastName: new FormControl('', [NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    class: new FormControl('', [NValidate.required]),
    region: new FormControl('',[NValidate.required, NValidate.maxLen(50)]),
    city: new FormControl('', [NValidate.required, NValidate.maxLen(50)]),
    school: new FormControl('',[NValidate.required, NValidate.maxLen(50)]),
    email: new FormControl('',[NValidate.required, NValidate.email()]),
    password: new FormControl('', [NValidate.minLen(8), NValidate.required, NValidate.password()]),
    agreeTerms: new FormControl(false, NValidate.required),
    parentFirstName: new FormControl('', [NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    parentLastName: new FormControl('', [NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    parentMiddleName: new FormControl('', [NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    parentEmail: new FormControl('', [NValidate.required, NValidate.email()]),
    parentPhone: new FormControl('', NValidate.required),
    confirmPassword: new FormControl('', [NValidate.required, NValidate.minLen(8), NValidate.noSpaces(), NValidate.equalWith('password')]),
  });

  constructor(private readonly _authService: AuthService,
              private readonly _activatedRoute: ActivatedRoute) {
    this._activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams?.email) this.form.get('email').setValue(queryParams?.email);
      if (queryParams?.classNumber) this.form.get('class').setValue(queryParams?.classNumber);
      if (queryParams?.redirect_uri) localStorage.setItem('redirect_uri', JSON.stringify(queryParams?.redirect_uri));
    });
  }

  singUpKit(): void {
    if (this.form.validate()) {
      this._authService.signUpKit({... this.form.value, class: Number(this.form.value.class)})
        .subscribe({
          next: () => this._loginKit(this.form.value),
          error: (e) => this.form.externalValidationHandler(e)
        });
    }
  }

  private _loginKit(form: KitForm): void {
    this._authService.login({userName: form.email, password: form.password})
      .subscribe(() => {
        const redirectUri = JSON.stringify(localStorage.getItem('redirect_uri'));
        window.open(JSON.parse(redirectUri), '_self');
      }, (e) => this.form.externalValidationHandler(e));
  }

}
