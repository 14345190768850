import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

@Injectable({providedIn: 'root'})

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HammerModule
  ],
  providers: [{provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfigModule}]
})
export class HammerConfigModule extends HammerGestureConfig {
  overrides = {
    swipe: {direction: Hammer.DIRECTION_ALL}
  } as any;
}
