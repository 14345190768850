<div class="congratulations">
  <div class="congratulations-wrapper">
    <div class="congratulations-icon">
      <img src="/assets/images/auth/congradulation-check.svg" alt="">
    </div>
    <div class="congratulations-title">
      Вітаємо!<br>
      Ваша реєстрація пройшла успішно.
    </div>
    <div class="congratulations-subtitle">
      Для входу перейдіть на головну сторінку
    </div>
    <a routerLink="/login" class="congratulations-link">На головну</a>
  </div>
</div>
