import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';
import {nushRootRouting} from '../nush/nush-root-routing';
import {studentGeneralInformationRouting} from './pages/student-general-information/student-general-information-root-routing';
import {briefingForStudentsRouting} from './pages/briefing-for-students/briefing-for-students-root-routing';
import {accountingAcademicAchievementRouting} from './pages/accounting-academic-achievement/accounting-academic-achievement-routing';
import {socialGroupsRouting} from './pages/social-groups/social-groups-root-routing';
import {paymentsRouting} from '../payments/payments-root-routing';
import {teacherReplacementRouting} from './pages/teacher-replacement/teacher-replacement-root-routing';


export const classTeacherOfficeRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/class-feature-list/class-feature-list.component').then(m => m.ClassFeatureListComponent),
    canActivate: [PermissionGuard],
    data: {
      // permissions: ['read:briefing-journal', 'read:visiting', 'create:report:any', 'get:movement-table']
    }
  },
  {
    path: 'visit-accounting',
    loadComponent: () => import('./pages/visit-accounting/visit-accounting.component').then(m => m.VisitAccountingComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Облік відвідування',
      permissions: ['read:visiting']
    }
  },
  {
    path: 'student-general-information',
    children: studentGeneralInformationRouting,
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Загальні відомості про учнів',
      permissions: ['get:class']
    }
  },
  {
    path: 'briefing-for-students',
    children: briefingForStudentsRouting,
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Бесіди, інструктажі, заходи з БЖ',
      permissions: ['read:briefing-journal']
    }
  },
  {
    path: 'total-rating',
    loadComponent: () => import('./pages/total-rating/total-rating.component').then(m => m.TotalRatingComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Зведений облік досягнень учнів',
      permissions: ['read:mark', 'read:mark:all']
    }
  },
  {
    path: 'class-report',
    loadComponent: () => import('./pages/class-report/class-report.component').then(m => m.ClassReportComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Завантаження класного журналу',
      permissions: ['create:report:any']
    }
  },
  {
    path: 'accounting-academic-achievement',
    children: accountingAcademicAchievementRouting,
    data: {
      breadcrumb: 'Облік навчальних досягнень',
      permissions: ['get:journal']
    }
  },
  {
    path: 'students-movement',
    loadComponent: () => import('./pages/students-movement/students-movement.component').then(m => m.StudentsMovementComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Таблиця руху учнів',
      permissions: ['get:movement-table']
    }
  },
  {
    path: 'close-year',
    loadComponent: () => import('./pages/close-year/close-year.component').then(m => m.CloseYearComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Завершення навчального року',
      permissions: ['update:school']
    }
  },
  {
    path: 'archive',
    loadComponent: () => import('./pages/years-archives/years-archives.component').then(m => m.YearsArchivesComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Архів',
      permissions: ['read:archive']
    }
  },
  {
    path: 'school-journal-changes',
    loadComponent: () => import('./pages/school-journal-changes/school-journal-changes.component').then(m => m.SchoolJournalChangesComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Журнал змін',
      permissions: ['read:mark-changelog']
    }
  },
  {
    path: 'teacher-replacement',
    children: teacherReplacementRouting,
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Заміна вчителя',
      permissions: ['read:teacher-replace']
    }
  },
  {
    path: 'excursions-practices',
    loadComponent: () => import('../excursions-practices/pages/excursions-practices/excursions-practices.component').then(m => m.ExcursionsPracticesComponent),
    canActivate: [PermissionGuard],
    data: {
      readonly: true,
      breadcrumb: 'Навчальні екскурсії та практики',
      permissions: ['read:practice-journal', 'read:practice-journal:all']
    }
  },
  {
    path: 'class-journal-remark',
    loadComponent: () => import('../remark-on-keeping-class-journal/pages/class-journal-remark/class-journal-remark.component').then(m => m.ClassJournalRemarkComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Зауваження до ведення класного журналу',
      permissions: ['read:journal-remarks']
    }
  },
  {
    path: 'timesheet-student',
    loadComponent: () => import('../../../children/pages/timesheet-student/timesheet-student.component').then(m => m.TimesheetStudentComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Табель навчальних досягнень',
      permissions: ['get:decision', 'read:decision:all']
    }
  },
  {
    path: 'nush',
    children: nushRootRouting,
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'НУШ'
    }
  },
  {
    path: 'individual-forms-of-study',
    loadComponent: () => import('../individual-forms-of-study/pages/individual-forms-of-study/individual-forms-of-study.component').then(m => m.IndividualFormsOfStudyComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Індивідуальні форми здобуття освіти',
      permissions: ['get:education-form', 'get:education-form:all'],
    }
  },
  {
    path: 'students-social-groups',
    children: socialGroupsRouting,
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Соціальні групи учнів',
      permissions: ['read:social-group'],
    }
  },
  {
    path: 'classroom-teacher-journal',
    loadComponent: () => import('../../../student-journal/student-journal.component').then(m => m.StudentJournalComponent),
    data: {
      breadcrumb: 'Зведена таблиця відміток',
      permissions: ['read:mark']
    }
  },
  {
    path: 'payments',
    data: {
      breadcrumb: 'Платежі'
    },
    children: paymentsRouting
  },
  {
    path: 'health-card',
    loadComponent: () => import('./pages/health-card/health-card.component').then(m => m.HealthCardComponent),
    data: {
      breadcrumb: 'Листок здоров’я',
      permissions: ['read:health-card']
    }
  }
];
