import {inject} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {KendoFilterOperator} from '@nit-core/global/domain/enums';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {SocialGroupService} from '@nit-services';
import {map} from 'rxjs';

export const studentSocialGroupResolver = (route: ActivatedRouteSnapshot) => {
  const query: KendoDataQuery = new KendoDataQuery();
  query.take = 1;
  query.pushFilters([{field: 'id', operator: KendoFilterOperator.Eq, value: route.params.id}]);

  return inject(SocialGroupService).all({query: query.query}).pipe(
    map(res => {
      const student = res.data[0];
      route.routeConfig.data.breadcrumb = student?.user?.fullName ?? '';

      return student;
    })
  );
};
