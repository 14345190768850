<nit-no-data [hasData]="wishParent || wishTeacher"
             [text]="'Рекомендації та побажання відсутні'">
  <div *ngIf="wishParent" class="wish-card">
    <div class="wish-title">Побажання батьків:</div>
    <div class="wish">{{wishParent.wish}}</div>
    <nit-button-context-menu *ngIf="isParent && !isAdmin"
                             [size]="'small'"
                             [items]="wishOptions"
                             (itemClicked)="handleItemClick($event)" />
  </div>

  <div *ngIf="wishTeacher" class="wish-card">
    <div class="wish-title">Рекомендації вчителя:</div>
    <div class="wish">{{wishTeacher.wish}}</div>
    <nit-button-context-menu *ngIf="!isParent && !isAdmin"
                             [size]="'small'"
                             [items]="wishOptions"
                             (itemClicked)="handleItemClick($event)" />
  </div>
</nit-no-data>
