import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {PermissionService} from './permission.service';
import {UserService} from '@nit-services';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard {

  constructor(private readonly _permissionService: PermissionService,
              private readonly _ngxPermissionsService: NgxPermissionsService,
              private readonly _userService: UserService,
              private readonly _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (this._permissionService.hasPermission(route.data?.permissions)) {
      return true;
    } else {
      return this._router.createUrlTree(['/404']);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClassTeacherPermissionGuard {

  constructor(private readonly _permissionService: PermissionService,
              private readonly _ngxPermissionsService: NgxPermissionsService,
              private readonly _userService: UserService,
              private readonly _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (this._permissionService.hasPermission(route.data?.permissions) && this._userService.isClassTeacher$.getValue()) {
      return true;
    } else {
      return this._router.createUrlTree(['/404']);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ParentPermissionGuard {
  constructor(private readonly _permissionService: PermissionService,
              private readonly _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this._permissionService.hasPermissionByUser(route.params.userId, route.data.permissions) ||
      this._router.createUrlTree(['/404']);
  }
}
