import {Injectable} from '@angular/core';
import {LeavePageService} from '@nit-services';

@Injectable({
  providedIn: 'root'
})
export class UnsavedUpdatesGuard {
  constructor(private readonly _leavePageService: LeavePageService) { }

  canDeactivate(): boolean {
    if (this._leavePageService.isLocationChanged$.getValue() === 'changed') {
      if (confirm('У Вас є незбережені зміни. Ви впевнені, що хочете покинути сторінку?')) {
        this._leavePageService.updateState('not-changed');

        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
