import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Routes} from '@angular/router';
import {CourseNameService} from '@nit-core/services/utils/course-name.service';
import {viewCourseCoreRouting} from './view-course/view-course-core.routing';

const courseBreadcrumbResolver = (route: ActivatedRouteSnapshot) => {
  return inject(CourseNameService).getCourseName(route.params.courseId);
};

export const viewCourseRouting: Routes = [
  {
    path: 'safeguard',
    loadComponent: () => import('../safeguard/safeguard.component').then(m => m.SafeguardComponent),
    data: {
      breadcrumb: 'Safeguarding',
      permissions: ['read:course']
    }
  },
  {
    path: 'columbia-university',
    loadComponent: () => import('../columbia-university/columbia-university.component').then(m => m.ColumbiaUniversityComponent),
    data: {
      breadcrumb: 'Вплив травми',
      permissions: ['read:course']
    }
  },
  {
    path: ':courseId',
    children: viewCourseCoreRouting,
    resolve: {
      breadcrumb: courseBreadcrumbResolver
    },
    runGuardsAndResolvers: 'always',
    data: {
      breadcrumb: '',
      permissions: ['read:course']
    },
  }
];
