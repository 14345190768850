import {Component} from '@angular/core';
import {PolicyContainerComponent} from '../../components/policy-container/policy-container.component';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'nit-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  standalone: true,
  imports: [PolicyContainerComponent, RouterLink]
})
export class TermsOfUseComponent { }
