import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const createSubjectRouting: Routes = [
  {
    path: '',
    canActivate: [PermissionGuard],
    loadComponent: () => import('./create-subject.component').then(m => m.CreateSubjectComponent)
  },
  {
    path: 'create-new-subject',
    data: {
      breadcrumb: 'Створення нового предмету',
    },
    loadComponent: () => import('../managing-subject/managing-subject.component').then(m => m.ManagingSubjectComponent)
  },
  {
    path: 'edit-subject/:id',
    data: {
      breadcrumb: 'Редагування предмету',
    },
    loadComponent: () => import('../managing-subject/managing-subject.component').then(m => m.ManagingSubjectComponent)
  },
];
