import {
  AfterViewInit,
  Component, computed,
  ElementRef,
  OnInit, signal,
  Signal,
  viewChild,
  WritableSignal
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClassService, DropdownFilterService, SubjectService, TeacherReplacementService} from '@nit-services';
import {FilterDescriptor, SortDescriptor} from '@progress/kendo-data-query';
import {ReplacePaymentTypes, ReplaceReasonTypes} from '@nit-core/global/domain/enums';
import {ReplacementData} from '@nit-core/models/teacher-replacement';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  CellTemplateDirective,
  ColumnComponent, FilterCellTemplateDirective,
  GridComponent,
  KENDO_GRID_DECLARATIONS, NoRecordsTemplateDirective,
  RowClassArgs, StringFilterCellComponent
} from '@progress/kendo-angular-grid';
import {GridBulkDirective} from '@nit-core/directives/grid-bulk.directive';
import {ButtonContextMenuComponent, ContextItem} from '@nit-core/components/buttons/button-context-menu/button-context-menu.component';
import {DeletingReplacementModalComponent} from '../../pages/class-teacher-office/pages/teacher-replacement/pages/creating-teacher-replacement/modal/deleting-replacement-modal/deleting-replacement-modal.component';
import {DropdownFilterComponent} from '@nit-core/components/dropdown-filter/dropdown-filter.component';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {Subject} from '@nit-core/models/subject';
import {Class} from '@nit-models';
import {AuthService} from '@nit-auth';
import {PageTitleComponent} from '../../../../components/page-title/page-title.component';
import {DataPickerComponent} from '@nit-core/components/datepicker/datepicker.component';
import {DatePipe} from '@angular/common';
import {GetTextFromEnumPipe} from '@nit-core/pipes/get-text-from-enum.pipe';
import {CustomCheckboxComponent} from '../../../../components/custom-checkbox/custom-checkbox.component';
import {NoDataComponent} from '@nit-core/components/no-data/no-data.component';

@UntilDestroy()
@Component({
  selector: 'nit-replacement-table',
  templateUrl: './replacement-table.component.html',
  styleUrl: './replacement-table.component.scss',
  standalone: true,
  imports: [
    DeletingReplacementModalComponent,
    PageTitleComponent,
    GridBulkDirective,
    DataPickerComponent,
    DatePipe,
    DropdownFilterComponent,
    GetTextFromEnumPipe,
    CustomCheckboxComponent,
    ButtonContextMenuComponent,
    NoDataComponent,
    DynamicComponentDirective,
    KENDO_GRID_DECLARATIONS,
    ColumnComponent,
    CellTemplateDirective,
    FilterCellTemplateDirective,
    StringFilterCellComponent,
    NoRecordsTemplateDirective
  ],
})
export class ReplacementTableComponent implements OnInit, AfterViewInit {
  gridBulk: Signal<GridBulkDirective> = viewChild(GridBulkDirective);
  _grid: Signal<GridComponent> = viewChild('grid');
  deletingReplacement: Signal<DeletingReplacementModalComponent> = viewChild('deletingReplacement');
  dynamicComponentDirective: Signal<DynamicComponentDirective> = viewChild(DynamicComponentDirective);

  subjectsList: WritableSignal<Subject[]> = signal([]) ;
  classes: WritableSignal<Class[]> = signal([]);
  isReplacementHistory: WritableSignal<boolean> = signal(false);
  isMyReplacement: WritableSignal<boolean> = signal(false);
  replaceReasonType = signal(ReplaceReasonTypes);
  replacePaymentTypes = signal(ReplacePaymentTypes);

  title: Signal<string> = computed(() =>
    this.isMyReplacement() ? 'Мої заміни'
      : this.isReplacementHistory() ? 'Історія замін' : 'Журнал обліку пропущених і замінених уроків'
  );

  userId: string;
  sort: SortDescriptor[] = [{field: 'id', dir: 'asc'}];
  defaultFilter = new Array<FilterDescriptor>();

  groupItems = [
    {name: '1'},
    {name: '2'},
    {name: '3'},
    {name: '4'},
    {name: '5'},
  ];

  inAccountingJournal = [
    {name: 'Так', isOfficial: true},
    {name: 'Ні', isOfficial: false}
  ];

  status = [
    {name: 'Проведено', completed: true},
    {name: 'Не проведено', completed: false}
  ];

  lessonsList = [
    {number: 0},
    {number: 1},
    {number: 2},
    {number: 3},
    {number: 4},
    {number: 5},
    {number: 6},
    {number: 7},
    {number: 8},
    {number: 9},
    {number: 10},
    {number: 11},
    {number: 12},
    {number: 13},
    {number: 14},
    {number: 15},
    {number: 16},
    {number: 17},
    {number: 18},
    {number: 19},
    {number: 20},
  ];

  constructor(private readonly _activatedRoute: ActivatedRoute,
              private readonly _router: Router,
              private readonly _authService: AuthService,
              public teacherReplacementService: TeacherReplacementService,
              private readonly _dropdownFilterService: DropdownFilterService,
              private readonly _subjectService: SubjectService,
              private readonly _classService: ClassService) {
    this.userId = this._authService.userId;
    this._activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.isReplacementHistory.set(data?.isReplacementHistory || false);
      this.isMyReplacement.set(data?.isMyReplacement || false);
    });
  }

  ngOnInit(): void {
    if (!this.isReplacementHistory() || this.isMyReplacement()) {
      this._setDefaultFilters();
    }
    this._getSchoolSubjects();
    this._getClasses();
  }

  rowCallback = (context: RowClassArgs): { [key: string]: boolean } => {
    if (!this.isReplacementHistory()) {
      return {danger: context.dataItem.isInvalid};
    }

    return {};
  };

  openDropdownPopover(event: { isOpened: boolean, dropdownSelect: ElementRef },
    dropdown: DropdownFilterComponent): void {
    this._dropdownFilterService.handleDropdownToggling(event, dropdown, this.dynamicComponentDirective());
  }

  handleClickOutside(filterName: string): void {
    this._dropdownFilterService.handleClickOutside(filterName);
  }

  contextHandler(item: ContextItem, dataItem: ReplacementData): void {
    if (item.mode === 'edit') {
      this._router.navigate(['edit-teacher-replacement'], {
        relativeTo: this._activatedRoute,
        queryParams: {id: dataItem.id}
      });
    } else {
      this.deletingReplacement().open(dataItem);
    }
  }

  onDateChange(date: Date): void {
    this.removeFilter();
    switch (true) {
    case date === null || new Date(1999, 0, 0) > date:
      break;
    default:
      this.addFilter(new Date(date.setHours(0, 0, 0)).toJSON(), 'gte');
      this.addFilter(new Date(date.setHours(23, 59, 59)).toJSON(), 'lte');
      break;
    }
    this.refresh();
  }

  addFilter(date: string, operator: string): void {
    this.gridBulk().defaultFilter.push({field: 'date', operator, value: date} as FilterDescriptor);
  }

  removeFilter(): void {
    this.gridBulk().defaultFilter = this.gridBulk().defaultFilter.filter(x => x.field !== 'date');
  }

  refresh(): void {
    this.gridBulk().rebind();
  }

  confirmCompletion(id: string): void {
    this.teacherReplacementService.sendCompletion(id)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.refresh());
  }

  ngAfterViewInit(): void {
    if (this.isMyReplacement()) {
      const columns = this._grid().columns.toArray();
      const replacementReasonType = columns.find(column => column.title === 'Причина заміни');
      const paymentType = columns.find(column => column.title === 'Відомості про оплату');
      const lastIndex = columns.length - 1;

      this._grid().reorderColumn(replacementReasonType, lastIndex);
      this._grid().reorderColumn(paymentType, lastIndex, {before: true});
    }
  }

  private _setDefaultFilters(): void {
    const filterTypeDescriptor = {
      field: this.isMyReplacement() ? 'replacementTeacher.id' : 'isOfficial',
      operator: 'eq',
      value: this.isMyReplacement() ? this.userId : true
    } as FilterDescriptor;
    this.defaultFilter.push(filterTypeDescriptor);
  }

  private _getSchoolSubjects(): void {
    this._subjectService.all({query: {take: '999'}})
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.subjectsList.set(res.data);
      });
  }

  private _getClasses(): void {
    this._classService.all({query: {take: '100'}})
      .pipe(untilDestroyed(this))
      .subscribe(classes => {
        this.classes.set(classes.data.naturalSort('name'));
      });
  }
}
