<div class="congratulations">
  <div class="congratulations-wrapper">
    <div class="congratulations-icon">
      <img src="/assets/images/auth/congradulation-check.svg" alt="">
    </div>
    <div class="congratulations-title">
      Зміна паролю!<br>
      Вам на електронну пошту був відправлений лист.
    </div>
    <div class="congratulations-subtitle">
      Перевірте її та перейдіть за посиланням для завершення зміни паролю.
    </div>
    <a class="congratulations-link" [routerLink]="['/']">На головну</a>
  </div>
</div>
