import {Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {StudentDropdownComponent} from '../student-dropdown/student-dropdown.component';
import {CommonModule} from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'nit-student-pagination',
  templateUrl: './student-pagination.component.html',
  styleUrl: './student-pagination.component.scss',
  standalone: true,
  imports: [CommonModule]
})
export class StudentPaginationComponent {

  @Input() studentDropdown?: StudentDropdownComponent;

  public changeSelectedUser(event: 'next' | 'prev'): void {
    this.studentDropdown.changeStudent(event);
  }


}
