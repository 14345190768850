import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';

@Component({
  templateUrl: './congratulation-confirmation.component.html',
  styleUrl: './congratulation-confirmation.component.scss',
  standalone: true,
  imports: [RouterLink]
})
export class CongratulationConfirmationComponent {
}
