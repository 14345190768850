import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const paymentSystemRouting: Routes = [
  {
    path: '',
    canActivate: [PermissionGuard],
    loadComponent: () => import('./payment-system.component').then(m => m.PaymentSystemComponent)
  },
  {
    path: 'create-new-payment-system',
    data: {
      breadcrumb: 'Додання платіжної системи',
    },
    loadComponent: () => import('./payment-system-action/payment-system-action.component').then(m => m.PaymentSystemActionComponent)
  },
  {
    path: 'edit-payment-system/:id',
    data: {
      breadcrumb: 'Додання платіжної системи',
    },
    loadComponent: () => import('./payment-system-action/payment-system-action.component').then(m => m.PaymentSystemActionComponent)
  },
];
