<div *ngIf="studentDropdown?.students?.length > 1" class="pagination">
  <div class="total-date-pagination swipe-event"
       (swipeleft)="changeSelectedUser('next')"
       (swiperight)="changeSelectedUser('prev')">
    <div class="left" [ngClass]="{disabled: studentDropdown?.isFirstSelected}" (click)="changeSelectedUser('prev')"></div>
    <div class="date" [ngClass]="{disabled: studentDropdown?.isFirstSelected}" (click)="changeSelectedUser('prev')">Попередній учень</div>
    <div class="date" [ngClass]="{disabled: studentDropdown?.isLastSelected}" (click)="changeSelectedUser('next')">Наступний учень</div>
    <div class="right" [ngClass]="{disabled: studentDropdown?.isLastSelected}" (click)="changeSelectedUser('next')"></div>
  </div>
</div>
