import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const myClassRouting: Routes = [{
  path: '',
  children: [
    {
      path: '',
      loadComponent: () => import('./my-class/my-class.component').then(m => m.MyClassComponent),
    },
    {
      path: ':id',
      loadComponent: () => import('../basic-data/pages/classes/pages/class-info/class-profile/class-profile.component').then(m => m.ClassProfileComponent),
    },
    {
      path: ':id/schedule',
      loadComponent: () => import('../basic-data/pages/classes/pages/class-schedule/class-schedule.component').then(m => m.ClassScheduleComponent),
      canActivate: [PermissionGuard],
      data: {
        permissions: ['read:journal-day:my'],
        breadcrumb: 'Розклад класу'
      }
    },
    {
      path: ':id/materials',
      loadComponent: () => import('../basic-data/pages/classes/pages/educational-materials/educational-materials.component')
        .then(m => m.EducationalMaterialsComponent),
      canActivate: [PermissionGuard],
      data: {
        permissions: ['read:class-material'],
        breadcrumb: 'Навчальні матеріали'
      }
    },
  ]
}];
