import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const schoolListRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./page/school-list/school-list.component').then(m => m.SchoolListComponent)
  },
  {
    path: 'create-school',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:school'],
      breadcrumb: 'Створення школи'
    },
    loadComponent: () => import('./page/create-school/create-school.component').then(m => m.CreateSchoolComponent)
  }
];
