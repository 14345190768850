import {NValidate, NitForm} from '@nit-forms';
import {AuthService, ConfirmActionModel} from '@nit-auth';
import {Component} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {ConfirmationType} from '@nit-core/global/domain/enums';
import {finalize} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CommonModule} from '@angular/common';
import {WrapperComponent} from '../../components/wrapper/wrapper.component';
import {NitFieldWrapperComponent} from '@nit-forms';
import {ButtonComponent} from '@nit-core/components/buttons/button/button.component';

@Component({
  templateUrl: './confirmation.component.html',
  standalone: true,
  imports: [
    CommonModule,
    WrapperComponent,
    FormsModule,
    ReactiveFormsModule,
    NitFieldWrapperComponent,
    RouterLink,
    ButtonComponent,
  ],
})
export class ConfirmationComponent {
  form: NitForm;
  confirmationType = ConfirmationType;
  title: string = '';
  isAutoRedirect: boolean;

  constructor(private readonly _activatedRoute: ActivatedRoute,
              private readonly _authService: AuthService,
              private readonly _router: Router) {
    this._activatedRoute.queryParams.subscribe(params => {
      this._initForm(params);
      this.isAutoRedirect = this.getConfirmationType() === ConfirmationType.ConfirmEmail
      || this.getConfirmationType() === ConfirmationType.ChangeEmail;

      if (this.getConfirmationType() === ConfirmationType.ConfirmEmail) {
        this._confirmEmail();
      } else if (this.getConfirmationType() === ConfirmationType.ChangeEmail) {
        this._confirmChangeEmail();
      }
    });
  }

  getConfirmationType(): ConfirmationType {
    return +this.form.get('confirmationType')?.value;
  }

  submit(): void {
    if (this.form.validate()) {
      const confType = this.getConfirmationType();
      if (confType === ConfirmationType.ConfirmAccount) {
        this._confirmAccount();
      } else if (confType === ConfirmationType.ChangeEmail || confType === ConfirmationType.AddEmail) {
        this._confirmChangeEmail();
      } else if (confType === ConfirmationType.ChangePassword) {
        this._changePassword();
      } else if (confType === ConfirmationType.ChangeNotVerifiedEmail) {
        this._changeNotVerifiedUserEmail();
      }
    }
  }

  private _initForm(params: any): void {
    this.form = new NitForm({
      email: new FormControl(params.email, [NValidate.email()]),
      confirmationType: new FormControl(params.confirmationType),
      token: new FormControl(params.token?.split(' ').join('+'))
    });

    const confType = this.getConfirmationType();
    // if (confType === ConfirmationType.ConfirmAccount ||
    //   confType === ConfirmationType.ChangeEmail ||
    //   confType === ConfirmationType.AddEmail) {
    //   this.form.addControl('userCode', new FormControl(null, [NValidate.profileCode()]));
    // }
    if (confType === ConfirmationType.ConfirmAccount ||
      confType === ConfirmationType.ChangePassword || confType === ConfirmationType.ChangeNotVerifiedEmail) {
      this.form.addControl('password', new FormControl(null, environment.production ?
        [NValidate.required, NValidate.minLen(8), NValidate.maxLen(100), NValidate.noSpaces(), NValidate.password()] :
        [NValidate.required, NValidate.minLen(8), NValidate.maxLen(100), NValidate.noSpaces()]
      ));
      this.form.addControl('confirmPassword', new FormControl(null, environment.production ?
        [NValidate.required, NValidate.minLen(8), NValidate.maxLen(100), NValidate.noSpaces(), NValidate.equalWith('password'), NValidate.password()] :
        [NValidate.required, NValidate.minLen(8), NValidate.maxLen(100), NValidate.noSpaces(), NValidate.equalWith('password')]
      ));
    }
    if (confType === ConfirmationType.ConfirmAccount || confType === ConfirmationType.ChangeNotVerifiedEmail) {
      this.form.addControl('aggrement', new FormControl(null, [NValidate.requiredTrue]));
    }
    if (confType === ConfirmationType.ChangeNotVerifiedEmail) {
      this.form.get('email').clearValidators();
      this.form.get('email').updateValueAndValidity();
    }
    this._setTitle(+this.form.get('confirmationType')?.value);
  }

  private _confirmEmail(): void {
    this._authService.confirmEmail(new ConfirmActionModel(this.form.value))
      .pipe(finalize(() => this._onSuccessConfirm()))
      .subscribe();
  }

  private _confirmAccount(): void {
    this._authService.confirmAccount(new ConfirmActionModel(this.form.value))
      .subscribe(() => this._onSuccessConfirm());
  }

  private _confirmChangeEmail(): void {
    this._authService.confirmChangeEmail(new ConfirmActionModel(this.form.value))
      .pipe(finalize(() => this._onSuccessConfirm()))
      .subscribe();
  }

  private _changePassword(): void {
    this._authService.resetPassword(new ConfirmActionModel(this.form.value))
      .subscribe(() => this._onSuccessConfirm());
  }

  private _changeNotVerifiedUserEmail(): void {
    this._authService.confirmChangeEmailForNotVerifiedUser(new ConfirmActionModel(this.form.value))
      .subscribe(() => this._onSuccessConfirm());
  }

  private _onSuccessConfirm(type?: ConfirmationType): void {
    if (this._authService.userId) {
      this._authService.logout().subscribe(() => this._logout(type));
    } else {
      this._logout(type);
    }
    localStorage.clear();
  }

  private _logout(type: ConfirmationType): void {
    if (type === ConfirmationType.ConfirmAccount) {
      this._router.navigate(['/congratulations-confirmation']);
    } else {
      this._router.navigate(['/login']);
    }
  }

  private _setTitle(type: number): void {
    switch (type) {
    case 0: this.title = 'Завершити реєстрацію'; break;
    case 1: this.title = 'Зміна паролю'; break;
    case 5: this.title = 'Встановіть пароль'; break;
    }
  }
}
