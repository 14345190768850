import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';
import {createSubjectRouting} from '../create-subject/create-subject-routing';
import {nushRulesRouting} from '../nush-rules/nush-rules-routing';
import {paymentSystemRouting} from '../payment/payment-system-routing';

export const schoolProfileSettingsRouting: Routes = [
  {
    path: '',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['update:setting'],
    },
    loadComponent: () => import('./school-profile-settings/school-profile-settings.component').then(m => m.SchoolProfileSettingsComponent)
  },
  {
    path: 'school-calendar',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Шкільний календар',
      permissions: ['read:day-off'],
    },
    loadComponent: () => import('./../school-calendar/school-calendar.component').then(m => m.SchoolCalendarComponent)
  },
  {
    path: 'create-subject',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Створення предметів',
      permissions: ['update:setting'],
    },
    children: createSubjectRouting
  },
  {
    path: 'nush',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Налаштування НУШ',
      permissions: ['create:rule', 'read:rule', 'update:rule', 'delete:rule']
    },
    children: nushRulesRouting
  },
  {
    path: 'dpa-subject',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Налаштування ДПА',
      permissions: ['update:setting'],
    },
    loadComponent: () => import('./../dpa-subject/dpa-subject.component').then(m => m.DpaSubjectComponent)
  },
  {
    path: 'payment',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Платіжні системи',
      permissions: ['create:rule', 'read:rule', 'update:rule', 'delete:rule']
    },
    children: paymentSystemRouting
  }
];
