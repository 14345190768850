import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {PermissionService} from '@nit-core/permission/permission.service';
import {UserService} from '@nit-core/services';

@Injectable({
  providedIn: 'root'
})
export class TransfersGuard {
  constructor(private readonly _router: Router,
              private readonly _permissionService: PermissionService,
              private readonly _userService: UserService) {
  }

  canActivate(): boolean | UrlTree {
    if ((this._permissionService.hasPermission(['read:transfer']) && this._userService.isClassTeacher$.getValue())
    || this._permissionService.hasPermission(['read:transfer:all'])) {
      return true;
    } else {
      this._router.createUrlTree(['/404']);
    }
  }
}
