<div *ngIf="groupedRows?.length && achievements?.length && !firstMarksLoad"
     class="educational-activity-table">
  <div class="outer-container">
    <div class="inner-container">
      <div class="characteristics-container">
        <div class="header">
          <div class="table-name">
            <span>Характеристика</span>
            <img src="/assets/images/notepads.svg" width="89" height="76" alt="Notepads">
          </div>
          <div class="evaluated">
            <span class="evaluated-title">Оцінено</span>
            <span class="evaluated-count"
                  [class.all-evaluated]="evaluatedCount === groupedRows.length">
              <span class="current">{{evaluatedCount}}</span>
              <span>/</span>
              <span>{{groupedRows.length}}</span>
            </span>
          </div>
        </div>

        <div class="blocks">
          <div *ngFor="let row of groupedRows" class="block">
            <div class="block-header">
              <span class="block-title">{{row.groupName}}</span>
              <span class="status"
                    [class.not-evaluated]="!row.isEvaluated">
                {{row.isEvaluated ? 'Оцінено' : 'Не оцінено'}}
              </span>
            </div>
            <div class="block-characteristics">
              <div *ngFor="let rowAchievement of row.groupedAchievements"
                   class="characteristic">
                {{rowAchievement.name}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-cells-container">
        <div class="table-head">
          <div class="th-column">
            Має значні успіхи
          </div>
          <div class="th-column">
            Демонструє помітний прогрес
          </div>
          <div class="th-column">
            Потребує уваги і допомоги
          </div>
        </div>

        <div class="table-body">
          <div *ngFor="let row of groupedRows let i=index" class="block-cells">
            <div *ngFor="let rowAchievement of row.groupedAchievements let j=index" class="table-row">
              <div class="mark-container">
                <nit-mark #mark
                          colKey="achievementId"
                          rowKey="learningOutcomeId"
                          [colId]="achievements[0]?.id"
                          [rowId]="rowAchievement.id"
                          [grading]="grading"
                          [is1To4Grade]="false"
                          [rate]="marks?.marks?.[achievements[0].id]?.[rowAchievement.id]?.[achievementMarkType.HasSuccess]?.[0] || {}"
                          [marksOfRow]="ungroupedMarks?.marks?.[achievements[0].id]?.[rowAchievement.id] || []"
                          [markService]="markService"
                          [isGeneralCharacteristics]="true"
                          [readOnly]="achievements[0].readOnly"
                          [generalAchievementValue]="achievementMarkType.HasSuccess"
                          [isOpened]="nusPopoverService.isOpenedStatuses['_' + rowAchievement?.id + '|' + achievements[0].id + '|'+ achievementMarkType.HasSuccess]"
                          (openPopover)="openPopover($event)"
                          (submitted)="getMarks($event, achievementMarkType.HasSuccess)" />
              </div>
              <div class="mark-container">
                <nit-mark #mark
                          colKey="achievementId"
                          rowKey="learningOutcomeId"
                          [rowId]="rowAchievement.id"
                          [colId]="achievements[0].id"
                          [grading]="grading"
                          [is1To4Grade]="false"
                          [rate]="marks?.marks?.[achievements[0].id]?.[rowAchievement.id]?.[achievementMarkType.ModerateProgress]?.[0] || {}"
                          [marksOfRow]="ungroupedMarks?.marks?.[achievements[0].id]?.[rowAchievement.id] || []"
                          [isGeneralCharacteristics]="true"
                          [markService]="markService"
                          [readOnly]="achievements[0].readOnly"
                          [generalAchievementValue]="achievementMarkType.ModerateProgress"
                          [isOpened]="nusPopoverService.isOpenedStatuses['_' + rowAchievement.id + '|' + achievements[0].id + '|'+ achievementMarkType.ModerateProgress]"
                          (openPopover)="openPopover($event)"
                          (submitted)="getMarks($event, achievementMarkType.ModerateProgress)" />
              </div>
              <div class="mark-container">
                <nit-mark #mark
                          colKey="achievementId"
                          rowKey="learningOutcomeId"
                          [rowId]="rowAchievement.id"
                          [colId]="achievements[0].id"
                          [grading]="grading"
                          [is1To4Grade]="false"
                          [rate]="marks?.marks?.[achievements[0].id]?.[rowAchievement.id]?.[achievementMarkType.NeedsAttention]?.[0] || {}"
                          [marksOfRow]="ungroupedMarks?.marks?.[achievements[0].id]?.[rowAchievement.id] || []"
                          [isGeneralCharacteristics]="true"
                          [markService]="markService"
                          [readOnly]="achievements[0].readOnly"
                          [generalAchievementValue]="achievementMarkType.NeedsAttention"
                          [isOpened]="nusPopoverService.isOpenedStatuses['_' + rowAchievement.id + '|' + achievements[0].id + '|'+ achievementMarkType.NeedsAttention]"
                          (openPopover)="openPopover($event)"
                          (submitted)="getMarks($event, achievementMarkType.NeedsAttention)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="decisionExists && childId" class="decision-wrapper">
  <p class="decision-title">Рішення педагогічної ради про:</p>
  <div class="decision-text">
    <span>{{decisionResult}}</span>
  </div>
</div>

<nit-loader-content *ngIf="!(marksLoaded$ | async)" />

<ng-template NitAddRating />
