import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';

@Component({
  templateUrl: './congratulations-password.component.html',
  styleUrl: './congratulations-password.component.scss',
  standalone: true,
  imports: [RouterLink]
})
export class CongratulationsPasswordComponent {}
