import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const calendarOfEventsRouting: Routes =[
  {
    path: '',
    loadComponent: () => import('./main-page-of-the-calendar/main-page-of-the-calendar.component').then(m => m.MainPageOfTheCalendarComponent)
  },
  {
    path: 'create-event',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Створення події',
    },
    loadComponent: () => import('./main-page-of-the-calendar/pages/managing-event/managing-event.component').then(m => m.ManagingEventComponent)
  },
  {
    path: 'edit-event',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Редагування події'
    },
    loadComponent: () => import('./main-page-of-the-calendar/pages/managing-event/managing-event.component').then(m => m.ManagingEventComponent)
  }
];
