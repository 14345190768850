import {Component} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router, RouterLink} from '@angular/router';
import {AuthService, SignUpModel} from '@nit-core/auth';
import {NitForm, NValidate} from '@nit-core/forms';
import {environment} from '../../../../environments/environment';
import {WrapperComponent} from '../../components/wrapper/wrapper.component';
import {CommonModule} from '@angular/common';
import {NitFieldWrapperComponent} from '@nit-forms';
import {TrimInputDirective} from '@nit-core/directives/trim-input.directive';
import {ButtonComponent} from '@nit-core/components/buttons/button/button.component';
import {LinkItemComponent} from '../../components/link-item/link-item.component';
import {InformModalComponent} from '../../components/inform-modal/inform-modal.component';

@Component({
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
  standalone: true,
  imports: [WrapperComponent, CommonModule, FormsModule, ReactiveFormsModule, NitFieldWrapperComponent, TrimInputDirective, RouterLink, ButtonComponent, LinkItemComponent, InformModalComponent]
})
export class SignupComponent {
  chooseRole: boolean = false;
  signupForm: NitForm;
  constructor(private readonly router: Router, private readonly authService: AuthService) {
    this.signupForm = new NitForm({
      userName: new FormControl('', [NValidate.required, NValidate.noSpaces(), NValidate.email()]),
      password: new FormControl('', environment.production
        ? [NValidate.required, NValidate.minLen(8), NValidate.noSpaces(), NValidate.password(), NValidate.maxLen(100)]
        : [NValidate.required, NValidate.minLen(8), NValidate.noSpaces()]),
      confirmPassword: new FormControl('',
        [NValidate.required, NValidate.minLen(8), NValidate.noSpaces(), NValidate.equalWith('password')]),
      aggrement: new FormControl('', NValidate.requiredTrue)
    });
  }

  signup(): void {
    if (this.signupForm.validate()) {
      this.authService.signUp(new SignUpModel(this.signupForm.value))
        .subscribe({
          next: () =>
            this.router.navigate(['/congratulations', {email: this.signupForm.get('userName').value}]),
          error: (e) => this.signupForm.externalValidationHandler(e)
        });
    }
  }
}
