<nit-wrapper heading="Вхід в особистий кабінет"
             [warning]="warning"
             [isReverse]="true">
  <form [formGroup]="loginForm" (keyup.enter)="login()">

    <nit-field-wrapper icon="mail" [required]="true" [label]="'Email'" [control]="loginForm.controls['userName']">
      <input formControlName="userName" nitTrim placeholder="youremail@gmail.com"/>
    </nit-field-wrapper>

    <nit-field-wrapper icon="password" [required]="true" [label]="'Пароль'" [control]="loginForm.controls['password']"
                       [showHidePassword]="true">
      <input class="password" formControlName="password" type="password" placeholder="••••••••"/>
    </nit-field-wrapper>
    <nit-link-item class="link" link="/forgot-password" linkText="Забули пароль?" />

    <nit-button (clicked)="login()">Увійти</nit-button>
    <nit-link-item text="Ви новий користувач?" link="/signup" linkText="Зареєструватися" />

    <div class="policy">
      <a class="link" routerLink="/privacy-policy">Політика конфіденційності</a><span>&nbsp;та&nbsp;</span><a class="link" routerLink="/terms-of-use">Умови
      використання</a>
    </div>
  </form>

</nit-wrapper>
