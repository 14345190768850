import {Component} from '@angular/core';
import {ActivatedRoute, RouterLink} from '@angular/router';

@Component({
  templateUrl: './congratulations.component.html',
  styleUrl: './congratulations.component.scss',
  standalone: true,
  imports: [RouterLink]
})
export class CongratulationsComponent {

  email: string;

  constructor(private readonly _route: ActivatedRoute) {
    this.email = this._route.snapshot.paramMap.get('email');
  }
}
