import {Routes} from '@angular/router';
import {publicRoutes} from './public/public-routes';
import {privateRoutes} from './private/private-routes';
import {AuthGuard} from '@nit-core/auth/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full'
  },
  {
    path: '',
    loadComponent: () => import('./public/public.component').then(m => m.PublicComponent),
    children: publicRoutes
  },
  {
    path: '',
    loadComponent: () => import('./private/private.component').then(m => m.PrivateComponent),
    data: {name: 'Private'},
    children: privateRoutes,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '404'
  },

];
