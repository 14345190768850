import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ContextItem} from '@nit-core/components/buttons/button-context-menu/button-context-menu.component';
import {AchievementWishes} from '@nit-core/models/achievement-wishes';
import {NoDataComponent} from '@nit-core/components/no-data/no-data.component';
import {CommonModule} from '@angular/common';
import {ButtonContextMenuComponent} from '@nit-core/components/buttons/button-context-menu/button-context-menu.component';

@Component({
  selector: 'nit-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrl: './wish-list.component.scss',
  standalone: true,
  imports: [NoDataComponent, CommonModule, ButtonContextMenuComponent]
})
export class WishListComponent {
  @Input() isParent: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() wishParent?: AchievementWishes;
  @Input() wishTeacher?: AchievementWishes;
  @Output() editEmitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteEmitted: EventEmitter<void> = new EventEmitter<void>();

  wishOptions: ContextItem[] = [
    {
      mode: 'edit',
      text: 'Редагувати',
      icon: '/assets/icons/edit.svg',
      visible: true
    },
    {
      mode: 'delete',
      text: 'Видалити',
      icon: '/assets/icons/delete.svg',
      color: 'var(--support-danger-300)',
      visible: true
    }
  ];

  handleItemClick(option: ContextItem): void {
    switch (option.mode) {
    case 'edit':
      this.editEmitted.emit();
      break;
    case 'delete':
      this.deleteEmitted.emit();
      break;
    }
  }
}
