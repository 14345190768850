import {Routes} from '@angular/router';
import {viewCourseRouting} from './pages/view-course/view-course.routing';

export const coursesRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('../courses/pages/courses.component').then(m => m.CoursesComponent)
  },
  {
    path: 'create-course',
    loadComponent: () => import('./pages/create-edit-course/create-edit-course.component').then(m => m.CreateEditCourseComponent),
    data: {
      breadcrumb: 'Створити курс',
      permissions: ['create:course']
    }
  },
  {
    path: 'view-course',
    data: {
      permissions: ['read:course']
    },
    children: viewCourseRouting
  },
  // {
  //   path: 'code-hour',
  //   loadComponent: () => import('./pages/code-hour/code-hour.component').then(m => m.CodeHourComponent),
  //   data: {
  //     breadcrumb: 'Вебінар “Година коду”',
  //     permissions: ['read:course']
  //   }
  // },
];
