<nit-policy-container>
  <h2 style="text-align: center;">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h2>
  <p><span>Ця політика конфіденційності визначає порядок обробки персональних даних користувачів електронного засобу
      навчального призначення &ldquo;Інформаційно-комунікаційна система &ldquo;НІТ (Навчання і Технології)&rdquo; та є
      невід&rsquo;ємною частиною <a routerLink="/terms-of-use">Умов використання.</a>&nbsp;</span></p>
  <p><span>Дана політика конфіденційності розроблена відповідно до Закону України &ldquo;Про захист персональних
      даних&rdquo; № 2297-VI від 01.06.2010, Наказу Уповноваженого Верховної ради України з прав людини &ldquo;Про
      затвердження документів у сфері захисту персональних даних&rdquo; № 1/02-14 від 08.01.2014 та визначає види,
      порядок обробки персональних даних суб&rsquo;єктів персональних даних. Тут також визначаються права
      суб&rsquo;єктів персональних даних. Крім того, при розробленні цієї Політики, були враховані положення Регламенту
      (ЄС) 2016/679 Європейського Парламенту і Ради ЄС від 27 квітня 2016 року (GDPR).</span></p>
  <p>&nbsp;</p>
  <ol>
    <li><h4> ВИЗНАЧЕННЯ ТЕРМІНІВ</h4></li>
  </ol>
  <p><strong>1.1. Програмна продукція</strong><span> - електронний засіб навчального призначення
      &ldquo;Інформаційно-комунікаційна система &ldquo;НІТ (Навчання і Технології)&rdquo;. Програмна продукція є
      результатом комп&rsquo;ютерного програмування у вигляді системних, прикладних програм, їх компонентів, а також у
      вигляді онлайн-сервісів та доступу до них тощо.&nbsp;</span></p>
  <p><span>Функціонал Програмної продукції:</span></p>
  <p><span>- доступ до електронних навчальних матеріалів;</span></p>
  <p><span>- управління навчальними планами;</span></p>
  <p><span>- формування розкладу уроків для вчителів та учнів;</span></p>
  <p><span>- цифровий щоденник учня;</span></p>
  <p><span>- цифровий журнал. Оцінювання знань та&nbsp; зворотний зв&rsquo;язок використовуючи цифровий журнал;</span>
  </p>
  <p><span>- текстова, аудіо та відео комунікація.</span></p>
  <p><strong>1.2. Персональні дані</strong><span> - відомості чи сукупність відомостей про фізичну особу, яка
      ідентифікована або може бути конкретно ідентифікована;</span></p>
  <p><strong>1.3. Обробка персональних даних</strong><span> - будь-яка дія або сукупність дій, таких як збирання,
      реєстрація, накопичення, зберігання, адаптування, зміна, поновлення, використання, поширення (розповсюдження,
      реалізація, передача), знеособлення, знищення персональних даних, у тому числі з використанням інформаційних
      (автоматизованих) систем;</span></p>
  <p><strong>1.4. Згода суб&rsquo;єкта персональних даних </strong><span>- добровільне волевиявлення фізичної особи щодо
      надання дозволу на обробку її персональних даних відповідно до сформульованої мети їх обробки, висловлене у
      письмовій формі або у формі, що дає змогу зробити висновок про надання згоди. Згода суб&rsquo;єкта персональних
      даних надається в момент проставлення відмітки в Програмній продукції про надання дозволу на обробку своїх
      персональних даних (Я ознайомився (лася) та погоджуюся з Політикою конфіденційності та Умовами використання) при
      реєстрації в Програмній продукції. Відносно персональних даних осіб, щодо яких зареєстрована фізична особа
      уповноважена законом надавати згоду на обробку персональних даних, згода надається в момент реєстрації такої особи
      в Програмній продукції через обліковий запис зареєстрованої особи, яка уповноважена законом надавати згоду на
      обробку персональних даних іншої особи (приклад: батьки реєструють своїх дітей через особистий обліковий
      запис).</span></p>
  <p><strong>1.5. Обліковий запис </strong><span>&nbsp;- профіль у Програмній продукції, в якому міститься інформація
      про користувача та за допомогою якого користувачу забезпечується певний рівень доступу до Програмної продукції, а
      також певний обсяг прав у Програмній продукції. Користувач має доступ до свого облікового запису після авторизації
      у Програмній продукції.</span></p>
  <p><strong>1.6. Суб&rsquo;єкт персональних даних </strong><span>-&nbsp; фізична особа, персональні дані якої
      обробляються. В цілях цієї Політики, існують такі категорії суб&rsquo;єктів персональних даних:&nbsp;</span></p>
  <p><span>1.6.1. користувач (найширша категорія, що включає як користувачів школи, так і інших користувачів, що не є
      користувачами школи, але які використовують Програмну продукцію);</span></p>
  <p><span>1.6.2. користувач школи:</span></p>
  <ul>
    <li><span>директор школи;</span></li>
    <li><span>адміністратор школи;</span></li>
    <li><span>вчитель школи;</span></li>
    <li><span>учень школи;</span></li>
    <li><span>батьки (батько/мати) учня школи.</span></li>
    <li><span>опікун, піклувальник.</span></li>
  </ul>
  <p><strong>1.7. Користувач - </strong><span>будь-який суб&rsquo;єкт персональних даних, який користується Програмною
      продукцією (в т.ч. користувач школи).</span></p>
  <p><strong>1.8. Користувач школи</strong><span> - суб&rsquo;єкт персональних даних, що є учасником освітнього процесу
      у школі, за умови, що такий користувач був приєднаний до школи у Програмній продукції. До користувачів школи
      належать: директор школи, адміністратор школи; вчитель школи, учень школи, батьки (батько/мати) учня школи.</span>
  </p>
  <p><strong>1.9. Школа </strong><span>- заклад загальної середньої освіти, який використовує Програмну продукцію
      відповідно до договору з Товариством.</span></p>
  <p><strong>1.10. Директор школи</strong><span> - посадова особа школи, директор. У Програмній продукції директор школи
      є адміністратором найвищого рівня та має можливість створювати адміністраторів школи, надавати користувачам із
      роллю вчитель школи додаткову, другу, роль адміністратора школи, створювати та видаляти класи, редагувати
      інформацію про школу в Програмній продукції.</span></p>
  <p><strong>1.11. Адміністратор школи </strong><span>-&nbsp; уповноважена школою особа, що відповідальна за взаємодію з
      розпорядником персональних даних користувачів школи з приводу надання доступу до Програмної продукції, створення
      облікових записів, а також підтвердження облікових записів, створених особисто користувачами школи.&nbsp;</span>
  </p>
  <p><strong>1.12. Батьки (батько/мати)</strong><span> - суб&rsquo;єкти персональних даних, що є батьками чи іншими
      законними представниками учнів школи (осіб, що не досягли 18 років/дітей).</span></p>
  <p><strong>1.13. Володілець персональних даних</strong><span> - юридична особа, яка визначає мету обробки персональних
      даних, встановлює склад цих даних та процедури їх обробки, якщо інше не визначено законом. Володільцем
      персональних даних щодо персональних даних користувачів школи є ця школа.&nbsp;</span></p>
  <p><strong>1.14. Розпорядник персональних даних </strong><span>- фізична чи юридична особа, якій володільцем
      персональних даних надано право обробляти ці дані від імені володільця. Для цілей цієї Політики, розпорядником
      персональних даних користувачів школи, є Товариство. Товариство обробляє персональні дані користувача школи
      відповідно до договору з такою школою.</span></p>
  <p><strong>1.15. Третя особа</strong><span> - будь-яка особа, за винятком суб&rsquo;єкта персональних даних, володільця
      чи розпорядника персональних даних.</span></p>
  <p><strong>1.16. Файли cookies </strong><span>- це маленькі частинки тексту, які зберігаються на пристроях
      користувачів під час відвідування певного сайту/сервісу. Згодом, коли користувач відвідує сайт/сервіс наступних
      разів, інформація, що зберігається у файлах cookies відправляється назад до сайту/сервісу. Це дозволяє
      розпізнавати користувача та пропонувати контент відповідно до потреб користувача.</span></p>
  <p><strong>1.17. Товариство </strong><span>- Товариство з обмеженою відповідальністю &ldquo;ЛАЄНВУД СОФТВЕР
      УКРАЇНА&rdquo; (код ЄДРПОУ 43009185), адреса реєстрації 79026, Львівська обл., місто Львів, вул. Курильська,
      будинок 3, квартира 3.&nbsp;</span></p>
  <p><strong>1.18. Використання Програмної продукції за функціональним призначенням -</strong><span> використання
      функціоналу Програмної продукції для реалізації прав користувача Програмної продукції відповідної категорії.
      Зокрема, Програмна продукція передбачає, для користувачів школи відповідних категорій, можливість доступу до
      електронних навчальних матеріалів; управління навчальними планами; формування розкладу уроків для вчителів та
      учнів; цифровий щоденник учня; цифровий журнал (включає оцінювання знань та&nbsp; зворотний зв&rsquo;язок
      використовуючи цифровий журнал); текстова, аудіо та відео комунікація.</span></p>
  <p><strong>1.19.&nbsp; Знеособлення персональних даних</strong><span> - вилучення відомостей, які дають змогу прямо чи
      опосередковано ідентифікувати особу;</span></p>
  <p><strong>1.20. Центр зберігання персональних даних - </strong><span>хмарне сховище, в якому зберігаються персональні
      дані користувачів Програмної продукції та яке надане у розпорядження Товариству Товариством з обмеженою
      відповідальністю &ldquo;ГІГАКЛАУД&rdquo; (КОД ЄДРПОУ 39792589) на підставі договору. ТОВ &ldquo;ГІГАКЛАУД&rdquo;
      має Атестат відповідності, який засвідчує, що комплексна система захисту інформації
      інформаційно-телекомунікаційного середовища, що належить ТОВ &ldquo;ГІГАКЛАУД&rdquo;, забезпечує захист інформації
      відповідно до вимог нормативних документів з технічного захисту інформації. Крім того, ТОВ &ldquo;ГІГАКЛАУД&rdquo;
      сертифіковані ISO/IEC 27001:2013 у сфері надання послуг хмарного сховища, дата центрів та інтернет послуг
      клієнтам.&nbsp;</span></p>
  <p>&nbsp;</p>
  <ol start="2">
    <li><h4>ЗАГАЛЬНІ ПОЛОЖЕННЯ&nbsp;</h4></li>
  </ol>
  <p><span>2.1. Ця Політика конфіденційності регламентує підстави та порядок обробки персональних даних користувачів
      Програмної продукції Товариством.</span></p>
  <p><span>2.2. Після укладення договору між школою та Товариством про постачання Програмної продукції, відповідно до
      умов даного договору, така школа виступає володільцем персональних даних користувачів школи, а Товариство -
      розпорядником таких персональних даних.&nbsp;</span></p>
  <p><span>2.3. Для того, аби певний користувач був приєднаний до школи та став користувачем школи, такий користувач
      повинен бути зареєстрований в Програмній продукції Адміністратором школи або має зареєструватися в Програмній
      продукції самостійно та надіслати заявку на приєднання до конкретної школи, та отримати від Адміністратора школи
      підтвердження заявки в Програмній продукції.</span></p>
  <p><span>2.5. Доступ до Програмної продукції осіб, що не досягли 18 років на дату здійснення першого доступу (в т.ч.
      учнів школи) можливий виключно за згодою одного з батьків такої особи. Така згода надається в момент реєстрації
      одним з батьків особи, що не досягла 18 років у Програмній продукції через обліковий запис одного з батьків. У
      випадку реєстрації облікових записів одного з батьків учня школи та учня школи Адміністратором школи, така згода
      надається в момент здійснення першого доступу до зареєстрованого облікового запису одного з батьків учня школи
      особою, коли такий доступ здійснюється одним з батьків учня школи.</span></p>
  <p><span>2.6. Для того, аби приєднати в Програмній продукції учня школи до школи, один з батьків через власний
      обліковий запис, при відображенні інформації про дитину, може приєднатися до певної школи. Учень буде відноситися
      до певної школи лише після того, коли Адміністратор школи через Програмну продукцію підтвердить заявку про
      приєднання. Коли облікові записи одного з батьків учня школи та учня школи були створені Адміністратором школи,
      такі облікові записи є автоматично приєднаними до цієї школи та не потребують додаткового подання та прийняття
      заявок на приєднання до школи.</span></p>
  <p><span>2.7. Персональні дані дітей обробляються виключно при наявності дозволу на обробку цих даних від одного з
      батьків, що наданий у порядку визначеному пп. 2.5., 2.6. цієї політики або коли реєстрація дитини була здійснена
      Адміністратором школи. При цьому, батьки повинні ознайомити своїх дітей з цією Політикою
      конфіденційності.&nbsp;</span></p>
  <p><span>2.8. Користування електронним засобом навчального призначення є добровільним і регулюється цією Політикою
      конфіденційності та Договором оферти.&nbsp;</span></p>
  <p>&nbsp;</p>
  <ol start="3">
    <li><h4>СКЛАД ТА ЗМІСТ ПЕРСОНАЛЬНИХ ДАНИХ</h4></li>
  </ol>
  <p><span>3.1. Склад персональних даних, що вноситься в систему користувачами обмежується виключно даними, що є
      необхідними для паперового документообігу у школі. Персональні дані користувачів обробляються в обсязі, наданому
      користувачами особисто або законними представниками користувачів в межах, необхідних для досягнення мети обробки
      персональних даних, що зазначена в цій Політиці конфіденційності.</span></p>
  <p><span>До складу персональних даних, що можуть оброблятися Товариством (як володільцем персональних даних, так і
      розпорядником персональних даних) відносяться:</span></p>
  <p><span>3.1.1. дані про користувача, які користувач самостійно додає в Програмну продукцію при самореєстрації
      (категорії користувачів школи Батьки (батько / мати) учня школи / Вчитель школи / Директор школи / Адміністратор
      школи; категорії користувачів (крім користувачів школи)):</span></p>
  <ul>
    <li><span>Особиста інформація: Прізвище*; Ім&rsquo;я*; По батькові*; Номер телефону*; Email*; День народження*;
        Стать.</span></li>
    <li><span>Фактичне місце проживання: Область; Місто / населений пункт; Вулиця; № Будинку / Квартири.</span></li>
    <li><span>Місце праці: Назва установи; Адреса; Посада; Робочий номер телефону.</span></li>
    <li><span>Копія документу, що посвідчує особу*;</span></li>
    <li><span>Додатково для категорій користувачів школи Вчитель школи, Директор школи та Адміністратор школи: Моя
        школа: Область*; Місто/Населений пункт*; Школа*; Предмет.</span></li>
  </ul>
  <p><span>3.1.2. дані про користувача, які користувач (категорії користувачів школи Батьки (батько / мати),(опікун/піклувальник)
      учня школи додає в Програмну продукцію при самостійній реєстрації Учня школи такі дані (категорія користувачів школи
      Учень школи (дитина - особа, що не досягла 18 років)):</span></p>
  <ul>
    <li><span>Особиста інформація: Прізвище*; Ім&rsquo;я*; По батькові*; Номер телефону*; Email*; День народження*;
        Стать.</span></li>
    <li><span>Фактичне місце проживання: Область; Місто / населений пункт; Вулиця; № Будинку / Квартири</span></li>
    <li><span>Додаткова інформація: Інтереси; Особливості; Соціальна група.</span></li>
    <li><span>Моя школа: Область*; Місто/Населений пункт*; Школа*; Клас; Копія свідоцтва про народження*.</span></li>
  </ul>
  <p><span>3.1.3. При цьому, якщо користувача школи реєструє у Програмній продукції Адміністратор школи, внесення копії
      документу, що посвідчує особу та/або копії свідоцтва про народження не вимагається у Програмній продукції.</span>
  </p>
  <p><span>3.1.4. інформація, що формується в результаті використання користувачами Програмної продукції;</span></p>
  <p><span>3.1.5. інформація, що стосується пристрою, який застосовується користувачем при використанні Програмної
      продукції (унікальні ідентифікатори пристрою, розмір екрану, тип пристрою, інформація про операційну
      систему);</span></p>
  <p><span>3.1.6. інформація, що обробляється під час користування Програмною продукцією (ІР адреса, інформація з
      браузера, геолокація (тільки країна), мова, що застосовується для відображення Програмної продукції, інформація,
      отримана з файлів cookies, відвідані сторінки в межах Програмної продукції, рухи мишею та натискання на кнопки в
      програмній продукції (онлайн поведінка користувача))</span></p>
  <p><span>3.2. При цьому персональні дані, поля для заповнення яких позначені зірочкою - заповнювати обов&rsquo;язково,
      в той час, як поля, що не позначені зірочкою - заповнювати не обов&rsquo;язково і користувач самостійно вирішує
      необхідність надання таких даних для обробки.</span></p>
  <p>&nbsp;</p>
  <ol start="4">
    <li><h4>МЕТА ТА ПІДСТАВИ ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ КОРИСТУВАЧІВ ШКОЛИ</h4></li>
  </ol>
  <p><span>4.1. Обробка Товариством, як розпорядником персональних даних, персональних даних користувача школи у
      Програмній продукції здійснюється від імені володільця персональних даних користувачів школи з метою забезпечення
      функціонування Програмної продукції та використання Програмної продукції володільцем персональних даних та
      користувачем школи за функціональним призначенням, надання відповідей на будь-які запити користувача школи,
      проведення аналізу ефективності роботи Програмної продукції, внесення змін, доповнень, розширень функціоналу
      Програмної продукції та інформування про це користувача школи, надсилання сповіщень, нагадувань, повідомлень,
      push-повідомлень, повідомлень на електронну пошту, смс-повідомлень з приводу використання користувачем школи
      Програмної продукції, а&nbsp; також виконання договорів, укладених між Товариством та володільцем персональних
      даних.&nbsp;</span></p>
  <p><span>4.2. Підставою для обробки Товариством, як розпорядником персональних даних, персональних даних користувача
      школи, з метою, що визначена в цій Політиці, є згода користувача на обробку його персональних даних розпорядником
      персональних даних, яка надається в момент проставлення відмітки (Я ознайомився (лася) та погоджуюся з Політикою
      конфіденційності та Умовами використання) при реєстрації у Програмній продукції. Відносно дітей, щодо яких
      зареєстровані батьки надають згоду на обробку персональних даних цих дітей, така згода надається в момент
      реєстрації дітей в Програмній продукції через обліковий запис батьків.</span></p>
  <p><span>4.3. У випадку самостійної реєстрації користувача в Програмній продукції, реєстрації своєї дитини через свій
      обліковий запис та надсилання заявки про приєднання до школи, обробка Товариством, як розпорядником персональних
      даних, персональних даних користувачів школи у Програмній продукції починається з моменту підтвердження заявки про
      приєднання до школи такого користувача Адміністратором школи. До цього моменту такий користувач не вважається
      користувачем школи, а його персональні дані обробляються у порядку, передбаченому Розділом 5 (МЕТА ТА ПІДСТАВИ
      ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ ІНШИХ КОРИСТУВАЧІВ (КРІМ КОРИСТУВАЧІВ ШКОЛИ)) цієї Політики конфіденційності.</span>
  </p>
  <p><span>4.4. Альтернативним способом реєстрації користувача школи (крім самореєстрації користувачів та підтвердження
      приєднання до школи Адміністратором школи) є реєстрація такого користувача у Програмній продукції самим
      Адміністратором школи. Адміністратор школи може створювати такі категорії користувачів школи: Вчителя школи;
      Батьків (батька/матір) учня школи, Учня школи. У випадку, коли користувач зареєстрований Адміністратором школи,
      такий користувач набуває статусу користувача школи (у розумінні цієї Політики конфіденційності) з моменту
      реєстрації. Відповідно, щодо такого користувача школи, володільцем персональних даних є школа, а Товариство -
      розпорядником з моменту реєстрації такого користувача. Згода на обробку персональних даних користувачем школи,
      надається в момент проставлення відмітки в Програмній продукції про надання дозволу на обробку своїх персональних
      даних (Я ознайомився (лася) та погоджуюся з Політикою конфіденційності та Умовами використання) при активації
      облікового запису такого користувача школи. В такому випадку, активація облікового запису користувача школи
      відбувається в момент переходу за посиланням, що надсилається автоматично користувачу школи, на електронну пошту в
      момент реєстрації такого користувача школи, в Програмній продукції Адміністратором школи.</span></p>
  <p>&nbsp;</p>
  <ol start="5">
    <li><h4>МЕТА ТА ПІДСТАВИ ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ ІНШИХ КОРИСТУВАЧІВ (КРІМ КОРИСТУВАЧІВ ШКОЛИ)</h4></li>
  </ol>
  <p><span>5.1. Обробка Товариством, як володільцем персональних даних,&nbsp; персональних даних інших користувачів
      (крім користувачів школи) здійснюється з метою забезпечення функціонування Програмної продукції, використання
      іншими користувачами (крім користувачів школи) її за функціональним призначенням та надання доступу до Програмної
      продукції таким користувачам (крім користувачів школи), а також для надання відповідей на будь-які запити
      користувачів та комунікація з користувачами, проведення аналізу ефективності роботи Програмної продукції, внесення
      змін, доповнень, розширень функціоналу Програмної продукції та інформування про це користувачів, надсилання
      сповіщень, нагадувань, повідомлень, push-повідомлень, повідомлень на електронну пошту, смс-повідомлень з приводу
      використання користувачами Програмної продукції.</span></p>
  <p><span>5.2. Підставою для обробки Товариством, як володільцем персональних даних, персональних даних користувача
      (крім користувачів школи), для цілей, що визначені в цій Політиці, є згода користувача на обробку його персональних
      даних, яка надається в момент проставлення відмітки (Я ознайомився (лася) та погоджуюся з Політикою конфіденційності
      та Умовами використання) при реєстрації в Програмній продукції. Відносно дітей, щодо яких зареєстровані батьки
      надають згоду на обробку персональних даних цих дітей, така згода надається в момент реєстрації дітей
      в Програмній продукції через обліковий запис батьків.</span></p>
  <p><span>5.3. Обробка Товариством, як володільцем персональних даних, персональних даних інших користувачів
      (крім користувачів школи) у Програмній продукції починається з моменту надання згоди на таку обробку, у порядку,
      передбаченому у п. 5.2. Проте, у випадку подання заявки про приєднання до певної школи іншими користувачами
      (крім користувачів школи) та підтвердження заявки про приєднання користувача до школи Адміністратором школи,
      такий користувач, у розумінні цієї Політики, набуває статусу користувача школи, а його персональні дані
      обробляються у порядку, передбаченому Розділом 4 (МЕТА ТА ПІДСТАВИ ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ КОРИСТУВАЧІВ,
      ЩО ВІДНОСЯТЬСЯ ДО ШКОЛИ) цієї Політики конфіденційності.</span></p>
  <p>&nbsp;</p>
  <ol start="6">
    <li><h4> СТРОК ТА УМОВИ ОБРОБКИ ТА ЗБЕРІГАННЯ ПЕРСОНАЛЬНИХ ДАНИХ</h4></li>
  </ol>
  <p><span>6.1. Після надання згоди суб’єктом персональних даних на обробку персональних даних у спосіб та у порядку,
      що визначений у цій Політиці або при реєстрації користувача Адміністратором школи, персональні дані обробляються
      впродовж строку, не більшого, ніж це необхідно відповідно до мети обробки персональних даних (не довше, ніж це
      передбачено законодавством у сфері архівної справи та діловодства).</span></p>
  <p><span>6.2. Електронні документи, які були створені за допомогою Програмної продукції та можуть містити персональні
      дані користувачів зберігаються протягом строку, який передбачений чинним законодавством у сфері архівної справи
      та діловодства для такого ж виду документів в паперовій формі.</span></p>
  <p><span>6.3. У випадку закінчення необхідності обробки персональних даних відповідно до визначеної мети обробки
      персональних даних, такі дані знищуються, якщо інше не передбачено чинним законодавством України.</span></p>
  <p><span>6.4. У випадку відкликання користувачем згоди на обробку персональних даних, а також у випадку вимоги
      користувача знищити його персональні дані в Програмній продукції, персональні дані такого Користувача знищуються
      у Програмній продукції, якщо інше не передбачено чинним законодавством України.</span></p>
  <p><span>6.5. Товариство докладає максимум зусиль, аби персональні дані Користувачів зберігалися у безпеці. Відповідно,
      персональні дані Користувачів Програмної продукції зберігаються у центрі зберігання персональних даних.</span></p>
  <p><span>6.6.&nbsp;Персональні дані мають бути точними, достовірними та оновлюватися в міру потреби, визначеної метою
      їх обробки. У разі зміни персональних даних користувача, користувач самостійно змінює персональні дані про себе
      у Програмній продукції шляхом редагування інформації, що міститься в обліковому записі користувача. Щодо даних,
      які користувач не може самостійно змінити у власному обліковому запису, користувач надсилає Товариству запит
      на зміну таких даних та надає документи, що підтверджують необхідність зміни даних такого користувача.</span></p>
  <p><span>6.7. Товариство, за допомогою організаційних та технічних заходів, забезпечує захист персональних даних від
      випадкових втрати або знищення, від незаконної обробки, у тому числі незаконного знищення чи доступу до
      персональних даних.</span></p>
  <p><span>6.7.1. Зокрема, Товариство вживає такі організаційні та технічні заходи:</span></p>
  <ul>
    <li><span>визначає порядок та рівень доступу працівників (підрядників) Товариства до персональних даних користувачів
        в Програмній продукції. Особи, що отримують доступ до персональних даних, доступ до яких необхідний таким особам
        для виконання їх зобов’язань перед володільцем чи розпорядником персональних даних, виключно в обсязі,
        що необхідний для виконання таких зобов'язань, а також обробляють такі дані виключно відповідно до мети обробки
        персональних даних, що визначена в цій Політиці конфіденційності;</span></li>
    <li><span>забезпечує, щоб працівники (підрядники), які для виконання своїх зобов’язань перед володільцем або
        розпорядником персональних даних мають доступ до персональних даних, зобов'язані не допускати розголошення
        у будь-який спосіб персональних даних, які їм було довірено або які стали відомі у зв'язку з виконанням їх
        обов'язків, крім випадків, передбачених законом. Таке зобов'язання чинне після припинення ними діяльності,
        пов'язаної з персональними даними, крім випадків, установлених законом;</span></li>
    <li><span>веде облік операцій, пов’язаних з обробкою персональних даних суб’єкта та доступом до них. Зокрема,
        інформація про операції, пов’язані з обробкою персональних даних, автоматично фіксується у Програмній продукції;</span></li>
    <li><span>паролі користувачів захищені стандартним шифруванням, а дані передаються по захищеному протоколу;</span>
    </li>
    <li><span>паролі працівників (підрядників) Товариства, створені з врахуванням відповідних правил безпечності паролів,
      та змінюються періодично (не рідше одного разу на три місяці), а також за певних обставин, коли є об’єктивні
      підстави вважати, що конкретний пароль слід змінити;</span></li>
    <li><span>працівник (підрядник), який працює на певному комп’ютері, знає лише свій пароль та зобов’язаний не
        повідомляти свій пароль будь-яким третім особам;</span></li>
    <li><span>персональні дані не використовують для тестування оновлень Програмної продукції, при тестуванні
        застосовуються знеособлені дані (псевдоніми);</span></li>
    <li><span>забезпечується резервне копіювання та аварійне відновлення персональних даних;</span></li>
    <li><span>навчання працівників (підрядників) які мають доступ до персональних даних щодо порядку обробки
        персональних даних;</span></li>
    <li><span>здійснює перевірку вразливості безпеки Програмної продукції;</span></li>
    <li><span>забезпечує наявність антивірусних програм на всіх пристроях, за допомогою яких відбувається доступ до
        персональних даних працівниками (підрядниками) Товариства;</span></li>
    <li><span>після виявлення порушень безпеки персональних даних, Товариство вживає негайних заходів для запобігання
        несанкціонованій обробці персональних даних.</span></li>
  </ul>
  <p>&nbsp;</p>
  <ol start="7">
    <li><h4>УМОВИ ТА ПРОЦЕДУРИ ПЕРЕДАЧІ ПЕРСОНАЛЬНИХ ДАНИХ</h4></li>
  </ol>
  <p><span>7.1. Відповідно до умов цієї Політики конфіденційності, у наступних суб’єктів відносин, пов’язаних з
      персональними даними, може бути доступ до персональних даних користувачів:</span></p>
  <p><span>7.1.1. Школа, як володілець персональних даних користувачів школи, стосовно персональних даних користувачів
      школи;</span></p>
  <p><span>7.1.2. Товариство, як розпорядник персональних даних користувачів школи стосовно персональних даних
      користувачів школи;</span></p>
  <p><span>7.1.3. Товариство, як володілець персональних даних, інших користувачів (крім користувачів школи),
      стосовно персональних даних інших користувачів (крім користувачів школи);</span></p>
  <p><span>7.1.4. Працівники (підрядники) Товариства, як володільця/розпорядника персональних даних, які отримують
      доступ до персональних даних, доступ до яких необхідний таким особам для виконання їх зобов’язань перед Товариством,
      як володільцем/розпорядником персональних даних, виключно в обсязі, що необхідний для виконання їх зобов’язань.
      Такі працівники (підрядники) обробляють такі дані виключно відповідно до мети обробки персональних даних,
      що визначена в цій Політиці конфіденційності;</span></p>
  <p><span>7.1.5. Центр зберігання персональних даних, стосовно персональних даних всіх користувачів.</span></p>
  <p><span>7.2. Будь-яка передача (розкриття) персональних даних користувачів відбувається виключно з метою та в межах,
      що визначені в цій Політиці конфіденційності або у чинному законодавстві.</span></p>
  <p><span>7.3. Товариство, як володілець/розпорядник персональних даних, в жодному випадку не буде продавати
      персональні дані користувачів Програмної продукції будь-якій третій особі.
      Будь-яке розголошення/розкриття/передача/надання доступу до персональних даних відбувається виключно в порядку,
      на умовах та в межах, що визначені цією Політикою конфіденційності.</span></p>
  <p><span>7.4. У випадку доручення проведення третім сторонам будь-яких досліджень (маркетингових тощо),
      отримання певних послуг, що стосуються Програмної продукції, від третіх осіб, Товариство не надаватиме таким
      особам доступу до персональних даних користувачів Програмної продукції. При цьому, якщо характером таких послуг
      передбачено необхідність доступу до знеособлених даних, то такий доступ може бути надано виключно до знеособлених
      даних.</span></p>
  <p><span>7.5. Персональні дані не передаються будь-яким іноземним особам.</span></p>
  <p><span>7.6. З метою отримання будь-якої інформації щодо обробки своїх персональних даних користувачі можуть
      звернутися:</span></p>
  <p><span>7.6.1. Користувачі школи до школи або до Адміністратора школи через свій обліковий запис у Програмній
      продукції;</span></p>
  <p><span>7.6.2. Користувачі школи, а також інші користувачі (крім користувачів школи) до Товариства за адресою
      електронної пошти support&#64;nit.school.&nbsp;</span></p>
  <p><span>7.7. Поширення персональних даних без згоди користувача дозволяється у випадках, визначених законом,
      і лише (якщо це необхідно) в інтересах національної безпеки, економічного добробуту та прав людини.</span></p>
  <p>&nbsp;</p>
  <ol start="8">
    <li><h4>ФАЙЛИ COOKIES</h4></li>
  </ol>
  <p><span>8.1. У Програмній продукції можуть використовуватися файли cookies для аналітики роботи Програмної продукції
      та оцінки ефективності маркетингових кампаній.&nbsp;</span></p>
  <p><span>8.2. Файли cookies в жодному випадку не збирають та не зберігають персональних даних користувачів
      Програмної продукції. Інформація, яка збирається за допомогою файлів cookies - збирається виключно в знеособленій
      та зашифрованій формі. Ідентифікувати певного користувача за допомогою інформації, що збирається файлами cookies
      - неможливо.</span></p>
  <p><span>8.3. Користувач може вручну керувати файлами cookies через налаштування браузера. Відповідно, користувач
      має змогу налаштувати браузер таким чином, щоб браузер не приймав файлів cookies на пристрій користувача.</span></p>
  <p><span>8.4. У випадку, якщо користувач налаштував свій браузер таким чином, що файли cookies браузер не приймає,
      деякі функції Програмної продукції можуть працювати неправильно або не відображатися зовсім.</span></p>
  <p>&nbsp;</p>
  <ol start="9">
    <li><h4> ПРАВА КОРИСТУВАЧА</h4></li>
  </ol>
  <p><span>9.1. Користувач Програмної продукції має право:</span></p>
  <p><span>9.1.1. знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх обробки,
      місцезнаходження або місце проживання (перебування) володільця чи розпорядника персональних даних або дати
      відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім випадків, встановлених законом;</span></p>
  <p><span>9.1.2. отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб,
      яким передаються його персональні дані;</span></p>
  <p><span>9.1.3.&nbsp; на доступ до своїх персональних даних;</span></p>
  <p><span>9.1.4. отримувати не пізніше як за тридцять календарних днів з дня надходження запиту, крім випадків,
      передбачених законом, відповідь про те, чи обробляються його персональні дані, а також отримувати зміст таких
      персональних даних;</span></p>
  <p><span>9.1.5. пред’являти вмотивовану вимогу володільцю персональних даних із запереченням проти обробки своїх
      персональних даних;</span></p>
  <p><span>9.1.6. пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким володільцем
      та розпорядником персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;</span></p>
  <p><span>9.1.7.&nbsp;на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження
      у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей,
      що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;</span>
  </p>
  <p><span>9.1.8. звертатися зі скаргами на обробку своїх персональних даних до Уповноваженого ВРУ з прав людини або
      до суду;</span></p>
  <p><span>9.1.9. застосовувати засоби правового захисту в разі порушення законодавства про захист персональних
      даних;</span></p>
  <p><span>9.1.10. вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання
      згоди;</span></p>
  <p><span>9.1.11.&nbsp; відкликати згоду на обробку персональних даних;</span></p>
  <p><span>9.1.12.&nbsp; знати механізм автоматичної обробки персональних даних;</span></p>
  <p><span>9.1.13. на захист від автоматизованого рішення, яке має для нього правові наслідки.</span></p>
  <p><span>9.2. Для реалізації будь-якого права користувача що зазначене вище, користувач може звернутися з письмовим
      запитом до Товариства, в будь-який час, шляхом направлення електронного листа на адресу електронної пошти:
      support&#64;nit.school.&nbsp;</span></p>
  <p><span>9.3. Впродовж 10 (десяти) робочих днів з дати надходження запиту, Товариство, як володілець/розпорядник
      персональних даних, доводить до відома користувача, який подає запит, що запит буде задоволено або відповідні
      персональні дані не підлягають наданню, із зазначенням підстави, визначеної у відповідному нормативно-правовому
      акті. При цьому, запит задовольняється протягом тридцяти календарних днів з дня його надходження, якщо інше не
      передбачено законом.</span></p>
  <p>&nbsp;</p>
  <ol start="10">
    <li><h4>ЗАКЛЮЧНІ ПОЛОЖЕННЯ</h4></li>
  </ol>
  <p><span>10.1.&nbsp; У Програмній продукції можуть бути розміщені активні посилання на вебсайти чи інші ресурси та сервіси
      третіх сторін. Такі посилання розміщені лише для зручності користувача та в жодному випадку не засвідчують згоду
      чи підтримку Товариством будь-якої інформації, що розміщена за такими посиланнями. Крім того, положення цієї
      Політики конфіденційності не застосовуються при відвідуванні користувачами таких сторонніх вебсайтів чи сервісів.
      На вебсайтах та сервісах третіх сторін можуть оброблятися та вимагатися інші персональні дані, в іншому обсязі
      та в іншому порядку.</span></p>
  <p><span>10.2. Товариство не несе жодної відповідальності за зміст та точність інформації, що розміщена на вебсайтах
      чи інших ресурсах третіх сторін.</span></p>
  <p><span>10.3. Положення цієї Політики конфіденційності застосовуються з моменту їх публікації у Програмній
      продукції.&nbsp;</span></p>
  <p><span>10.4. Товариство, у межах чинного законодавства України, а також у межах умов договору зі школою, може,
      в будь-який час, вносити зміни до цієї Політики конфіденційності.</span></p>
  <p><span>10.5. При внесенні будь-яких змін, оновлена Політика конфіденційності публікується у Програмній продукції
      та застосовується не раніше, дати її оприлюднення. При цьому, користувачам доступні попередні версії політик
      конфіденційності, а також інформація про дати оприлюднення таких політик.</span></p>
  <p><span>10.6. Товариство рекомендує користувачам періодично переглядати Політику конфіденційності, аби бути в
      курсі всіх змін та оновлень.</span></p>
  <p><span>10.7. У випадку наявності будь-яких питань стосовно цієї Політики конфіденційності, користувач може
      звертатися за електронною адресою support&#64;nit.school.</span></p>
  <p><span>10.8. Використовуючи Програмну продукцію, користувач підтверджує, що ознайомився з умовами цієї Політики
      конфіденційності.</span></p>
  <p><span>10.9. У випадку, коли користувач не погоджується з умовами Політики конфіденційності - користувач не може
      використовувати Програмну продукцію.</span></p>
  <p>&nbsp;</p>
  <h4>КОРИСТУВАЧ, ЩО ВИКОРИСТОВУЄ ЕЛЕКТРОННИЙ ЗАСІБ НАВЧАЛЬНОГО ПРИЗНАЧЕННЯ “ІНФОРМАЦІЙНО-КОМУНІКАЦІЙНА СИСТЕМА ”НІТ”
    НАВЧАННЯ І ТЕХНОЛОГІЇ”, ПІДТВЕРДЖУЄ, ЩО ОЗНАЙОМЛЕНИЙ І ЗГОДЕН З ЦІЄЮ ПОЛІТИКОЮ КОНФІДЕНЦІЙНОСТІ ТА УМОВАМИ
    ОБРОБКИ ЙОГО ПЕРСОНАЛЬНИХ ДАНИХ.</h4>
  <strong>Контактна інформація.</strong>
  <p><span>ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ЛАЄНВУД СОФТВЕР УКРАЇНА", в особі директора
      Каміновського Володимира Андрійовича, <br> який діє на підставі Статуту </span></p>
  <p><span>ЄДРПОУ 43009185</span></p>
  <p><span>Ел.пошта: support&#64;nit.school</span></p>
  <p><span>Web-сайт: <a href="https://nit.school/">https://nit.school/</a></span></p>
</nit-policy-container>
