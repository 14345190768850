<nit-modal
  #modal
  id="replace-all-lessons-modal"
  titleSize="small"
  [width]="422"
  [title]="title()"
  [isPopup]="true">
  @if (isCompleted()) {
    <div class="error-block">
      <p>Важливо! Після підтвердження видалення заміни усі дані про проведення заміни та відмітки в журналі будуть видалені.</p>
    </div>
  }
  <div class="buttons-container">
    <nit-button type="refuse" (click)="confirm().close()">Скасувати</nit-button>
    <nit-button type="delete" (click)="delete()">Видалити</nit-button>
  </div>
</nit-modal>
