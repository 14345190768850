import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {AuthService} from '@nit-core/auth';

@Injectable()
export class InSchoolGuard {
  constructor(private readonly router: Router, private readonly _auth: AuthService) { }

  canActivate(): boolean | UrlTree {
    if (this._auth.getUserSchoolId()) {
      return true;
    } else {
      return this.router.createUrlTree(['/404']);
    }
  }
}
