import {Routes} from '@angular/router';

export const personalScheduleRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./personal-schedule.component').then(m => m.PersonalScheduleComponent)
  },
  {
    path: ':teacherId',
    loadComponent: () => import('./personal-schedule.component').then(m => m.PersonalScheduleComponent)
  }
];
