import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalComponent} from '@nit-components';
import {NitForm, NValidate, NitFieldWrapperComponent} from '@nit-forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {AchievementWishes, WishType} from '@nit-core/models/achievement-wishes';
import {NushDecision} from '@nit-core/models/nush-decision';
import {AchievementWishService} from '@nit-services';
import {TrimInputDirective} from '@nit-core/directives/trim-input.directive';
import {NitToastr} from '@nit-core/services/global/nit-toastr.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'nit-add-wish-modal',
  templateUrl: './add-wish-modal.component.html',
  styleUrl: './add-wish-modal.component.scss',
  standalone: true,
  imports: [ModalComponent, FormsModule, ReactiveFormsModule, NitFieldWrapperComponent, TrimInputDirective]
})
export class AddWishModalComponent {
  @ViewChild('modal') confirm: ModalComponent;

  @Input() isParent: boolean = false;
  @Input() isEditMode: boolean = false;
  @Input() schoolId: number;
  @Input() childId: string;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  form: NitForm = new NitForm({
    id: new FormControl(),
    classId: new FormControl(null, NValidate.required),
    userId: new FormControl(null, NValidate.required),
    transferId: new FormControl(null),
    wish: new FormControl('', [NValidate.maxLen(250), NValidate.required]),
    type: new FormControl(null, NValidate.required),
  });
  title: string;
  wish: AchievementWishes;

  constructor(private readonly _achievementWishesService: AchievementWishService,
              private readonly _toastr: NitToastr) { }

  open(decision: NushDecision, wishType: WishType = WishType.ClassTeacher,
    isEditMode: boolean = false, wish?: AchievementWishes): void {
    this.form.reset({type: wishType});
    this.isEditMode = isEditMode;
    this.title = isEditMode ? `Редагування ${this.isParent ? 'побажання' : 'рекомендації'}`
      : `Залишити ${this.isParent ? 'побажання' : 'рекомендацію'}`;

    if (wish) {
      this.wish = wish;
      this.form.setValue(wish);
    }
    this.confirm.open();

    if (decision && !wish && !this.isEditMode) {
      this.form.get('classId').setValue(decision?.classId);
      this.form.get('userId').setValue(decision?.userId);
      this.form.get('transferId').setValue(decision?.leaveTransferId);
    }
  }

  onSubmit(): void {
    if (this.form.invalid) return;

    const childId = this.childId || null;

    const method = this.isEditMode ?
      this._achievementWishesService.update(this.wish.id, this.form.value, {asUserId: childId, asUserSchoolId: this.schoolId}) :
      this._achievementWishesService.create(this.form.value, this.schoolId, childId);

    method.subscribe(() => {
      this.submitted.emit();
      this._toastr.success(`${this.isParent ? 'Побажання' : 'Рекомендацію'} успішно ${
        this.isEditMode ? 'змінено' : 'створено'}`);
      this.confirm.close();
    });
  }
}
