import {Routes} from '@angular/router';

export const childClassProfileRouting: Routes = [{
  path: '',
  children: [
    {
      path: '',
      loadComponent: () => import('../../../basic-data/pages/classes/pages/class-info/class-profile/class-profile.component').then(m => m.ClassProfileComponent),
    },
    {
      path: 'materials',
      data: {
        readonly: true,
        breadcrumb: 'Навчальні матеріали'
      },
      loadComponent: () => import('../../../basic-data/pages/classes/pages/educational-materials/educational-materials.component').then(m => m.EducationalMaterialsComponent)
    },
    {
      path: 'schedule',
      data: {
        breadcrumb: 'Розклад класу'
      },
      loadComponent: () => import('../../../basic-data/pages/classes/pages/class-schedule/class-schedule.component').then(m => m.ClassScheduleComponent)
    },
    {
      path: 'groups',
      data: {
        breadcrumb: 'Групи класів'
      },
      loadComponent: () => import('../../../basic-data/pages/classes/pages/class-groups/class-groups.component').then(m => m.ClassGroupsComponent)
    },
    {
      path: 'class-journal',
      data: {
        breadcrumb: 'Журнал класу'
      },
      // eslint-disable-next-line max-len
      loadComponent: () => import('../../../basic-data/pages/class-journal/pages/class-journal/class-journal.component').then(m => m.ClassJournalComponent)
    }
  ]
}];
