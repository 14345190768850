import {Routes} from '@angular/router';
import {TransfersGuard} from '@nit-core/guards';
import {classJournalRoot} from '../../../class-journal/class-journal-root-routing';

export const classInfoRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./class-profile/class-profile.component').then(m => m.ClassProfileComponent)
  },
  {
    path: 'groups',
    loadComponent: () => import('../../pages/class-groups/class-groups.component').then(m => m.ClassGroupsComponent),
    data: {
      breadcrumb: 'Групи класів'
    }
  },
  {
    path: 'materials',
    loadComponent: () => import('../../pages/educational-materials/educational-materials.component').then(m => m.EducationalMaterialsComponent),
    data: {
      breadcrumb: 'Навчальні матеріали'
    }
  },
  {
    path: 'schedule',
    loadComponent: () => import('../../pages/class-schedule/class-schedule.component').then(m => m.ClassScheduleComponent),
    data: {
      breadcrumb: 'Розклад класу'
    }
  },
  {
    path: 'class-journal',
    children: classJournalRoot,
    data: {
      breadcrumb: 'Журнал класу'
    }
  },
  {
    path: 'transfers',
    canActivate: [TransfersGuard],
    data: {
      breadcrumb: 'Рух учнів'
    },
    loadComponent: () => import('../students-transfer/students-transfer.component').then(m => m.StudentsTransferComponent)
  }
];
