import './app/core/global/extensions/array-sorting'; // TODO investigate index approach
import './app/core/global/extensions/string-trim'; // TODO investigate index approach
import {
  enableProdMode,
  LOCALE_ID,
  isDevMode,
  importProvidersFrom,
} from '@angular/core';
import {environment} from './environments/environment';
import 'hammerjs';
import {AuthService} from '@nit-core/auth';
import {GlobalService} from '@nit-core/services/global/global.service';
import {OAuthModuleConfig, OAuthStorage, OAuthModule} from 'angular-oauth2-oidc';
import {HttpInterceptorProviders} from '@nit-core/interceptors/interceptor-providers';
import {TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {BrowserModule, bootstrapApplication} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {AppRoutes} from './app/app-routes';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxPermissionsModule} from 'ngx-permissions';
import {LayoutModule} from '@angular/cdk/layout';
import {HammerConfigModule} from '@nit-core/modules/hammer-js.module';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AppComponent} from './app/app.component';
import {PermissionService} from '@nit-core/permission/permission.service';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {ukLocale} from 'ngx-bootstrap/locale';
import {registerLocaleData} from '@angular/common';
import localeUk from '@angular/common/locales/uk';
import 'zone.js';
import {provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig} from '@angular/router';

if (environment.production) {
  enableProdMode();
}

defineLocale('uk', ukLocale);
registerLocaleData(localeUk);

function authConfigFactory(service: GlobalService): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [
        service.apiUrl,
        service.maintenanceUrl,
      ],
      sendAccessToken: true,
    }
  };
}

// console.log(AppModule);
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch(err => console.error(err));

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(moduleRef => console.log('Bootstrap success:', moduleRef))
//   .catch(err => console.error('Bootstrap error:', err));

// platformBrowserDynamic()
//   .bootstrapModule(AppModule, { ngZone: 'noop' }) // Використовуй 'noop' для беззонового режиму
//   .catch(err => console.error(err));

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      PermissionService,
      BrowserModule,
      ReactiveFormsModule,
      OAuthModule.forRoot(),
      NgxPermissionsModule.forRoot(),
      LayoutModule,
      HammerConfigModule,
      NgxGoogleAnalyticsModule.forRoot('G-BG32K1WQ4Q'),
      NgxGoogleAnalyticsRouterModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
      })),
    AuthService,
    GlobalService,
    HttpInterceptorProviders,
    {
      provide: OAuthModuleConfig,
      useFactory: authConfigFactory,
      deps: [GlobalService],
    },
    {provide: OAuthStorage, useValue: localStorage},
    {provide: LOCALE_ID, useValue: 'uk-UA'},
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    // TODO: check if working or switch to zonejs
    provideRouter(AppRoutes, withComponentInputBinding(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })
    )
  ]
}).catch(err => console.error(err));

// TODO: init firebase app
// initializeApp(environment.firebase);
