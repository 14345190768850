<nit-modal #informModal
           [title]="'Самостійна реєстрація учня недоступна'"
           [titleSize]="'small'"
           [isPopup]="true"
           [width]="422">
 <div class="main">
   <span>Батьки реєструються в платформі і створюють профіль учня через розділ “Мої діти” - “Створити профіль дитини”</span>
   <button (click)="informModal.close()">Закрити</button>
 </div>
</nit-modal>
