import {Routes} from '@angular/router';

export const courseDetailsRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./course-details.component').then(m => m.CourseDetailsComponent),
  },
  {
    path: 'lessons-content/:lessonId',
    loadComponent: () => import('../courses-lesson-content/courses-lesson-content.component').then(m => m.CoursesLessonContentComponent),
    data: {
      breadcrumb: 'Заповнення уроку',
      permissions: ['update:course']
    }
  },
  {
    path: 'lessons-content/:lessonId/edit',
    loadComponent: () => import('../courses-lesson-content/courses-lesson-content.component').then(m => m.CoursesLessonContentComponent),
    data: {
      breadcrumb: 'Редагування уроку',
      permissions: ['update:course'],
      isEdit: true
    }
  },
  {
    path: 'permissions-settings',
    loadComponent: () => import('../permissions-settings/permissions-settings.component').then(m => m.PermissionsSettingsComponent),
    data: {
      breadcrumb: 'Налаштування доступу',
      permissions: ['update:course']
    }
  },
  {
    path: 'edit',
    loadComponent: () => import('./../../pages/create-edit-course/create-edit-course.component').then(m => m.CreateEditCourseComponent),
    data: {
      breadcrumb: 'Редагування курсу',
      permissions: ['update:course'],
      isEdit: true
    }
  }
];
