<div class="header-replacement-history">
  <nit-page-title>{{ title() }}</nit-page-title>

<!--  TODO NEED API FOR Generate Report-->
<!--  <nit-header-menu-actions [withPdf]="true"-->
<!--                           [withXls]="true"-->
<!--                           (generateReport)="generateReport($event.t ype)">-->
<!--  </nit-header-menu-actions>-->
</div>

<kendo-grid #grid gridBulk
            id="replacement-table"
            pageSize="15"
            [restService]="teacherReplacementService"
            [sort]="sort"
            [filterable]="true"
            [reorderable]="true"
            [defaultFilter]="defaultFilter"
            [rowClass]="rowCallback">
  <kendo-grid-column title="№ п/п" [width]="50">
    <ng-template let-index="rowIndex" kendoGridCellTemplate>
      <span class="index-row">{{ index + 1 }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="date" title="Дата" [width]="135">
    <ng-template kendoGridFilterCellTemplate>
      <nit-datepicker class="data-picker"
                      (valueChange)="onDateChange($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      <span>{{ dataItem?.date | date: 'dd.MM.yyyy' }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="originalTeacher.fullName"
                     [title]="isReplacementHistory() || isMyReplacement() ? 'Вчитель, якого заміняли' : 'Вчитель, який пропустив урок'"
                     [width]="250">
    <ng-template let-filter let-column="column" kendoGridFilterCellTemplate>
      <kendo-grid-string-filter-cell [column]="column"
                                     [filter]="filter"
                                     [showOperators]="false" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      <div class="full-name-wrapper">
        @if (dataItem?.isInvalid && !isReplacementHistory()) {
        <img src="assets/icons/atention.svg" alt="atention">
        }
        <span
          [class.danger-text]="dataItem?.isInvalid && !isReplacementHistory()">{{ dataItem.originalTeacher.fullName }}
        </span>
      </div>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="replacementReasonType" title="Причина заміни" [width]="200">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #reasonTypeDropdown
                           component="reasonType"
                           textField="text"
                           valueField="replacementReasonType"
                           [filter]="filter"
                           [defaultText]="'Всі'"
                           [data]="replaceReasonType()"
                           (opened)="openDropdownPopover($event, reasonTypeDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      <span>{{ dataItem.replacementReasonType | getTextFromEnum : 'ReplaceReasonTypes' }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="subject.name" title="Заміна з предмету" [width]="200">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #subjectDropdown
                           textField="name"
                           valueField="name"
                           component="subject.name"
                           [defaultText]="'Всі'"
                           [defaultValue]="'Всі'"
                           [filter]="filter"
                           [data]="subjectsList()"
                           (opened)="openDropdownPopover($event, subjectDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      <div class="d-flex align-items-center">
        <span class="subject-name">{{ dataItem?.subject.name }}</span>
        @if (dataItem.subject.isFds) {
          <span class="fds-label ml-1">ШПД</span>
        }
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="number" title="Порядковість уроку" [width]="150">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #lessonNumberDropdown
                           textField="number"
                           valueField="number"
                           component="number"
                           [defaultText]="'Всі'"
                           [defaultValue]="'Всі'"
                           [filter]="filter"
                           [data]="lessonsList"
                           (opened)="openDropdownPopover($event, lessonNumberDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      {{ dataItem.number }} <span>урок</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="class.name" title="В якому класі" [width]="110">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #classDropdown
                           textField="name"
                           valueField="name"
                           component="class.name"
                           [defaultText]="'Всі'"
                           [defaultValue]="'Всі'"
                           [filter]="filter"
                           [data]="classes()"
                           (opened)="openDropdownPopover($event, classDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      {{ dataItem.class.name }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="group" title="Група" [width]="100">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #groupDropdown
                           textField="name"
                           valueField="name"
                           component="group.name"
                           [defaultText]="'Всі'"
                           [defaultValue]="'Всі'"
                           [filter]="filter"
                           [data]="groupItems"
                           (opened)="openDropdownPopover($event, groupDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      {{ dataItem.group?.name ? dataItem.group?.name : '-' }}
    </ng-template>
  </kendo-grid-column>

  @if (!isMyReplacement()) {
    <kendo-grid-column field="replacementTeacher.fullName" title="Вчитель, який заміщав урок" [width]="200">
      <ng-template let-filter let-column="column" kendoGridFilterCellTemplate>
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false" />
      </ng-template>

      <ng-template let-dataItem kendoGridCellTemplate>
        {{ dataItem.replacementTeacher?.fullName ? dataItem.replacementTeacher?.fullName : '-'  }}
      </ng-template>
    </kendo-grid-column>
  }
  <kendo-grid-column field="replacementSubject" title="Яким предметом" [width]="150">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #replacementSubjectDropdown
                           textField="name"
                           valueField="name"
                           component="replacementSubject.name"
                           [defaultText]="'Всі'"
                           [defaultValue]="'Всі'"
                           [filter]="filter"
                           [data]="subjectsList()"
                           (opened)="openDropdownPopover($event, replacementSubjectDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      <div class="d-flex align-items-center">
        <span
          class="subject-name">{{ dataItem?.replacementSubject?.name ? dataItem?.replacementSubject?.name : '-' }}</span>
        @if (dataItem?.replacementSubject?.isFds) {
          <span class="fds-label ml-1">ШПД</span>
        }
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="paymentType" title="Відомості про оплату" [width]="200">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #paymentTypeDropdown
                           component="paymentType"
                           textField="text"
                           valueField="paymentType"
                           [filter]="filter"
                           [defaultText]="'Всі'"
                           [data]="replacePaymentTypes()"
                           (opened)="openDropdownPopover($event, paymentTypeDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      {{ dataItem.paymentType | getTextFromEnum : 'ReplacePaymentTypes' }}
    </ng-template>
  </kendo-grid-column>

  @if (!isMyReplacement()) {
    <kendo-grid-column field="isOfficial" title="В журналі обліку" [width]="150">
      <ng-template let-filter kendoGridFilterCellTemplate>
        <nit-dropdown-filter #isOfficialDropdown
                             component="isOfficial"
                             textField="name"
                             valueField="isOfficial"
                             [filter]="filter"
                             [defaultText]="'Всі'"
                             [data]="inAccountingJournal"
                             (opened)="openDropdownPopover($event, isOfficialDropdown)"
                             (clickOutside)="handleClickOutside($event)" />
      </ng-template>

      <ng-template let-dataItem kendoGridCellTemplate>
        @if (dataItem?.isOfficial) {
          <img class="icon" src="assets/icons/check-mark-green.svg" alt="check">
        }
        @if (!dataItem?.isOfficial) {
          <span>-</span>
        }
      </ng-template>
    </kendo-grid-column>
  }

  <kendo-grid-column field="completed" title="Статус" [width]="150">
    <ng-template let-filter kendoGridFilterCellTemplate>
      <nit-dropdown-filter #isOfficialDropdown
                           component="completed"
                           textField="name"
                           valueField="completed"
                           [filter]="filter"
                           [defaultText]="'Всі'"
                           [data]="status"
                           (opened)="openDropdownPopover($event, isOfficialDropdown)"
                           (clickOutside)="handleClickOutside($event)" />
    </ng-template>

    <ng-template let-dataItem kendoGridCellTemplate>
      @if (isMyReplacement()) {
          <nit-custom-checkbox label="Проведено"
                               [isWithBorder]="true"
                               [field]="dataItem.id"
                               [isChecked]="dataItem.completed"
                               [isDisabled]="dataItem.completed"
                               [disableTemplate]="true"
                               (change)="confirmCompletion(dataItem?.id)" />
      }

      @if (!isMyReplacement()) {
        @if (dataItem?.completed) {
          <img class="icon" src="assets/icons/check-mark-green.svg" alt="check">
        }
        @if (!dataItem?.completed) {
          <span>-</span>
        }
      }
    </ng-template>
  </kendo-grid-column>

  @if (!isMyReplacement()) {
    <kendo-grid-column [width]="70" [style]="{'text-align': 'right'}">
      <ng-template let-dataItem class="index-row" kendoGridCellTemplate>
        <nit-button-context-menu
          [className]="'table-action-buttons'"
          [items]="[{
                  mode: 'edit',
                  text: 'Редагувати',
                  icon: '/assets/icons/edit.svg',
                  visible: true,

                }, {
                  mode: 'remove',
                  text: 'Видалити',
                  icon: '/assets/icons/delete.svg',
                  color: 'var(--support-danger-300)',
                  visible: true,
                }
              ]"
          (itemClicked)="contextHandler($event, dataItem)" />
      </ng-template>
    </kendo-grid-column>
  }

  <ng-template kendoGridNoRecordsTemplate>
    <nit-no-data [hasData]="false"
                 [text]="'Замін не знайдено'" />
  </ng-template>

  <kendo-grid-messages pagerOf="з" pagerItems="елементів" pagerItemsPerPage="елементів на сторінці" />
</kendo-grid>

<ng-template NitAddRating />

<nit-deleting-replacement-modal #deletingReplacement
                                (updateTableData)="refresh()" />
