import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, inject} from '@angular/core';
import {AchievementMarkType, KendoFilterOperator} from '@nit-core/global/domain/enums';
import {Achievement, GeneralAchievement} from '@nit-core/models/achievement';
import {AchievementMark, GrouppedMarks} from '@nit-core/models/nush-mark';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {NushDecision, NushDecisionLearningOutcome, NushDecisionSubject} from '@nit-core/models/nush-decision';
import {HTTPOptions, RestService} from '@nit-core/services/global/http-services/rest.service';
import {GeneralAchievementMarkService} from '@nit-services';
import {BehaviorSubject} from 'rxjs';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {NusPopoverEvent} from '../../../../models/nush.models';
import {TableComponent} from '../../../../components/table/table.component';
import {MarkComponent} from '../../../../components/mark/mark.component';
import {LoaderContentComponent} from '../../../../../../../../components/loader-content/loader-content.component';
import {NusPopoverService} from '@nit-core/services/utils/nus-popover.service';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'nit-characteristics-table',
  templateUrl: './characteristics-table.component.html',
  styleUrl: './characteristics-table.component.scss',
  standalone: true,
  imports: [CommonModule, TableComponent, MarkComponent, LoaderContentComponent, DynamicComponentDirective]
})
export class CharacteristicsTableComponent implements OnInit, OnChanges {
  @ViewChild(DynamicComponentDirective, {static: true}) dynamicComponentDirective: DynamicComponentDirective;

  @Input() isGeneralCharacteristics: boolean = false;
  @Input() achievements: GeneralAchievement[] | Achievement[];
  @Input() isTeacherView: boolean = false;
  @Input() areFifthAndHigher: boolean = false;
  @Input() childId: string;
  @Input() schoolId: number;
  @Input() subject: NushDecisionSubject;
  @Input() rows: NushDecisionLearningOutcome[];
  @Input() footerRows: NushDecisionLearningOutcome[];
  @Input() markService: RestService<any>;
  @Input() decision: NushDecision;

  achievementMarkType = AchievementMarkType;
  marks?: GrouppedMarks<AchievementMark>;
  decisionResult: string = '';
  decisionExists: boolean = false;
  marksLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isFirstMarksLoading: boolean = true;
  public nusPopoverService = inject(NusPopoverService);

  ngOnChanges(changes: SimpleChanges): void {
    if (this.subject) {
      if (changes.subject && !changes.subject.firstChange) {
        this.getMarks();
      }
    }
    if (changes.achievements && !changes.achievements.firstChange) {
      this.getMarks();
    }
    if (changes?.decision?.currentValue) {
      this._formatDecision();
    }
  }

  ngOnInit(): void {
    this.getMarks();
  }

  openPopover(event: NusPopoverEvent): void {
    this.nusPopoverService.openPopover(event, this.dynamicComponentDirective, null, null, false);
  }

  getMarks(): void {
    this.nusPopoverService.clearPopoverSubscriptions();

    if (this.isGeneralCharacteristics) {
      this.marksLoaded$.next(false);
      const achievementsId = this.achievements.map(x => x.id).join(',');
      const query = new KendoDataQuery(0, 999);
      const options: HTTPOptions = {};
      query.pushFilters({field: 'achievementId', operator: KendoFilterOperator.In, value: achievementsId});

      if (this.childId) {
        if (this.markService instanceof GeneralAchievementMarkService) {
          query.pushFilters({field: 'userId', operator: KendoFilterOperator.Eq, value: this.childId});
        }

        options.asUserId = this.childId;
        options.asUserSchoolId = this.schoolId;
      }
      options.query = query.query;

      this.markService.all(options).subscribe(res => {
        res.data.forEach(mark => mark.isVerbalFormed = true);
        this.marks = new GrouppedMarks<AchievementMark>(res.data, 'achievementId', 'learningOutcomeId');

        this.marksLoaded$.next(true);
        this.isFirstMarksLoading = false;
      });
    }
  }

  private _formatDecision(): void {
    let decisionResult = '';
    const decisions = {
      commendationLetter: 'Нагородження Похвальним листом,',
      certificateHonors: 'Видання свідоцтва з відзнакою,',
      nextClass: 'Переведення до наступного класу,',
      repeatCourse: 'Залишення для повторного здобуття загальної середньої освіти,',
      finishedSchool: 'Випущення з навчального закладу,',
      droppedSchool: 'Вибуття з навчального закладу,'
    };

    Object.keys(decisions).forEach(key => {
      if (this.decision[key]) {
        this.decisionExists = true;
        decisionResult += decisions[key];
      }
    });
    if (decisionResult.endsWith(',')) {
      decisionResult = decisionResult.replace(/.$/, '.');
    }
    this.decisionResult = decisionResult.split(',').join(', ');
  }
}
