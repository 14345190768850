import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const briefingForStudentsRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./briefing-for-students-main/briefing-for-students-main.component').then(m => m.BriefingForStudentsMainComponent),
    canActivate: [PermissionGuard]
  },
  {
    path: 'brief/:teacherId/:journalId/:classId',
    loadComponent: () => import('./briefing-all-info/briefing-all-info.component').then(m => m.BriefingAllInfoComponent),
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Зміст проведеної роботи',
    }
  }
];
