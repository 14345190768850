import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Warning} from '@nit-core/models/warning';
import {CommonModule} from '@angular/common';
import {TechAlertMessageComponent} from '@nit-core/components/tech-alert-message/tech-alert-message.component';

@Component({
  selector: 'nit-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrl: './wrapper.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TechAlertMessageComponent]
})

export class WrapperComponent {
  @Input() heading: string = '';
  @Input() isReverse: boolean = false;
  @Input() warning: Warning;
}
