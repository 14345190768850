import {Component} from '@angular/core';
import {UserService} from '@nit-services';
import {RouterLink} from '@angular/router';

@Component({
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
  standalone: true,
  imports: [RouterLink]
})

export class NotFoundComponent {
  isParent: boolean = false;
  isSchoolActive: boolean;

  constructor(private readonly _userService: UserService) {
    this._userService.currentUser$.subscribe(res => {
      if (res) {
        this.isParent = !res.isChild;
      }
    });
  }
}
