<nit-modal #modal
           titleSize="small"
           [width]="422"
           [title]="title"
           [buttonWidth]="342"
           (closed)="closed.emit()"
           (click)="$event.stopPropagation()"
           (submited)="onSubmit()">
  <form [formGroup]="form">
    <div class="note-wrapper">
      <div class="value-length">
        <span>
          {{form.get('wish')?.value ? form.get('wish').value.length : '0'}}/250
        </span>
      </div>
      <nit-field-wrapper [control]="form.get('wish')"
                         [label]="isParent ? 'Ваше побажання': 'Ваша рекомендація'"
                         [required]="true">
        <textarea nitTrim
                  rows="5"
                  formControlName="wish"
                  [maxlength]="250">
        </textarea>
      </nit-field-wrapper>
    </div>
  </form>
</nit-modal>
