<nit-wrapper *ngIf="!isAutoRedirect" [heading]="title">
  <form *ngIf="!isAutoRedirect" [formGroup]="form">
    <!-- <nit-field-wrapper *ngIf="form.get('userCode')" [control]="form.controls['userCode']" [required]="true"
                       [label]="'ID'"
                       infoLabel="ID — це унікальний ідентифікатор користувача, який після створення Вашого акаунту відображається у Вашому профілі Адміністратору або, якщо ти Учень — на сторінці “Діти” в акаунті батьків.">
      <input formControlName="userCode" type="text" placeholder="AA000000"/>
    </nit-field-wrapper> -->

    <nit-field-wrapper *ngIf="form.get('password')" icon="password" [isHighError]="true" [control]="form.controls['password']"
                       [required]="true" [label]="'Пароль'" [showHidePassword]="true">
      <input formControlName="password" type="password" placeholder="********"/>
    </nit-field-wrapper>

    <nit-field-wrapper *ngIf="form.get('confirmPassword')" icon="password"
                       [control]="form.controls['confirmPassword']" [required]="true" [label]="'Підтвердження паролю'" [showHidePassword]="true">
      <input formControlName="confirmPassword" type="password" placeholder="********"/>
    </nit-field-wrapper>

    <ng-container *ngIf="form.get('aggrement') || +form.get('confirmationType').value === confirmationType.ChangeNotVerifiedEmail">
      <div class="row privacy-policy-checkbox">
        <div class="col-12">
          <nit-field-wrapper [required]="true" [control]="form.controls['aggrement']">
            <label class="checkbox"><input type="checkbox" formControlName="aggrement">
              <div>
                Я ознайомився (лася) та <br>погоджуюся з
                <a class="link" routerLink="/privacy-policy">Політикою конфіденційності </a> та <a class="link" routerLink="/terms-of-use">Умовами використання</a>
              </div>
            </label>
          </nit-field-wrapper>
        </div>
      </div>
    </ng-container>

    <nit-button [isDisabled]="form.invalid"
                (clicked)="submit()">
      Підтвердити
    </nit-button>
  </form>
</nit-wrapper>
