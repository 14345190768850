import {Routes} from '@angular/router';
import {certificateAchievementsRouting} from './pages/certificate-achievement/certificate-achievement-routing';

export const nushRootRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/menu/menu.component').then(m => m.MenuComponent),
  },
  {
    path: 'certificate-of-achievement',
    children: certificateAchievementsRouting,
    data: {
      breadcrumb: 'Свідоцтво досягнень'
    },
  },
  {
    path: 'diagnostic-works',
    loadComponent: () => import('./pages/diagnostic-works/diagnostic-works.component').then(m => m.DiagnosticWorksComponent),
    data: {
      breadcrumb: 'Діагностувальні роботи'
    },
  },
  {
    path: 'students-observations',
    loadComponent: () => import('./pages/students-observations/students-observations.component').then(m => m.StudentsObservationsComponent),
    data: {
      breadcrumb: 'Спостереження за учнем'
    },
  }
];
