import {Routes} from '@angular/router';
import {classInfoRouting} from './pages/class-info/class-info-routing';

export const classesRootRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/class-list/class-list.component').then(m => m.ClassListComponent),
  },
  {
    path: ':id',
    children: classInfoRouting,
    data: {
      breadcrumb: 'Профайл класу'
    },
  }
];
