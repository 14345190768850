import {Routes} from '@angular/router';
import {calendarOfEventsRouting} from '../../../school/pages/calendar-of-events/calendar-of-events';

export const childSchoolProfileRouting: Routes = [{
  path: '',
  children: [
    {
      path: '',
      loadComponent: () => import('../../../school/pages/school-profile/school-profile.component').then(m => m.SchoolProfileComponent),
    },
    {
      path: 'calendar-of-events',
      data: {
        breadcrumb: 'Події'
      },
      children: calendarOfEventsRouting
    },
    // {
    //   path: 'school-calendar',
    //   canActivate: [ParentPermissionGuard],
    //   data: {
    //     breadcrumb: 'Шкільний календар',
    //     permissions: ['read:day-off'],
    //   },
    //   loadComponent: () => import('../../../school/pages/school-calendar/school-calendar.component').then(m => m.SchoolCalendarComponent)
    // },
  ]
}];
