import {Routes} from '@angular/router';

export const comparisonReportRouting: Routes =[
  {
    path: '',
    loadComponent: () => import('../comparison-report/pages/report.component').then(m => m.ReportComponent)
  },
  {
    path: 'file-comparison',
    loadComponent: () => import('./pages/file-comparison/file-comparison.component').then(m => m.FileComparisonComponent),
    data: {
      breadcrumb: 'Сторінка порівняння файлів'
    }
  },
  {
    path: 'statistics',
    loadComponent: () => import('./pages/statistics/statistics.component').then(m => m.StatisticsComponent),
    data: {
      breadcrumb: 'Статистика'
    }
  }
];
