import {Routes} from '@angular/router';
import {ReplacementTableComponent} from './replacement-table.component';

export const replacementTableRouting: Routes = [
  {
    path: '',
    component: ReplacementTableComponent,
  },
  {
    path: 'edit-teacher-replacement',
    loadComponent: () =>
      import('../../pages/class-teacher-office/pages/teacher-replacement/pages/set-edit-teacher-replacement-settings/set-edit-teacher-replacement-settings.component')
        .then(m => m.SetEditTeacherReplacementSettingsComponent),
    data: {
      breadcrumb: 'Редагування заміни'
    }
  }
];
