import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';
import {schoolProfileSettingsRouting} from './pages/school-profile-settings/school-profile-settings-routing';
import {nushRulesRouting} from './pages/nush-rules/nush-rules-routing';

export const schoolRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/school-profile/school-profile.component').then(m => m.SchoolProfileComponent)
  },
  {
    path: 'edit',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['update:school'],
      breadcrumb: 'Редагування профілю школи'
    },
    loadComponent: () => import('./pages/school-profile-edit/school-profile-edit.component').then(m => m.SchoolProfileEditComponent)
  },
  {
    path: 'settings',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['update:setting'],
      breadcrumb: 'Налаштування'
    },
    children: schoolProfileSettingsRouting
  },
  {
    path: 'school-calendar',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Шкільний календар',
      permissions: ['read:day-off'],
    },
    loadComponent: () => import('./pages/school-calendar/school-calendar.component').then(m => m.SchoolCalendarComponent)
  },
  // {
  //   path: 'calendar-of-events',
  //   canActivate: [PermissionGuard],
  //   data: {
  //     permissions: ['read:event'],
  //     breadcrumb: 'Події'
  //   },
  //   loadComponent: () => import('./pages/calendar-of-events/main-page-of-the-calendar/main-page-of-the-calendar.component').then(m => m.MainPageOfTheCalendarModule)
  // },
  {
    path: 'nush',
    canActivate: [PermissionGuard],
    data: {
      breadcrumb: 'Налаштування НУШ'
    },
    children: nushRulesRouting
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
];
