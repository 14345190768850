<!-- *ngIf="!isFirstMarksLoading"> -->
<ng-container *ngIf="true">
  <nit-no-data [hasData]="achievement.columns?.length"
               [text]="'Обраний предмет не додано в розклад'">
    <nit-table>
      <tr>
        <th>
          <span>Характеристика</span>
        </th>
        <th *ngFor="let column of achievement.columns">
          <span>{{column.name}}</span>
        </th>
        <th *ngIf="isFifthOrHigher"></th>
        <th *ngIf="isFifthOrHigher && achievement.columns?.length === 1"></th>
      </tr>

      <ng-container *ngFor="let outcome of subject.learningOutcomes">
        <tr *ngIf="!outcome.isImmutable">
          <td>
            <span>{{outcome.name}}</span>
          </td>

          <td *ngFor="let col of achievement.columns" class="column">
            <nit-mark [rate]="col.groupedMarks[outcome?.id]?.[0] || {}"
                      [readOnly]="true"
                      [grading]="grading" />
          </td>
          <td *ngIf="isFifthOrHigher"></td>
          <td *ngIf="isFifthOrHigher && achievement.columns?.length === 1"></td>
        </tr>
      </ng-container>
    </nit-table>

    <nit-table *ngIf="isFifthOrHigher" [isFooter]="true">
      <tr>
        <th class="footer-th"></th>
        <th><span>І семестр</span></th>
        <th><span>ІІ семестр</span></th>
        <th><span>Річна</span></th>
      </tr>
      <tr>
        <td><span>Загальна оцінка результатів навчання</span></td>
        <td>
          <span>
            <nit-mark [rate]="firstSemesterMark || {}"
                      [readOnly]="true"
                      [grading]="grading" />
          </span>
        </td>
        <td>
          <span>
            <nit-mark [rate]="secondSemesterMark || {}"
                      [readOnly]="true"
                      [grading]="grading" />
          </span>
        </td>
        <td>
          <span>
            <nit-mark [rate]="yearMark || {}"
                      [readOnly]="true"
                      [grading]="grading" />
          </span>
        </td>
      </tr>
    </nit-table>

    <div *ngIf="decisionExists && childId" class="decision-wrapper">
      <p class="decision-title">Рішення педагогічної ради про:</p>
      <div class="decision-text">
        <span>{{decisionResult}}</span>
      </div>
    </div>
  </nit-no-data>
</ng-container>

<ng-template NitAddRating />
