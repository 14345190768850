import {Routes} from '@angular/router';

export const questionnaireSettingsRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./questionnaire-settings.component').then(m => m.QuestionnaireSettingsComponent)
  },
  {
    path: 'create-questionnaire',
    loadComponent: () => import('./create-edit-questionnaire/create-edit-questionnaire.component').then(m => m.CreateEditQuestionnaireComponent),
    data: {
      breadcrumb: 'Створити опитування',
      permissions: ['create:questionnaire']
    }
  },
  {
    path: 'update-questionnaire/:id',
    loadComponent: () => import('./create-edit-questionnaire/create-edit-questionnaire.component').then(m => m.CreateEditQuestionnaireComponent),
    data: {
      breadcrumb: 'Редагувати опитування',
      permissions: ['update:questionnaire']
    }
  }
];
