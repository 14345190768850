<div class="congratulations">
  <div class="congratulations-wrapper">
    <div class="congratulations-icon">
      <img src="/assets/images/auth/congradulation-check.svg" alt="">
    </div>
    <div class="congratulations-title">
      Перший етап реєстрації<br>у платформі пройшов успішно.
    </div>
    <div class="congratulations-subtitle">
      Вам на <span>{{email}}</span> було надіслано листа для підтвердження реєстрації. 
      Будь ласка, перевірте свою електронну пошту та перейдіть за посиланням у листі.
    </div>
    <div class="congratulations-resend">
      Якщо Ви не отримали лист-підтвердження, перевірте, будь ласка,<br>
      папку "Спам" або напишіть на <span>info&#64;nit.school</span>
    </div>
    <a routerLink="/" class="congratulations-link">На головну</a>
  </div>
</div>
