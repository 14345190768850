import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'nit-link-item',
  template: `
    <div><span *ngIf="!!text">{{text}}</span><a [routerLink]="link">{{linkText}}</a></div>
  `,
  styleUrl: './link-item.component.scss',
  standalone: true,
  imports: [CommonModule, RouterLink]
})

export class LinkItemComponent {
  @Input() text: string = '';
  @Input() link: string = '';
  @Input() linkText: string = '';
}
