import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'nit-policy-container',
  templateUrl: './policy-container.component.html',
  styleUrl: './policy-container.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class PolicyContainerComponent { }
