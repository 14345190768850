<div class="private-wrapper" [ngClass]="{reverse: isReverse}">
  <div class="private-wrapper__slider">
    <div class="slider-wrapper">
      <div class="slider-item">
        <img src="./assets/images/auth/class-profile-screen.png" alt="class image">
      </div>
    </div>
  </div>
  <div class="private-wrapper__form">
    <nit-tech-alert-message *ngIf="warning?.isActive" [warning]="warning">{{warning?.text}}</nit-tech-alert-message>
    <div class="form-title">{{heading}}</div>
    <div class="form-wrapper">
      <ng-content />
    </div>
  </div>
</div>
