import {Pipe, PipeTransform} from '@angular/core';
import {ReplacePaymentTypes, ReplaceReasonTypes} from '@nit-core/global/domain/enums';

@Pipe({
  standalone: true,
  name: 'getTextFromEnum'
})
export class GetTextFromEnumPipe implements PipeTransform {
  ReplaceReasonTypes = ReplaceReasonTypes;
  ReplacePaymentTypes = ReplacePaymentTypes;

  transform(item: number, arrayName: string): string {
    const key = arrayName === 'ReplaceReasonTypes' ? 'replacementReasonType' : 'paymentType';
    const foundItem = this[arrayName].find(el => el[key] === item);

    return foundItem ? foundItem?.text : '';
  }
}
