import {Routes} from '@angular/router';
import {RelationGuard} from '@nit-core/guards';
import {PermissionGuard} from '@nit-core/permission/permission.guard';
import {NushViewModes} from '@nit-core/global/domain/enums';
import {certificateAchievementsRouting} from '../basic-data/pages/nush/pages/certificate-achievement/certificate-achievement-routing';
import {profileRouting} from '../profile/profile-routing';
import {chatRouting} from '../chat/chat-routing';
import {childClassProfileRouting} from './pages/child-class-profile/child-class-profile-routing';
import {childSchoolProfileRouting} from './pages/child-school-profile/child-school-profile-routing';

export const childrenRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('../children/pages/children/children.component').then(m => m.ChildrenComponent),
  },
  {
    path: 'create',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:relation'],
      isEdit: false,
      breadcrumb: 'Створити профіль дитини'
    },
    loadComponent: () => import('../children/pages/child/child.component').then(m => m.ChildComponent),
  },
  {
    path: ':userId/edit',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['update:relation'],
      isEdit: true,
      breadcrumb: 'Редагувати профіль дитини'
    },
    loadComponent: () => import('../children/pages/child/child.component').then(m => m.ChildComponent),
  },
  {
    path: ':userId/dashboard',
    canActivate: [RelationGuard],
    data: {
      breadcrumb: 'Дашборд'
    },
    loadComponent: () => import('../dashboard/dashboard.component').then(m => m.DashboardComponent),
  },
  {
    path: ':userId/diary',
    canActivate: [RelationGuard],
    data: {
      breadcrumb: 'Щоденник'
    },
    loadComponent: () => import('../diary/pages/diary/diary.component').then(m => m.DiaryComponent)
  },
  {
    path: ':userId/school/:id',
    data: {
      breadcrumb: 'Школа'
    },
    children: childSchoolProfileRouting
  },
  {
    path: ':userId/class/:id',
    canActivate: [RelationGuard],
    data: {
      hasAccess: true,
      isClassOfChild: true,
      breadcrumb: 'Профайл класу'
    },
    children: childClassProfileRouting
  },
  {
    path: ':userId/student-journal',
    canActivate: [RelationGuard],
    data: {
      breadcrumb: 'Журнал оцінок'
    },
    loadComponent: () => import('../student-journal/student-journal.component').then(m => m.StudentJournalComponent),
  },
  {
    path: ':userId/timesheet-student',
    canActivate: [RelationGuard],
    data: {
      breadcrumb: 'Табель'
    },
    loadComponent: () => import('../children/pages/timesheet-student/timesheet-student.component').then(m => m.TimesheetStudentComponent)
  },
  {
    path: ':userId/chat',
    canActivate: [
      RelationGuard
    ],
    children: chatRouting
  },
  {
    path: ':userId/chat:classId/:roomId/:school',
    loadComponent: () => import('../chat/pages/dialog/dialog.component').then(m => m.DialogComponent)
  },
  {
    path: 'create',
    loadComponent: () => import('../chat/pages/create-chat/create-chat.component').then(m => m.CreateChatComponent)
  },
  {
    path: 'profile',
    children: profileRouting,
    data: {
      breadcrumb: 'Профіль',
      ignoreProfileRole: true,
      viewMode: NushViewModes.Parent,
    },
  },
  {
    path: ':classId/saves',
    loadComponent: () => import('../chat/pages/dialog/dialog.component').then(m => m.DialogComponent)
  },
  {
    path: ':classId/:roomId/details',
    loadComponent: () => import('../chat/pages/chat-details/chat-details.component').then(m => m.ChatDetailsComponent)
  },
  {
    path: ':id/certificate-of-achievements',
    children: certificateAchievementsRouting,
    data: {
      breadcrumb: 'Свідоцтво досягнень',
      viewMode: NushViewModes.Parent,
    },
  },
  {
    path: ':id/diagnostic-works',
    loadComponent: () => import('../basic-data/pages/nush/pages/diagnostic-works/diagnostic-works.component').then(m => m.DiagnosticWorksComponent),
    data: {
      breadcrumb: 'Діагностувальні роботи',
      viewMode: NushViewModes.Parent,
    },
  },
  {
    path: ':id/students-observations',
    loadComponent: () => import('../basic-data/pages/nush/pages/students-observations/students-observations.component').then(m => m.StudentsObservationsComponent),
    data: {
      breadcrumb: 'Спостереження за учнем',
      viewMode: NushViewModes.Parent,
    },
  }
];
