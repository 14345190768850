<nit-wrapper heading="Змінити пароль">
  <form [formGroup]="form">
    <nit-field-wrapper icon="mail" [label]="'Email'" [required]="true" [control]="form.controls['email']">
      <input class="login-input" formControlName="email" type="email" placeholder="youremail@gmail.com" />
    </nit-field-wrapper>
    <nit-button (clicked)="submit()">Підтвердити</nit-button>
    <div class="text-container">
      <span>Згадали пароль?</span>
      <a [routerLink]="['/login']">Авторизуватися</a>
    </div>
  </form>
</nit-wrapper>
