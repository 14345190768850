import {Routes} from '@angular/router';
import {UnsavedUpdatesGuard} from '@nit-core/guards/unsaved-updates.guard';
import {PermissionGuard} from '@nit-core/permission/permission.guard';

export const studentGeneralInformationRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./student-general-information/student-general-information.component').then(m => m.StudentGeneralInformationComponent)
  },
  {
    path: 'profile',
    loadComponent: () => import('./student-edit/student-edit.component').then(m => m.StudentEditComponent),
    canActivate: [PermissionGuard],
    canDeactivate: [UnsavedUpdatesGuard],
    data: {
      breadcrumb: 'Редагування даних учня',
      permissions: ['update:school-user', 'get:class']
    },
  }
];
