import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {environment} from '../environments/environment';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {interval} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {UpdateVersionModalComponent} from '@nit-core/components/update-version-modal/update-version-modal.component';
import {Router, RouterOutlet} from '@angular/router';

@Component({
  selector: 'nit-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, UpdateVersionModalComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  @ViewChild('updateVersionModal') updateVersionModal: UpdateVersionModalComponent;
  environment= environment;
  constructor(private readonly _swUpdate: SwUpdate,
              private readonly router: Router) {
    interval(3600000).subscribe(() => {
      this._swUpdate.checkForUpdate();
    });
    const updatesAvailable= _swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));
    updatesAvailable.subscribe(()=> {
      this._swUpdate.activateUpdate().then(()=> {
        setTimeout(() => this.updateVersionModal?.open(),0);
      });
    });
  }
}
