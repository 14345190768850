import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {AuthService} from '@nit-core/auth';

@Injectable()
export class RelationGuard {
  constructor(private readonly router: Router, private readonly _auth: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const id = route.paramMap.get('userId');
    if (this._auth.getUserSchoolId(id) !== null) {
      return true;
    } else {
      return this.router.createUrlTree(['/404']);
    }
  }
}
