import {Routes} from '@angular/router';
import {PermissionGuard} from '@nit-core/permission/permission.guard';
import {UnsavedUpdatesGuard} from '@nit-core/guards/unsaved-updates.guard';

export const documentsRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('../documents/documents.component').then(m => m.DocumentsComponent)
  },
  {
    path: 'sign-document/:id',
    canActivate: [PermissionGuard],
    canDeactivate: [UnsavedUpdatesGuard],
    data: {
      permissions: ['create:sign'],
      breadcrumb: 'Підпис документа'
    },
    loadComponent: () => import('./signing-document/signing-document.component').then(m => m.SigningDocumentComponent),
  },
  {
    path: 'sign-document-check',
    canActivate: [PermissionGuard],
    data: {
      permissions: ['create:sign'],
      breadcrumb: 'Перевірка підпису'
    },
    loadComponent: () => import('./document-sign-check/document-sign-check.component').then(m => m.DocumentSignCheckComponent),
  },
];
