<nit-policy-container>
  <h2 style="text-align: center;">УМОВИ ВИКОРИСТАННЯ</h2>
  <p><span>Документ, положення якого викладені нижче по тексту, є офертою та договором приєднання. Згідно із положеннями
      ст. 634 Цивільного кодексу України, договір приєднання може бути укладений лише шляхом приєднання другої
      сторони до запропонованого договору в цілому. Друга сторона не може запропонувати свої умови договору.</span></p>
  <p><span>Дана оферта адресована всім фізичним особам з повною цивільною дієздатністю, що
      бажають та мають технічну можливість отримати доступ до електронного засобу навчального призначення
      &ldquo;Інформаційно-комунікаційна система &ldquo;НІТ (Навчання і Технології)&rdquo;.&nbsp;&nbsp;</span></p>
  <p><span>У випадку прийняття умов даної оферти, Ви погоджуєтеся з усіма умовами цього Договору і підтверджуєте,
      що Вам зрозумілі всі його положення. </span></p>
  <p><span>ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ЛАЄНВУД СОФТВЕР УКРАЇНА" (далі – Виконавець) в особі директора
      Каміновського Володимира Андрійовича, який діє на підставі Статуту, Ідентифікаційний код юридичної особи 43009185,
      керуючись чинним законодавством України публікує відповідно до статті 634 Цивільного кодексу України  цю оферту
      та пропонує фізичній особі з повною цивільною дієздатністю (далі – Замовник), надалі разом – Сторони,
      а кожен окремо – Сторона, приєднатися до цього Договору про надання доступу до програмної продукції (далі – Договір)
      на наступних умовах:</span></p>
  <br />
  <ol>
    <li><h4>ВИЗНАЧЕННЯ ТЕРМІНІВ</h4></li>
  </ol>
  <p><strong>1.1.&nbsp; Оферта -</strong><span> дійсна пропозиція Виконавця, адресована будь-якій фізичній особі з повною
      цивільною дієздатністю відповідно до статті 641 Цивільного кодексу України, укласти з Виконавцем Договір про
      надання доступу до Програмної продукції, що є договором приєднання.</span></p>
  <p><strong>1.2. Акцепт</strong><span> - повне та безумовне прийняття оферти в цілому шляхом проставлення відповідної
      відмітки (Я ознайомився (лася) та погоджуюся з Політикою конфіденційності та Умовами використання).</span></p>
  <p><strong>1.3. Договір</strong><span> - Договір про надання доступу до Програмної продукції, що укладений шляхом
      публікації Виконавцем оферти та здійснення повного та безумовного прийняття оферти Замовником у спосіб,
      що визначений в оферті.</span></p>
  <p><strong>1.4. Виконавець (Товариство)</strong><span> - Товариство з обмеженою
      відповідальністю &ldquo;ЛАЄНВУД СОФТВЕР УКРАЇНА&rdquo; (код ЄДРПОУ 43009185), адреса реєстрації 79026, Львівська
      обл., місто Львів, вул. Курильська, будинок 3, квартира 3.</span></p>
  <p><strong>1.5. Замовник </strong><span>- будь-яка фізична особа з повною цивільною дієздатністю, яка має намір
      та можливість користуватися доступом до Програмної продукції та використовувати Програмну продукцію,
      а також яка здійснила акцепт оферти. Замовниками вважаються будь-які користувачі та будь-які користувачі школи.</span></p>
  <p><strong>1.6. Програмна продукція</strong><span> - Електронний засіб навчального
      призначення &ldquo;Інформаційно-комунікаційна система &ldquo;НІТ (Навчання і Технології)&rdquo;. Програмна
      продукція є результатом комп&rsquo;ютерного програмування у вигляді системних, прикладних програм, їх компонентів,
      а також у вигляді онлайн-сервісів та доступу до них тощо.&nbsp;</span></p>
  <p><span>Функціонал Програмної продукції:</span></p>
  <p><span>- доступ до електронних навчальних матеріалів;</span></p>
  <p><span>- управління навчальними планами;</span></p>
  <p><span>- формування розкладу уроків для вчителів та учнів;</span></p>
  <p><span>- цифровий щоденник учня;</span></p>
  <p><span>- цифровий журнал. Оцінювання знань та&nbsp; зворотний зв&rsquo;язок використовуючи
      цифровий журнал класу;</span></p>
  <p><span>- текстова, аудіо та відео комунікація.</span></p>
  <p><strong>1.7. Користувач </strong><span>- Замовник, що здійснив акцепт оферти та зареєструвався в
    Програмній продукції.</span></p>
  <p><strong>1.8. Користувач школи</strong><span> - Замовник, що здійснив акцепт оферти та зареєструвався в
      Програмній продукції. При цьому, такий Замовник є учасником освітнього процесу у школі, що використовує
      Програмну продукцію відповідно до договору з Товариством, та є приєднаний до цієї школи у Програмній продукції. </span></p>
  <p><strong>1.9. Категорія користувача -</strong><span> це певний статус користувача, на підставі якого визначається
      рівень доступу користувача до Програмної продукції та обсяг прав такого користувача у Програмній продукції.
      У Програмній продукції існують наступні категорії користувачів: користувачі школи та інші користувачі
      (крім користувачів школи).</span></p>
  <p><strong>1.10. Категорія користувача школи </strong><span>- це певний статус користувача школи, на підставі
      якого визначається рівень доступу користувача школи до Програмної продукції та обсяг прав такого користувача
      школи у Програмній продукції. У Програмній продукції існують наступні категорії користувачів школи: директор школи,
      адміністратор школи, вчитель школи, учень школи, батьки (батько/мати) учня школи.</span></p>
  <p><strong>1.11. Учасники освітнього процесу </strong><span>- учні; педагогічні працівники; інші працівники закладу освіти;
      батьки учнів; асистенти дітей (у разі їх допуску відповідно до вимог чинного законодавства України).</span></p>
  <p><strong>1.12. Батьки (батько/мати)</strong><span> - користувачі, що є батьками чи іншими законними представниками
      учнів школи (осіб, що не досягли 18 років/дітей).</span></p>
  <p><strong>1.13. Школа </strong><span>- заклад загальної середньої освіти, який використовує Програмну продукцію
      відповідно до договору з Товариством.</span></p>
  <p><strong>1.14. Директор школи</strong><span> - посадова особа школи, директор. У Програмній продукції директор школи
      є адміністратором найвищого рівня та має можливість створювати адміністраторів школи, надавати користувачам із
      роллю вчитель школи додаткову, другу, роль адміністратора школи, створювати та видаляти класи, редагувати
      інформацію про школу в Програмній продукції.</span></p>
  <p><strong>1.15. Адміністратор школи </strong><span>- &nbsp; уповноважена школою особа, що відповідальна за взаємодію з
      розпорядником персональних даних користувачів школи з приводу надання доступу до Програмної продукції,
      створення облікових записів, а також підтвердження облікових записів, створених особисто користувачами школи. </span></p>
  <p><strong>1.16. Реєстрація</strong><span> - створення Замовником облікового запису у Програмній продукції шляхом
      здійснення акцепту оферти та заповнення відповідних полів у Програмній продукції.</span></p>
  <p><strong>1.17. Обліковий запис</strong><span> - профіль у Програмній продукції, створений після реєстрації Замовника
      у Програмній продукції, в якому міститься інформація про Замовника та за допомогою якого Замовнику забезпечується
      певний рівень доступу до Програмної продукції, а також певний обсяг прав у Програмній продукції. Замовник має
      доступ до свого облікового запису після авторизації у Програмній продукції.</span></p>
  <p><strong>1.18. Авторизація </strong><span>- здійснення доступу до свого облікового запису конкретним Замовником у
      Програмній продукції за допомогою логіна та паролю.</span></p>
  <p><strong>1.19. Логін</strong><span> - адреса електронної пошти конкретного Замовника, яка використовується для
      авторизації у Програмній продукції.</span></p>
  <p><strong>1.20. Пароль</strong><span> - набір символів у певній послідовності, відомий виключно конкретному
      Замовнику та який використовується ним для авторизації у Програмній продукції.</span></p>
  <p><strong>1.21 Послуги технічної підтримки</strong><span> - послуги, що надаються
      Виконавцем з приводу консультування Замовників щодо отримання та здійснення доступу до&nbsp; Програмної продукції
      та використання Програмної продукції. Послуги технічної підтримки надаються впродовж робочого часу з 10:00 до
      18:00 за київським часом впродовж робочого тижня з понеділка по п&rsquo;ятницю за виключенням офіційних державних
      вихідних в Україні, що визначені на вебсайті (</span><a href="https://zakon.rada.gov.ua/laws/main/days2020"><span
       >https://zakon.rada.gov.ua/laws/main/days</span></a><span>).
      Послуги з технічної підтримки надаються виключно у відповідь на звернення від Замовника, яке направлене на
      електронну пошту support&#64;nit.school та в якому описано конкретне питання щодо якого Замовнику необхідна технічна
      підтримка. З метою швидкого розв'язання проблеми, що виникла в Замовника, Виконавець рекомендує додавати до
      повідомлення відеозапис екрану або знімок екрану з проблемою, щодо якої Замовник звертається до Виконавця.</span>
  </p>
  <p><strong>1.22. Форс-мажорні обставини</strong><span> - надзвичайні та невідворотні обставини, що об’єктивно
      унеможливлюють виконання зобов’язань, передбачених умовами Договору, а саме:</span></p>
  <p><span>загроза війни, збройний конфлікт або серйозна погроза такого конфлікту, включаючи, але не обмежуючись ворожими
      атаками, блокадами, військовим ембарго, дії іноземного ворога, загальна військова мобілізація, військові дії,
      оголошена та неоголошена війна, дії суспільного ворога, збурення, акти тероризму, диверсії, піратство, безлади,
      вторгнення, блокада, революція, заколот, повстання, масові заворушення, введення комендантської години,
      експропріація, примусове вилучення, захоплення підприємств, реквізиція, блокада, страйк, протиправні дії третіх осіб,
      пожежа, вибух, акти державних органів влади, ембарго, заборона (обмеження) експорту, епідемії, епізоотії,
      а також обставини, викликані винятковими погодними умовами і стихійним лихом, а саме: сильний шторм, циклон,
      ураган, торнадо, буревій, повінь, нагромадження снігу, землетрус, блискавка, пожежа, посуха, просідання і
      зсув ґрунту, інші стихійні лиха.</span></p>
  <p><strong>1.23. Використання Програмної продукції за функціональним призначенням</strong><span
     > - використання функціоналу Програмної продукції для реалізації прав користувача Програмної продукції відповідної категорії.
      Зокрема, Програмна продукція передбачає, для користувачів школи відповідних категорій, можливість доступу до
      електронних навчальних матеріалів; управління навчальними планами; формування розкладу уроків для вчителів та учнів;
      цифровий щоденник учня; цифровий журнал (включає оцінювання знань та  зворотний зв’язок використовуючи цифровий журнал);
      текстова, аудіо та відео комунікація.</span></p>
  <p><strong>1.24. Політика конфіденційності </strong><span>- документ, що визначає порядок
      обробки Товариством персональних даних користувачів Програмної продукції та міститься за <a routerLink="/privacy-policy">посиланням</a>.
      Політика конфіденційності є невід&rsquo;ємною складовою цього Договору.</span></p>
  <p><strong>1.25. Персональні дані </strong><span>- відомості чи сукупність відомостей про фізичну особу,
      яка ідентифікована або може бути конкретно ідентифікована. </span></p>
  <p><strong>1.26. Згода суб'єкта персональних даних </strong><span>- добровільне волевиявлення фізичної особи,
      яка надає дозвіл на обробку її персональних даних відповідно до мети обробки.</span></p>
  <p><strong>1.27. Обробка персональних даних </strong><span>- будь-яка дія або сукупність дій, таких як збирання,
      реєстрація, накопичення, зберігання, адаптування, зміна, поновлення, використання і поширення (розповсюдження,
      реалізація, передача), знеособлення, знищення персональних даних, у тому числі з використанням інформаційних
      (автоматизованих) систем.</span></p>
  <p><strong>1.28. Інші терміни та поняття,</strong><span> що вживаються у цьому Договорі
      мають значення, що встановлене в Політиці конфіденційності або у чинному законодавстві України.</span></p>
  <p>&nbsp;</p>
  <ol start="2">
    <li><h4>ПОРЯДОК УКЛАДАННЯ ДОГОВОРУ</h4></li>
  </ol>
  <p><span>2.1. Цей Договір є договором приєднання відповідно до ст. 634 Цивільного кодексу
      України.&nbsp;</span></p>
  <p><span>2.2. Публікація (розміщення) тексту Договору на сайті Виконавця є офіційною пропозицією (Публічною Офертою)
      Виконавця, що адресована невизначеному колу осіб відповідно до статті 641 Цивільного кодексу України укласти з
      ним договір про надання послуг на умовах договору приєднання. &nbsp;</span></p>
  <p><span>2.3. У випадку прийняття умов даної Оферти, Замовник погоджується з усіма умовами цього Договору і підтверджує,
      що йому зрозумілі всі його положення.</span></p>
  <p><span>2.4. Публікація Товариством умов оферти у Програмній продукції є пропозицією Товариства, що адресована
      будь-якій фізичній особі з повною цивільною дієздатністю укласти Договір про надання доступу до Програмної
      продукції на умовах, що визначені в оферті. </span></p>
  <p><span>2.5. Здійснюючи акцепт, Замовник засвідчує своє повне та безумовне прийняття оферти в цілому.
      Акцептом вважається проставлення Замовником відповідної відмітки (Я ознайомився (лася) та погоджуюся з
      Політикою конфіденційності та Умовами використання).</span></p>
  <p><span>2.6. Договір вважається укладеним у письмовій формі з моменту акцепту Замовником оферти Виконавця (Товариства).
      При цьому Сторони погоджуються, що після здійснення акцепту, неукладення цього Договору у вигляді окремого
      документа не тягне за собою недійсність цього Договору.</span></p>
  <p><span>2.7. Здійснення доступу до Програмної продукції та використання Програмної продукції користувачем,
      який на момент здійснення такого доступу вперше не володіє повною цивільною дієздатністю, відбувається за
      наявності згоди одного з батьків такого користувача, яка надається у порядку що визначений у Політиці конфіденційності,
      текст якої міститься у Програмній продукції.</span></p>
  <p><span>2.8. Виконавець не несе відповідальності за будь-який несанкціонований доступ або використання мобільного
      додатку та/або будь-якої інформації про користувачів, що зберігається в Системі, а також за будь-які помилки,
      збої, віруси і інші недоліки та порушення в мобільному додатку, які можуть бути передані на мобільний додаток
      або через Додаток третіми особами та їх наслідки у Користувача, у т.ч. але не виключно: за збитки, упущену вигоду,
      моральну шкоду, шкоду честі, гідності та ділової репутації.</span></p>
  <p><span>2.9. Місцем укладення Договору вважається м. Львів, Україна.</span></p>
  <p>&nbsp;</p>
  <ol start="3">
    <li><h4>ПРЕДМЕТ ДОГОВОРУ</h4></li>
  </ol>
  <p><span>3.1. В порядку та на умовах, визначених цим Договором, на безоплатній основі, Виконавець зобов’язується
      надати доступ Замовнику до Програмної продукції, а також надати послуги технічної підтримки як складової частини
      надання доступу до Програмної продукції.</span></p>
  <p><span>3.2. Замовник зобов’язується здійснювати доступ до та користуватися Програмною продукцією виключно в межах
      рівня доступу Замовника до Програмної продукції відповідно до категорії користувача Замовника, а також в межах
      функціонального призначення Програмної продукції. </span></p>
  <p><span>3.3. Доступ до Програмної продукції, а також сама Програмна продукція надається
      Замовнику &ldquo;як є&rdquo;(&ldquo;as is&rdquo;) та &ldquo;як доступно&rdquo; (&ldquo;as available&rdquo;).
      Виконавець в жодному разі не гарантує та не несе відповідальності за те, що доступ до Програмної продукції, а
      також сама Програмна продукція не буде відповідати цілям або очікуванням Замовника або будь-яких інших
      осіб.</span></p>
  <p>&nbsp;</p>
  <ol start="4">
    <li><h4> КАТЕГОРІЇ КОРИСТУВАЧІВ ТА РІВНІ ДОСТУПУ ДО ПРОГРАМНОЇ ПРОДУКЦІЇ</h4></li>
  </ol>
  <p><span>4.1. Замовник погоджується, що його рівень доступу та обсяг конкретних прав у Програмній продукції залежить
      від його категорії користувача чи категорії користувача школи, факту використання школою Програмної продукції на
      підставі договору з Товариством, а також факту приєднання Замовника до школи. Після приєднання Замовника
      до школи - Замовник стає користувачем школи. </span></p>
  <p><span>4.2. Замовник, що прийняв умови цього Договору та зареєструвався в Програмній продукції є користувачем.
      Користувач має доступ до загального функціоналу Програмної продукції та до контенту, що доступний всім Замовникам.
      Такий користувач, за умови, що він є учасником освітнього процесу у певній школі, може подати заявку на приєднання
    до цієї школи. До моменту приєднання такого користувача до школи (прийняття його заявки на приєднання адміністратором
      цієї школи), такий користувач не матиме доступу до будь-якої інформації, що стосується проведення освітнього процесу
      у цій школі та не може виконувати будь-які дії у Програмній продукції, що стосуються освітнього процесу у цій школі.</span></p>
  <p><span>4.3. Замовники, які є користувачами школи поділяються на наступні категорії:</span>
  </p>
  <p><span>4.3.1. Директор школи. Директор є адміністратором школи з найширшим обсягом прав у Програмній продукції стосовно
      організації освітнього процесу у цій школі за допомогою Програмної продукції. Після укладення школою договору з Товариством,
      Товариство надає директору школи повноваження адміністратора школи найвищого рівня в Програмній продукції після
      здійснення самостійної реєстрації директором у Програмній продукції. Директор школи має можливість створювати
      адміністраторів школи, надавати користувачам із роллю вчитель школи додаткову, другу, роль адміністратора школи,
      створювати та видаляти класи, редагувати інформацію про школу в Програмній продукції, а також здійснювати інші дії,
      що відповідають характеру діяльності директора школи. </span></p>
  <p><span>4.3.2. Адміністратор школи. Адміністратором школи є уповноважена школою особа з відповідним обсягом прав у
      Програмній продукції. Адміністратор школи має можливість створювати у системі вчителів школи,
      учнів школи та батьків учнів школи, а також здійснювати інші дії, що відповідають характеру
      діяльності адміністратора школи. </span></p>
  <p><span>4.3.3. Вчитель школи. Вчитель школи може бути зареєстрований у Програмній продукції Адміністратором школи,
      а також може зареєструватися у Програмній продукції самостійно, подати заявку на приєднання до школи у ролі вчителя школи,
      та після прийняття такої заявки адміністратором школи - отримати доступ до програмної Продукції як вчитель школи.
      Зокрема, вчителю школи доступна можливість переглядати та працювати з електронними журналами класів,
      у яких цей вчитель викладає, спілкуватися з учнями цих класів та з батьками учнів цих класів, працювати з
      навчальними планами, розкладами занять, а також здійснювати інші дії у Програмній продукції, що відповідають
      професійній діяльності вчителя школи.</span></p>
  <p><span>4.3.4. Батько/Мати учня школи. Батько/Мати учня школи може бути зареєстрований (на) у Програмній продукції
      адміністратором школи або може зареєструватися самостійно, подати заявку на приєднання до школи,
      та після прийняття такої заявки адміністратором школи, отримати доступ до Програмної продукції як батько/мати учня школи.
      Зокрема, такий доступ передбачає можливість батьку/матері учня школи переглядати інформацію про успішність,
      відвідуваність та домашні завдання своєї дитини (учня школи), а також спілкуватися у Програмній продукції з вчителями школи,
      які викладають у їх дитини (учня школи) та з іншими батьками/матерями учнів, що є однокласниками їх дитини (учня школи)
      та здійснювати інші подібні дії.</span></p>
  <p><span>4.3.5. Учень школи. Учень школи може бути зареєстрований у Програмній Продукції адміністратором школи
      або батьком/матір’ю цього учня школи. Доступ учня школи передбачає можливість працювати з електронним щоденником,
      спілкуватися зі своїми однокласниками (іншими учнями школи), а також спілкуватися із вчителями, що викладають
      у цього учня школи та здійснювати інші подібні дії.</span></p>
  <p>&nbsp;</p>
  <ol start="5">
    <li><h4> ПРАВА ТА ОБОВ&rsquo;ЯЗКИ ВИКОНАВЦЯ</h4></li>
  </ol>
  <p><span>5.1. Виконавець має право:</span></p>
  <p><span>5.1.1. обмежити або припинити доступ до Програмної продукції Замовника, якщо у Виконавця виникнуть
      обґрунтовані підстави вважати, що Замовник, через свій обліковий запис, або без використання свого облікового запису,
      намагається порушити безпеку Програмної продукції чи будь-яким іншим чином спричинити негативний вплив на
      функціонування Програмної продукції;</span></p>
  <p><span>5.1.2. обмежити або припинити доступ до Програмної продукції Замовника, якщо у Виконавця виникнуть
      обґрунтовані підстави вважати, що унікальними даними Замовника для авторизації у Програмній продукції
      (логіном та паролем Замовника) заволоділа будь-яка третя особа;</span></p>
  <p><span>5.1.3. обмежити або припинити доступ до Програмної продукції Замовника, якщо у Виконавця виникнуть
      обґрунтовані підстави вважати, що Замовник порушив умови цього Договору, Політики конфіденційності чи положення
      чинного законодавства України;</span></p>
  <p><span>5.1.4. обмежити або припинити доступ до Програмної продукції Замовника, якщо у Виконавця виникнуть
      обґрунтовані підстави вважати, що Замовник здійснює доступ до та використовує Програмну продукцію у такий спосіб,
      що порушує чинне законодавство України, порушує права та законні інтереси третіх осіб;</span></p>
  <p><span>5.1.5. вимагати від Замовника відшкодування збитків, якщо такі збитки були завдані внаслідок
      порушення Замовником умов цього Договору;</span></p>
  <p><span>5.1.6. залучати до виконання своїх зобов’язань за цим Договором, а також для забезпечення належного
      функціонування Програмної продукції третіх осіб;</span></p>
  <p><span>5.1.7. здійснювати будь-які зміни, оновлення, додатки, доповнення та/або розширення функціоналу
      Програмної продукції;</span></p>
  <p><span>5.1.8. проводити планові та позапланові технічні роботи у Програмній продукції з метою забезпечення
      належного функціонування Програмної продукції, а також здійснювати покращення Програмної продукції,
      налаштування створених для Програмної продукції будь-яких змін, оновлень, додатків, доповнень та/або розширень
      функціоналу Програмної продукції.</span></p>
  <p><span>5.2. Виконавець зобов&rsquo;язаний:</span></p>
  <p><span>5.2.1. забезпечити Замовникові можливість здійснювати доступ до Програмної продукції та використовувати
      Програмну продукцію відповідно до її функціонального призначення;</span></p>
  <p><span>5.2.2. виконувати свої зобов’язання за цим Договором та відповідно до чинного
      законодавства України;</span></p>
  <p><span>5.2.3. забезпечувати належне функціонування Програмної продукції;</span></p>
  <p><span>5.2.4. надавати відповіді та технічну підтримку Замовнику у відповідь на звернення
      Замовника до Виконавця електронною поштою (support&#64;nit.school);</span></p>
  <p><span>5.3.5. дотримуватися вимог Закону України &ldquo;Про захист персональних
      даних&rdquo; та Політики конфіденційності при обробці персональних даних Замовника.</span></p>
  <p>&nbsp;</p>
  <ol start="6">
    <li><h4> ПРАВА ТА ОБОВ&rsquo;ЯЗКИ ЗАМОВНИКА</h4></li>
  </ol>
  <p><span>6.1. Замовник має право:</span></p>
  <p><span>6.1.1. здійснювати доступ до та користуватися Програмною продукцією за її функціональним призначенням
      відповідно до умов цього Договору, Політики конфіденційності та чинного законодавства України;</span></p>
  <p><span>6.1.2. отримувати від Виконавця послуги технічної підтримки щодо здійснення доступу до та використання
      Замовником Програмної продукції у відповідь на запит Замовника, що був надісланий Замовником на електронну
      пошту support&#64;nit.school;</span></p>
  <p><span>6.1.3. вимагати від Виконавця відшкодування завданих збитків, якщо такі збитки були спричинені
      внаслідок невиконання чи неналежного виконання Виконавцем цього Договору.</span></p>
  <p><span>6.2. Замовник зобов&rsquo;язаний:</span></p>
  <p><span>6.2.1. здійснювати доступ до та користуватися Програмною продукцію виключно відповідно до умов цього Договору,
      Політики конфіденційності та чинного законодавства України;</span></p>
  <p><span>6.2.2. зберігати свої унікальні дані для авторизації у Програмній продукції (Логін та Пароль),
      а також доступ до своєї електронної пошти у конфіденційності та не допускати їх розголошення будь-яким третім особам,
      а також у випадку наявності у Замовника обґрунтованих підстав вважати, що його унікальні дані для авторизації
      у Програмній продукції (Логін та Пароль) доступні будь-яким третім особам - повідомити про такий факт Виконавця
      негайно у письмовій формі, шляхом направлення листа на електронну пошту Виконавця support&#64;nit.school.
      Замовник несе відповідальність за схоронність та конфіденційність своїх унікальних даних для авторизації
      в Програмній продукції (Логін та Пароль), а також за шкоду, що була завдана Замовникові, Виконавцеві та/або
      третім особам внаслідок заволодіння такими даними третіми особами, якщо таке заволодіння сталося з вини Замовника;</span>
  </p>
  <p><span>6.2.3. при здійсненні доступу до та використання Програмної продукції жодним чином не намагатися порушити
      безпеку Програмної продукції чи будь-яким іншим чином спричинити негативний вплив на функціонування Програмної продукції;
      жодним чином не перевіряти вразливість безпеки Програмної продукції та не використовувати Програмну продукцію
      з метою завдання шкоди правам та законним інтересам Виконавця та третіх осіб;</span></p>
  <p><span>6.2.4. не здійснювати доступ до та не використовувати Програмну продукцію у будь-який спосіб,
      що порушує чинне законодавство України, порушує права та законні інтереси третіх осіб;</span>
  </p>
  <p><span>6.2.5. здійснювати доступ до та використовувати Програмну продукції виключно за її функціональним
      призначенням та в межах своєї категорії користувача (категорії користувача школи);</span></p>
  <p><span>6.2.6. не здійснювати стосовно Програмної продукції зворотну розробку, копіювання інтерфейсу та функціоналу,
      переклад з однієї мови програмування на іншу, не здійснювати перероблення (не створювати похідного твору,
      не вносити зміни, модифікації до вихідного коду, не декомпілювати). </span></p>
  <p><span>6.2.7. у випадку виявлення будь-яких недоліків у роботі Програмної продукції, повідомити про такі недоліки
      Виконавцеві шляхом надсилання листа на електронну пошту support&#64;nit.school впродовж 2 (двох) днів з моменту
      виявлення недоліків.</span></p>
  <p>&nbsp;</p>
  <ol start="7">
    <li><h4>КОНФІДЕНЦІЙНІСТЬ. ЗАХИСТ ПЕРСОНАЛЬНИХ ДАНИХ</h4></li>
  </ol>
  <p><span>7.1. Виконавець обробляє персональні дані Замовника відповідно до Закону України
      “Про захист персональних даних”.</span></p>
  <p><span>7.2. Детальна інформація щодо порядку обробки Виконавцем персональних даних Замовника міститься у
      Політиці конфіденційності, що є невід'ємною частиною цього Договору.</span></p>
  <p>&nbsp;</p>
  <ol start="8">
    <li><h4> УМОВИ ВИКОРИСТАННЯ ПРОГРАМНОЇ ПРОДУКЦІЇ</h4></li>
  </ol>
  <p><span>8.1. Доступ Замовника до Програмної Продукції, а також використання Програмної продукції відбувається
      за умови акцепту цього Договору у порядку, що визначений цим Договором.</span></p>
  <p><span>8.2. Замовник може використовувати Програмну продукцію виключно за наступних умов (в сукупності):</span></p>
  <p><span>8.2.1. Замовник прийняв умови Оферти/або уклав інший договір з Товариством, відповідно до умов якого
      має право використовувати Програмну продукцію, та Політики Конфіденційності;</span></p>
  <p><span>8.2.2. Замовник при використанні Програмної продукції дотримується умов цього Договору та
      Політики конфіденційності;</span></p>
  <p><span>8.2.3. Замовник при використанні Програмної продукції дотримується чинного законодавства України; &nbsp;</span></p>
  <p><span>8.2.4. Використання Програмного забезпечення можливе за умови увімкненого програмно-керованого пристрою для
      обробки інформації, а саме: персонального комп’ютера або мобільного пристрою, з доступом до мережі Інтернет. &nbsp;</span></p>
  <p><span>Рекомендовано здійснювати доступ до Програмної продукції та її використання через
      наступні веббраузери:</span></p>
  <ul>
    <li><span>Google Chrome (найновіша версія. При цьому мінімальна
        версія, що підтримує Програмну продукцію є Google Chrome - 86.0.4240);</span></li>
    <li><span>Opera (найновіша версія. При цьому мінімальна версія,
        що підтримує Програмну продукцію є Opera - 70);</span></li>
    <li><span>Mozilla Firefox (найновіша версія. При цьому
        мінімальна версія, що підтримує Програмну продукцію є Mozilla Firefox - 79.0);</span></li>
    <li><span>Safari (найновіша версія. При цьому мінімальна версія,
        що підтримує Програмну продукцію є Safari - 13.1.2).</span></li>
    <li><span>Microsoft Edge (найновіша версія. При цьому мінімальна версія,
        що підтримує Програмну продукцію є Microsoft Edge - 91.0.864.71). </span></li>
  </ul>
  <p><span>8.2.5. Замовник не порушує безпеку Програмної продукції, не створює будь-яких перешкод у функціонуванні
      Програмної продукції, а також не перешкоджає у використанні Програмної продукції іншими користувачами;</span></p>
  <p><span>8.2.6. Замовник використовує Програмну продукцію виключно за її функціональним призначенням;</span></p>
  <p><span>8.2.7. Замовник не вчиняє жодних дій з наміром зламати безпеку Програмної продукції та використовувати
      Програмну продукції не за її цільовим призначенням;</span></p>
  <p><span>8.2.8. Замовник не намагається та не викликає будь-які збої у роботі серверів,
      що використовуються для забезпечення належного функціонування Програмної продукції.</span></p>
  <p><span>8.3. Здійснення Замовником доступу до Програмної продукції відбувається з урахуванням категорії користувача
      (категорії користувача школи) Замовника.</span></p>
  <p><span>8.4. Можливість використання Програмної продукції та доступ до Програмної продукції надається цілодобово,
      без вихідних 24 години на добу, 7 днів на тиждень, за умови наявності доступу до мережі інтернет,
      увімкненого програмно-керованого пристрою зі встановленим веббраузером, як зазначено вище.</span></p>
  <p><span>8.5. При проведенні Товариством планових/позапланових технічних робіт у Програмній продукції,
      перерви у функціонуванні Програмної продукції та, відповідно, у можливості здійснення доступу користувачем
      до Програмної продукції та використання користувачем Програмної продукції є можливими. Товариство не несе
      відповідальності за безперебійну роботу Програмної продукції при здійсненні таких технічних робіт.</span></p>
  <p>&nbsp;</p>
  <ol start="9">
    <li><h4>ВІДПОВІДАЛЬНІСТЬ</h4></li>
  </ol>
  <p><span>9.1. У разі невиконання або неналежного виконання обов'язків, передбачених цим Договором,
      Сторони несуть відповідальність згідно з чинним законодавством України, якщо інше не зазначено у цьому Договорі.</span></p>
  <p><span>9.2. Замовник несе відповідальність за шкоду, що може бути завдана Замовнику, Виконавцеві або іншим третім особам,
      внаслідок навмисної чи ненавмисної втрати Замовником унікальних даних для авторизації у Програмній продукції
      (Логіна та Паролю), якщо така втрата спричинила доступ до Програмної продукції сторонніх осіб.</span></p>
  <p><span>9.3. Сторона не несе відповідальності за невиконання чи неналежне виконання умов цього Договору,
      якщо таке невиконання чи неналежне виконання було наслідком дії Форс-мажорних обставин.</span></p>
  <p><span>9.4. ССторона не несе відповідальності за порушення Договору, якщо воно сталося не з її вини  (умислу чи необережності).
      Сторона вважається невинуватою і не несе відповідальності за  порушення умов цього Договору, якщо вона доведе,
      що вжила всіх залежних від неї заходів щодо  належного виконання умов цього Договору. </span></p>
  <p><span>9.5. Виконавець не несе відповідальність:</span></p>
  <p><span>9.5.1. за будь-які дії Замовника, які були вчинені Замовником за допомогою Програмної продукції чи у
      Програмній продукції, включаючи, але не обмежуючись: поширення будь-якого контенту Замовником з порушення прав
      інтелектуальної власності третіх осіб, поширення будь-якої інформації образливого, непристойного, неправдивого,
      агресивного чи іншого незаконного характеру; вчинення Замовником будь-яких дій за допомогою Програмної продукції
      або у Програмній продукції, якщо такі дії спричинили порушення будь-яких прав та законних інтересів інших
      користувачів Програмної продукції або будь-яких інших третіх осіб;
</span></p>
  <p><span>9.5.2. за дії або бездіяльність інших користувачів (включаючи користувачів школи), якщо такі дії чи
      бездіяльність мали наслідком недотримання Замовником доступу до Програмної продукції або можливості використання
      Програмної продукції або неотримання доступу до будь-якої інформації, доступ до якої Замовник отримав би,
      якби інші користувачі (включаючи користувачів школи) здійснили певні дії або утрималися від
      вчинення певних дій;</span></p>
  <p><span>9.5.3. за будь-які очікування Замовника щодо доступу до чи використання Програмної продукції,
      якщо такі очікування не були виправдані Програмною продукцією, адже доступ до Програмної продукції,
      а також сама Програмна продукція надається Замовнику “як є”(“as is”) та “як доступно” (“as available”). </span></p>
  <p><span>9.5.4. за зміст даних, які вносить в Програмну продукцію будь-який користувач (включаючи користувачів школи)
      Програмної продукції;</span></p>
  <p><span>9.5.5. за тимчасові перерви у функціонуванні Програмної продукції, що спричинені виконанням планових,
      позапланових технічних робіт чи профілактичних; </span></p>
  <p><span>9.5.6. за неможливість здійснення доступу до Програмної продукції та неможливість використання Програмної
      продукції Замовником при технічних збоях інтернет-провайдерів, серверів, мереж, протиправних діях третіх осіб;</span></p>
  <p><span>9.5.7. за навмисну втрату чи втрату з необережності Замовником унікальних даних для
      авторизації в Програмній продукції (логіна та пароля), що спричинило будь-які негативні наслідки для Замовника,
      Виконавця, інших користувачів Програмної продукції та будь-яких третіх осіб;</span></p>
  <p><span>9.5.8. за будь-які неправомірні дії Замовника, користувачів (включаючи користувачів
      школи);</span></p>
  <p><span>9.5.9. за будь-який неправомірний доступ до Програмної продукції третіх осіб, що
      спричиняє поширення через Програмну продукції будь-якої недостовірної інформації, інформації образливого та
      насильницького характеру, інформації, яка порушує загальноприйняті в суспільстві норми моралі, інформації, яка
      порушує права та інтереси будь-яких третіх осіб; за використання особою, що здійснила такий неправомірний доступ
      будь-якої інформації, що міститься у програмній продукції у будь-яких власних цілях або в цілях третіх осіб, за
      умови, що така особа, що здійснила неправомірний доступ до Програмної продукції здійснила такий доступ за
      допомогою Замовника або іншого користувача (включаючи користувачів школи).</span></p>
  <p>&nbsp;</p>
  <ol start="10">
    <li><h4>ВИРІШЕННЯ СПОРІВ</h4></li>
  </ol>
  <p><span>10.1. Усі спори, що пов’язані з цим Договором вирішуються шляхом переговорів між Сторонами.</span></p>
  <p><span>10.2. У випадку наявності у Замовника будь-яких претензій до Виконавця стосовно виконання Виконавцем своїх
      зобов’язань за цим Договором, Замовник надсилає письмове звернення на адресу електронної пошти support&#64;nit.school.
      Виконавець зобов’язаний розглянути таке звернення та надати письмову відповідь на це звернення впродовж 10 (десяти)
      робочих днів з дати отримання Виконавцем письмового звернення від Замовника.</span></p>
  <p><span>10.3. Якщо спір не може бути вирішений шляхом переговорів, він вирішується в порядку,
      визначеному чинним законодавством України.</span></p>
  <p>&nbsp;</p>
  <ol start="11">
    <li><h4>ДІЯ ДОГОВОРУ ТА ЙОГО РОЗІРВАННЯ</h4></li>
  </ol>
  <p><span>11.1. Цей Договір набирає чинності з моменту акцепту Замовником оферти Виконавця у порядку,
      що визначений у цьому Договорі.</span></p>
  <p><span>11.2. Виконавець може вносити зміни до цього Договору в будь-який час без будь-якого спеціального повідомлення,
      розмістивши нову редакцію Договору у Програмній продукції. Нова редакція Договору набуває чинності з моменту
      її розміщення у Програмній продукції, якщо інше не передбачено такою новою редакцією. &nbsp;</span></p>
  <p><span>11.2.1. Замовник зобов’язаний слідкувати за змінами до Договору та ознайомлюватися з такими змінами. &nbsp;</span></p>
  <p><span>11.2.2. Продовження здійснення доступу до Програмної продукції та продовження використання Програмної
      продукції Замовником після внесення змін до Договору Виконавцем та їх оприлюднення у Програмній продукції,
      свідчить про прийняття Замовником таких змін.</span></p>
  <p><span>11.2.3.  У випадку, якщо Замовник не погоджується зі змінами, що були внесені Виконавцем до цього Договору,
      Замовник зобов’язується повідомити про це Виконавця та припинити здійснювати доступ до Програмної продукції
      та припинити користуватися Програмною продукцією. </span></p>
  <p><span>11.3. Цей Договір є чинним до моменту його розірвання Сторонами.</span></p>
  <p><span>11.4. Договір може бути розірвано Сторонами за взаємною згодою Сторін, а також в інших випадках,
      передбачених чинним законодавством України.</span></p>
  <p><span>11.5. Виконавець може розірвати цей Договір в односторонньому порядку за наступних умов:</span></p>
  <p><span>11.5.1.у разі невиконання або неналежного виконання Замовником умов цього Договору - на 10 (десятий)
      день з дати повідомлення Замовника, якщо до цього часу Замовник не виправив таке невиконання чи неналежне
      виконання умов цього Договору;</span></p>
  <p><span>11.5.2. у разі, якщо є обґрунтовані підстави вважати, що дії Замовника завдають шкоди правам та законним
      інтересам Виконавця, чи третіх осіб - в день повідомлення Замовника;</span></p>
  <p><span>11.5.3.  у разі неможливості забезпечувати належне функціонування Програмної продукції -
      в день повідомлення Замовника.</span></p>
  <p><span>11.6. Замовник може розірвати цей Договір в односторонньому порядку за наступних умов:</span></p>
  <p><span>11.6.1. невиконання або неналежного виконання Виконавцем своїх зобов’язань - на 10 (десятий) день з
      дати повідомлення Виконавця, якщо до цього часу Виконавець не виправив таке невиконання чи неналежне
      виконання умов цього Договору;</span></p>
  <p><span>11.6.2. не погодження зі змінами, внесеними Виконавцем до цього Договору - негайно, після внесення
      змін до Договору Виконавцем та після повідомлення Виконавця Замовником про розірвання цього Договору;</span></p>
  <p><span>11.6.3. за власним бажанням - шляхом повідомлення Виконавця в письмовій формі та припинення здійснення
      доступу до Програмної продукції та припинення використання Програмної продукції.</span></p>
  <p><span>11.7. У випадку, якщо після розірвання Договору, незалежно від причини такого розірвання, Замовник має
      намір знову здійснювати доступ до Програмної продукції та користуватися Програмною продукцією,
      Замовник повідомляє про свій намір Виконавцеві на електронну пошту support&#64;nit.school.</span></p>
  <p>&nbsp;</p>
  <ol start="12">
    <li><h4> ЗАКЛЮЧНІ ПОЛОЖЕННЯ</h4></li>
  </ol>
  <p><span>12.1.&nbsp; Програмна продукція, програмний код, знаки для товарів і послуг, а також елементи дизайну, текст,
      графічні зображення, ілюстрації, відео, скрипти, програми, музика, звуки та інші об’єкти і їх добірки чи
      будь-який інший контент, що розміщений Виконавцем або третіми особами, за вказівкою Виконавця, у Програмній
      продукції є об’єктами права інтелектуальної власності.</span></p>
  <p><span>12.2. Всі майнові права інтелектуальної власності або право використання на всі об’єкти права
      інтелектуальної власності, що перелічені в п. 12.1. цього Договору належать Виконавцеві.</span></p>
  <p><span>12.3. Будь-яке використання з комерційною метою об’єктів права інтелектуальної власності,
      що перелічені в п. 12.1. цього Договору забороняється Замовнику.</span></p>
  <p><span>12.4. Усі взаємовідносини Сторін, що не обумовлені цим Договором, регулюються
      чинним  законодавством України.</span></p>
  <p></p>
  <h4>ЮРИДИЧНІ АДРЕСИ ТА БАНКІВСЬКІ РЕКВІЗИТИ СТОРІН</h4>
  <strong>Контактна інформація.</strong>
  <p><span>ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ЛАЄНВУД СОФТВЕР УКРАЇНА",
      в особі директора Каміновського Володимира Андрійовича, який діє на підставі Статуту</span></p>
  <p><span>ЄДРПОУ 43009185</span></p>
  <p><span>Ел.пошта: support&#64;nit.school</span></p>
  <p><span>Web-сайт: <a href="https://nit.school/">https://nit.school/</a></span></p>

</nit-policy-container>
