import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NitForm, NValidate} from '@nit-forms';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from '@nit-auth';
import {GuidService} from '@nit-services';
import {environment} from '../../../../environments/environment';
import {LbsForm, LbsFormResponse} from '@nit-core/models/lbs-form';
import {NitFieldWrapperComponent} from '../../../core/forms/components/field-wrapper/field-wrapper.component';
import {TrimInputDirective} from '../../../core/directives/trim-input.directive';
import {PhoneInputComponent} from '../../../core/components/phone-input/phone-input.component';
import {CustomCheckboxComponent} from '../../../private/components/custom-checkbox/custom-checkbox.component';

@Component({
  selector: 'nit-lbs-form',
  templateUrl: './lbs-form.component.html',
  styleUrl: './lbs-form.component.scss',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NitFieldWrapperComponent, TrimInputDirective, PhoneInputComponent, CustomCheckboxComponent]
})
export class LbsFormComponent implements OnInit {
  classNumber: number;
  form: NitForm = new NitForm({
    country: new FormControl('',[NValidate.required, NValidate.maxLen(50)]),
    region: new FormControl('',[NValidate.required, NValidate.maxLen(50)]),
    city: new FormControl('', [NValidate.required, NValidate.maxLen(50)]),
    lastName: new FormControl('', [NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    firstName: new FormControl('',[NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    class: new FormControl(''),
    password: new FormControl(''),
    agreeTerms: new FormControl(false, NValidate.required),
    parentFirstName: new FormControl('', [NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    parentLastName: new FormControl('', [NValidate.required, NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    parentMiddleName: new FormControl('', [NValidate.minLen(2), NValidate.maxLen(64), NValidate.cyrillicWithoutNumber()]),
    parentEmail: new FormControl('', [NValidate.required, NValidate.email()]),
    parentPhone: new FormControl('', NValidate.required),
  });
  constructor(private readonly _route: ActivatedRoute,
              private readonly _guidService: GuidService,
              private readonly _authService: AuthService) {
    this.classNumber = this._classNumber(this._route.snapshot.params.id);
  }

  ngOnInit(): void {
    this.form.get('class').patchValue(this.classNumber);
  }

  saveDate(): void {
    this._generatePassword();
    if (this.form.validate()) {
      const classValue = this.form.value.class ? this.form.value.class : 12;
      
      this._authService.signUpLbs({...this.form.value, class: Number(classValue), school: 50})
        .subscribe({
          next: (res) => this._loginLbs(this.form.value, res),
          error: (e) => this.form.externalValidationHandler(e)
        });
    }
  }

  private _loginLbs(form: LbsForm, response: LbsFormResponse): void {
    this._authService.login({userName: response.email, password: form.password}).subscribe(() => {
      window.location.href = `${environment.url}/kit/testing/${response.olympiadId}/${response.stageId}`;
    }, (e) => this.form.externalValidationHandler(e));
  }

  private _generatePassword(): void {
    const password = this._guidService.generatePassword();
    this.form.get('password').patchValue(password);
  }

  private _classNumber(number: string): number {
    const value: number = Number(number);

    return value > 11 ? value === 12 ? 0 : 11 : value < 0 ? 0 : value;
  }

}
